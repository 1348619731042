/*
 * PMApp::activities
 * Place JS to support activities related functionality in this file
 */

import { customerSelectorSetupEventHandlers,
         customerSelectorAddFieldsToParams         } from './customer_selector.js.erb';
import { initializeDateTimePickers                 } from './date_time_pickers.js.erb';
import { jobSelectorSetupEventHandlers,
         jobSelectorAddFieldsToParams              } from './job_selector.js.erb';
import { pmappButtonAddSpinner                     } from './pmapp_button_spinner';
import { pmappPreventDefaults                      } from './pmapp.js.erb';
import { pmappTinyMCEInitializeMentionable,
         pmappTinyMCESetupModalCleanupEventHandler } from './tinymce.js.erb';


export function activitiesSetupEventHandlers() {
  // initialize data time pickers before setting up event handlers on them to avoid unintended calculate_time_spent
  initializeDateTimePickers();

  // setup event handler for tabs
  $('#activityModal .nav-link').click(function(e) {
    e = e || window.event
    var selectedTab = e.target || e.srcElement;
    activitySwitchTab(e, selectedTab);
  });

  $('#teamAndMemberCollapse').on('show.bs.collapse', function() {
    $('#LoggingFor').hide();
    $('#loggingForCollapseLink').text('hide');
  });

  $('#teamAndMemberCollapse').on('hide.bs.collapse', function() {
    $('#LoggingFor').show();
    $('#loggingForCollapseLink').text('change');
  });

  // setup event handlers for the team, travel time, and job selects
  $('#' + activityBuildTeamSelectId()).change(function(e) {
    e = e || window.event
    var triggeringSelect = e.target || e.srcElement;
    handleActivitySelectChange(triggeringSelect);
  });

  $('#' + activityBuildWorkerSelectId()).change(function(e) {
    e = e || window.event
    var triggeringSelect = e.target || e.srcElement;

    var buttonSelector = '#recordTimeActivityButton';
    var keepContent = true;
    pmappButtonAddSpinner(buttonSelector, keepContent);

    buttonSelector = '#skipRecordTimeActivityButton';
    pmappButtonAddSpinner(buttonSelector, keepContent);

    handleActivitySelectChange(triggeringSelect);
  });

  $('#' + activityBuildTravelTimeSelectId()).change(function(e) {
    e = e || window.event
    var triggeringSelect = e.target || e.srcElement;
    handleActivitySelectChange(triggeringSelect);
  });

  $('#' + activityBuildTimeSpentSelectId()).change(function(e) {
    e = e || window.event
    var triggeringSelect = e.target || e.srcElement;
    resetTimeSpentStyles(triggeringSelect);
  });

  $('#' + activityBuildTimeBucketSelectId()).change(function(e) {
    e = e || window.event
    var triggeringSelect = e.target || e.srcElement;
    handleActivitySelectChange(triggeringSelect);
  });

  $('#startStopTimesCollapse').on('show.bs.collapse', function() {
    $('#startStopCollapseLink').text('hide start/stop times');
  });

  $('#startStopTimesCollapse').on('hide.bs.collapse', function() {
    $('#startStopCollapseLink').text('show start/stop times');
  });

  $('#travelTimeCollapse').on('show.bs.collapse', function() {
    $('#travelTimeCollapseLink').text('hide travel time');
  });

  $('#travelTimeCollapse').on('hide.bs.collapse', function() {
    $('#travelTimeCollapseLink').text('show travel time');
  });

  $('#' + activityBuildStartTimePickerId()).on('change.datetimepicker', function(e) {
    e = e || window.event
    var triggeringPicker = e.target || e.srcElement;
    activityCalculateTimeSpent(triggeringPicker);
  });

  $('#' + activityBuildStopTimePickerId()).on('change.datetimepicker', function(e) {
    e = e || window.event
    var triggeringPicker = e.target || e.srcElement;
    activityCalculateTimeSpent(triggeringPicker);
  });

  customerSelectorSetupEventHandlers(activityGenerateJobSelectorParams);
  jobSelectorSetupEventHandlers(activityGenerateCustomerSelectorParams);

  var tinyMCEClassName = 'activity-tinymce';
  pmappTinyMCEInitializeMentionable(tinyMCEClassName);

  var modalId = 'activityModal';
  pmappTinyMCESetupModalCleanupEventHandler(modalId, tinyMCEClassName);
}


// event handlers

function activitySwitchTab(event, selectedTab) {
  pmappPreventDefaults(event);

  var selectedTabName      = $(selectedTab).text();
  var selectedTeamId       = activityGetSelectedTeamId();
  var selectedWorkerId     = activityGetSelectedWorkerId();
  var selectedTravelTime   = activityGetSelectedTravelTime();
  var selectedTimeBucketId = activityGetSelectedTimeBucketId();

  var params = customerSelectorAddFieldsToParams({
                 tab_name:       selectedTabName,
                 team_id:        selectedTeamId,
                 worker_id:      selectedWorkerId,
                 travel_time:    selectedTravelTime,
                 time_bucket_id: selectedTimeBucketId });
  params = jobSelectorAddFieldsToParams(params);

  var updateUrl = activityGetUpdateUrl(selectedTab);

  $.ajax({
    data: params,
    type: 'POST',
    url:   updateUrl,
    async: true
  });
}

/*
 * activityCalculateTimeSpent
 * Used (inline) to automatically calculate time spent from user-provided start/stop times in the activity modal.
 */
function activityCalculateTimeSpent(pickerHandle) {
  var startTimeId = activityBuildStartTimePickerId();
  var endTimeId   = activityBuildStopTimePickerId();

  // if the user manually populates the picker or uses browser "recent values," rather than using the tempus
  // dominus stuff, the event will fire for both the tempus dominus wrapper and the wrapped browser control
  // this check skips the event for the wrapped control
  var pickerId = $(pickerHandle).attr("id");
  if (pickerId == startTimeId || pickerId == endTimeId) {

    // get the start and end times from the modal
    var startTime = $('#' + startTimeId + ' input').val();
    var endTime   = $('#' + endTimeId + ' input').val();

    // only calculate if both times are provided
    if (startTime && endTime) {

      var serverUrl   = $(pickerHandle).data("url");
      var timeSpentId = activityBuildTimeSpentSelectId();

      // ship the start and end times off to the server to calculate the time spent
      $.ajax({
        data:    { start_time: startTime,
                   end_time:   endTime    },
        type:    'POST',
        url:     serverUrl,
        async:   true,
        success: function(response) {
          // set the time spent, in the modal, to the result of the server-side calculation
          $('#' + timeSpentId).val(response.time_spent);
          $('#' + timeSpentId).css('font-weight', 'bold');
        }
      });
    }
  } // skip unintended events
}

function handleActivitySelectChange(triggeringSelect) {
  var selectedTabName      = activityGetSelectedTabName();
  var selectedTeamId       = activityGetSelectedTeamId();
  var selectedWorkerId     = activityGetSelectedWorkerId();
  var selectedTravelTime   = activityGetSelectedTravelTime();
  var selectedTimeBucketId = activityGetSelectedTimeBucketId();

  var params = customerSelectorAddFieldsToParams({
                 tab_name:       selectedTabName,
                 team_id:        selectedTeamId,
                 worker_id:      selectedWorkerId,
                 travel_time:    selectedTravelTime,
                 time_bucket_id: selectedTimeBucketId,
  });
  params = jobSelectorAddFieldsToParams(params);

  var updateUrl = activityGetUpdateUrl(triggeringSelect);

  $.ajax({
    data:  params,
    type:  'POST',
    url:   updateUrl,
    async: true
  });
}

/*
 * resetTimeSpentStyles
 * Used (inline) to reset the styles of the time spent select on the activity modal when the user changes the
 * selected value.  Used in conjunction with the automatic time spent calculation (calculate_time_spent) to help
 * the user understand when the time spent selected was computed and when it was selected.
 */
function resetTimeSpentStyles(timeSpentSelect) {
  $(timeSpentSelect).css('font-weight', 'normal');
}


// helpers

function activityBuildActiveTabSelector() {
  return '#activityModal .nav-link.active';
}

function activityBuildTravelTimeSelectId() {
  return 'travelTimeSelect';
}

function activityBuildTeamSelectId() {
  return 'teamSelect';
}

function activityBuildTimeBucketSelectId() {
  return 'timeBucketSelect';
}

function activityBuildTimeSpentSelectId() {
  return 'timeSpentSelect';
}

function activityBuildWorkerSelectId() {
  return 'workerSelect';
}

function activityBuildStartTimePickerId() {
  return 'startTimePicker';
}

function activityBuildStopTimePickerId() {
  return 'stopTimePicker';
}

/*
 * activityGenerateJobSelectorParams
 * Used to ensure that job selector params are passed with a customer selector update controls callback action
 * NOTE: we also use this function to trick the customer selector into showing a spinner whenever the selected
 #       customer or location is changed
 */
export function activityGenerateJobSelectorParams() {
  return jobSelectorAddFieldsToParams({});
}

export function activityGenerateCustomerSelectorParams() {

  // HACK ALERT
  // I need a way to affect the modal buttons when the job selector changes, this will serve for now, but
  // at some point I need to implement this in a better way
  var buttonSelector = '#recordTimeActivityButton';
  if (! $(buttonSelector).prop('disabled')) {
    var keepContent = true;
    pmappButtonAddSpinner(buttonSelector, keepContent);
  }

  buttonSelector = '#skipRecordTimeActivityButton';
  if (! $(buttonSelector).prop('disabled')) {
    var keepContent = true;
    pmappButtonAddSpinner(buttonSelector, keepContent);
  }
  // END HACK

  return customerSelectorAddFieldsToParams({});
}

function activityGetSelectedTimeBucketId() {
  return $('#' + activityBuildTimeBucketSelectId()).children('option:selected').val();
}

function activityGetSelectedTravelTime() {
  return $('#' + activityBuildTravelTimeSelectId()).children('option:selected').val();
}

function activityGetSelectedTeamId() {
  var rval = null;

  if ($('#' + activityBuildTeamSelectId()).length) {
    rval = $('#' + activityBuildTeamSelectId()).children('option:selected').val();
  } else {
    rval = $('#teamHiddenField').val();
  }

  return rval;
}

function activityGetSelectedTabName() {
  return $(activityBuildActiveTabSelector()).text();
}

function activityGetSelectedWorkerId() {
  var rval = null;

  if ($('#' + activityBuildWorkerSelectId() + ':visible').length > 0) {
    rval = $('#' + activityBuildWorkerSelectId()).children('option:selected').val();
  } else {
    rval = $('#workerHiddenField').val();
  }

  return rval;
}

function activityGetUpdateUrl(triggeringSelect) {
  var updateUrlKey = 'change-url';
  return $(triggeringSelect).data(updateUrlKey);
}
