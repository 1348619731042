/*
 * PMApp::task_worker_associations
 */

import { initializeDateTimePickers } from './date_time_pickers.js.erb';


export function taskWorkerAssociationsSetupEventHandlers(teamSelectorId, workerSelectorId, mcdFieldId, updateUrl) {
  taskWorkerAssociationsSetupTeamSelectorEventHandler(teamSelectorId, workerSelectorId, mcdFieldId, updateUrl);
  taskWorkerAssociationsSetupWorkerSelectorEventHandler(teamSelectorId, workerSelectorId, mcdFieldId, updateUrl);

}

export function taskWorkerAssociationsSetupWorkerSelectorEventHandler(teamSelectorId, workerSelectorId, mcdFieldId,
                                                                      updateUrl) {
  var workerSelectSelector = '#' + workerSelectorId;
  taskWorkerAssociationsSetupEventHandler(workerSelectSelector, teamSelectorId, workerSelectorId, mcdFieldId,
                                          updateUrl);

  initializeDateTimePickers();
}


// private helpers -- ---- -- -- --

function taskWorkerAssociationsSetupTeamSelectorEventHandler(teamSelectorId, workerSelectorId, mcdFieldId,
                                                             updateUrl) {
  var teamSelectSelector = '#' + teamSelectorId;
  taskWorkerAssociationsSetupEventHandler(teamSelectSelector, teamSelectorId, workerSelectorId, mcdFieldId, updateUrl);
}

function taskWorkerAssociationsSetupEventHandler(triggerElementSelector, teamSelectorId, workerSelectorId,
                                                 mcdFieldId, updateUrl) {
  var teamSelectSelector   = '#' + teamSelectorId;
  var workerSelectSelector = '#' + workerSelectorId;
  var mcdFieldSelector     = '#' + mcdFieldId + ' input[type=text]';

  // avoid setting up the event handlers more than once
  $(triggerElementSelector).off("change", triggerElementSelector)

  $(triggerElementSelector).change(function() {

    var teamId   = $(teamSelectSelector).val();
    var workerId = $(workerSelectSelector).val();
    var mcd       = $(mcdFieldSelector).val();

    var params = { team_id:   teamId,
                   user_id: workerId,
                   mcd:       mcd };

    $.ajax({
      data:  params,
      type:  'POST',
      url:   updateUrl,
      async: true
    });
  });
}
