/*
 * PMApp::support_requests
 * Place JS here to support functionality specific to the Support Reqeusts controller
 */

import { showLoadingOverlay } from "./loading_overlay";
import { pmappJobsInitializeNewEditForm } from './pmapp_jobs.js.erb';

/*
 * initialization
 */

$(document).on('turbolinks:load', function() {
  if ($('body.support_requests.new').length  || $('body.support_requests.create').length ||
      $('body.support_requests.edit').length || $('body.support_requests.update').length) {
    pmappJobsInitializeNewEditForm();
  }

  if ($('body.support_requests.show').length) {
    supportRequestsInitializeForDragAndDropOwnerAssignment();
  }
});


/*
 * helpers
 */

/*
 * supportRequestsInitializeForDragAndDropOwnerAssignment
 * pmappJobsInitializeForDragAndDropOwnerAssignment does most of the relevant work; this function adds anything
 * that is needed for SRs that is not needed for other job types
 */
function supportRequestsInitializeForDragAndDropOwnerAssignment() {
  if ($('.set-owner-hotspot').length > 0) {
    $('.set-owner-hotspot').on("rosterableReceive",
                                                                           supportRequestsHandleDropTeamMember);
  }
}

/*
 * supportRequestsHandleDropTeamMember
 * pmappJobsRequestsHandleDropTeamMember does most of the relevant work; this function adds anything that is
 * needed for SRs that is not needed for other job types
 */
function supportRequestsHandleDropTeamMember() {
  showLoadingOverlay();
}
