/*
 * PMApp::team_member_binders
 * This file contains JS implemented to support team, location, project, and worker dashboards.
 */

export function teamMemberBindersSetupEventHandlers(teamSelectorId, workerSelectorId, updateUrl) {
  var teamSelectSelector   = '#' + teamSelectorId
  var workerSelectSelector = '#' + workerSelectorId

  // avoid setting up the event handlers more than once
  teamMemberBindersRemoveEventHandlers(teamSelectSelector, workerSelectSelector);

  $(teamSelectSelector + ", " + workerSelectSelector).change(function() {

    var teamId = $(teamSelectSelector).val();
    var workerId = $(workerSelectSelector).val();

    var params = { team_id: teamId,
                   worker_id: workerId }

    $.ajax({
      data:  params,
      type:  'GET',
      url:   updateUrl,
      async: true
    });
  });
}


// private

function teamMemberBindersRemoveEventHandlers(teamSelectSelector, workerSelectSelector) {
  var bothSelector = teamSelectSelector + ", " + workerSelectSelector
  $(bothSelector).off("change", bothSelector)
}
