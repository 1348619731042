/*
 * PMApp::purchase_requests
 * Place JS here to support functionality specific to the Purchase Requests controller
 */

import { pmappJobsInitializeNewEditForm } from './pmapp_jobs.js.erb';

/*
 * initialization
 */

$(document).on('turbolinks:load', function() {
  if ($('body.purchase_requests.new').length  || $('body.purchase_requests.create').length ||
      $('body.purchase_requests.edit').length || $('body.purchase_requests.update').length) {
    pmappJobsInitializeNewEditForm();
  }
});
