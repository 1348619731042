/*
 * PMApp::job_depot.js.erb
 * JS that is specific to the job depot
 */

import { jobListableSetupEventHandlers } from './job_listable.js.erb';
import { showLoadingOverlay            } from "./loading_overlay";
import { teamFocusSetupEventHandlers   } from './team_focus.js.erb';


var jobDepotIntervalId = null;


// -- ---- -- -- --
// initialization
// -- ---- -- -- --

$(document).on('turbolinks:load', function() {

  if (jobDepotIntervalId) {
    clearInterval(jobDepotIntervalId);
    jobDepotIntervalId = null;
  }

  if ($('body.job_depot.depot').length > 0) {
    teamFocusSetupEventHandlers();
    jobDepotSetupEventHandlers();

    $('a.job-action-option-delete').click(function() {
      showLoadingOverlay();
    });

    // set up perodic refresh if needed
    var refreshPath = $('#depotContentContainer').data('refresh-path');
    var oneSecond = 1000;
    var oneMinute = 60 * oneSecond;
    if (refreshPath.length > 0) {
      jobDepotIntervalId = setInterval(function() {
        if (refreshPath.length > 0) {
          $('#refreshIndicator').removeClass('hidden');

          $.ajax({
            data:  { },
            type:  'POST',
            url:   refreshPath,
            async: true
          });
        }
      }, 15 * oneMinute);
    }
  }
});


// public

/*
 * jobDepotSetupEventHandlers
 * initializaton needed when most of the depot changes (for example, if the focus team changes)
 */
export function jobDepotSetupEventHandlers() {
  var paramsForJobList = { };
  jobListableSetupEventHandlers('.entire-page', paramsForJobList);
}

