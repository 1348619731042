/*
 * PMApp::customer_contacts
 * JS that pertains to customer contacts
 */

$(document).on('turbolinks:load', function() {
  if ($('body.customer_contacts.index').length > 0) {
    setupSearchCustomerContactsEventHandler('firstNameField');
    setupSearchCustomerContactsEventHandler('lastNameField');
    setupSearchCustomerContactsEventHandler('emailAddressField');
    setupSearchCustomerContactsEventHandler('phoneNumberField');

    $('#customersSeletor').change(function(e) {
      e = e || window.event
      var triggeringInput = e.target || e.srcElement;
      searchForCustomerContacts(triggeringInput);
    });
  }
});

/*
 * setupSearchCustomerContactsEventHandler
 * This method supports the search-as-you-type search on the customer contacts index page.  Doing a round trip on
 * every keystroke is wasteful and problematic.  Even if i wasn't worried about the waste, trying to do an
 * asynchronous round trip can result in unpredicable results.  Specifically, which ever round-trip requests finishes
 * last will dictate the search results that gets rendered.  The more you type, the quicker the search results are
 * generated, so if you type fast enough the first round trip will finish after the last.  This method sets up the
 * event handler to wait for a 200 ms pause before starting a round trip.
 */

function setupSearchCustomerContactsEventHandler(controlId) {
  var timer = null;

  $('#' + controlId).on('input', function(e) {
      e = e || window.event
      var triggeringInput = e.target || e.srcElement;

      if (timer) {
        // the timer has not expired, clear it to keep the round trip from happening for now
        window.clearTimeout(timer);
      }

      timer = window.setTimeout(function() {
        timer = null; // make sure we will know whether or not the timer expired
        searchForCustomerContacts(triggeringInput); // start the round trip
      }, 200); // wait for a 200 ms pause before starting the round trip; 200 seems to work well; I tried
               // going down to 100 ms but was able to get ahead of it
  });
}

function searchForCustomerContacts(triggeringInput) {
  var form = $(triggeringInput).closest("form");

  $.ajax({
    url:      form.attr('action'),
    data:     form.serialize(),
    dataType: 'script',
    async:    true
  });
}
