/*
 * PMApp:team_focus
 * This file is includes JS that directly supports the team focus control
 */

import { showLoadingOverlay   } from "./loading_overlay";
import { pmappPreventDefaults } from './pmapp.js.erb';

// functions -- ---- -- -- --

/*
 * teamFocusAddFieldsToParams
 * This function is used as a helper by other team focus JS functions as well as by other PMApp JS functions.
 * This function is used to infuse team focus params into a more general params list to be submitted to
 * some fucntionality-specific action.
 * -- ---- -- -- --
 * params: the params list into which the team focus params are to be infused (see description)
 */
export function teamFocusAddFieldsToParams(params) {

  var selectedTeamId = teamFocusGetSelectedTeamId();
  var hostControllerData = teamFocusGetHostControllerData();
  var loadingFlag = teamFocusGetLoadingFlag();
  var readOnlyFlag = teamFocusGetReadOnlyFlag();

  params['team_focus_team_id']   = selectedTeamId;
  params['team_focus_host_data'] = hostControllerData;
  params['team_focus_loading']   = loadingFlag;
  params['team_focus_read_only'] = readOnlyFlag;

  return params;
}

/*
 * teamFocusSetupEventHandlers
 * This function must be called whenever a team focus control is rendered so that the options will appear when the
 * cursor hovers over the selected team.
 * -- ---- -- -- --
 * generateBaseParams: if present, a function to generate a base set of params to which the team focus
 *                     params should be added before hitting the update control action
 */
export function teamFocusSetupEventHandlers(generateBaseParams) {
  $('#teamFocusControlWrapper a').click(function(e) {
    e = e || window.event
    pmappPreventDefaults(e);

    var triggeringElement = e.target || e.srcElement;

    var loadingFlag = teamFocusGetLoadingFlag();
    if (loadingFlag) showLoadingOverlay();

    var params;
    if (generateBaseParams && typeof(generateBaseParams) === typeof(Function))
    {
      params = generateBaseParams();
    }
    else {
      params = {};
    }

    teamFocusChangeTeam(triggeringElement, params);
  });

  if ($('#teamFocusControlWrapper .team-focus-read-only').length == 0) {
    $('#teamFocusControlWrapper .team-focus-control').hover(
      function(e) { // hover in
        $('#teamFocusControlWrapper .team-focus-options-inner').css('display', 'flex');
      },
      function(e) { // hover out
        $('#teamFocusControlWrapper .team-focus-options-inner').css('display', 'none');
      }
    );
  }
}


// event handlers -- ---- -- -- --

/*
 * teamFocusChangeTeam
 * This handler is fired whenever the user picks a new team.
 * -- ---- -- -- --
 * teamElement: the element of the team focus control the user selected to incidate that they wish to
 *              change the team
 * givenParams: a base set of params to which the team focus params should be added before hitting the
 *              update controls action
 */
function teamFocusChangeTeam(teamElement, givenParams) {
  var params = teamFocusAddFieldsToParams(givenParams);

  // change the selected team to the team that was just selected
  var selectedTeamId = $(teamElement).data('team-focus-team-id');
  params['team_focus_team_id'] = selectedTeamId;

  var updateUrlKey = 'team-focus-url';
  var updateUrl = $(teamElement).data(updateUrlKey);

  $.ajax({
    data:  params,
    type:  'POST',
    url:   updateUrl,
    async: true
  });
}


// helpers -- ---- -- -- --

/*
 * teamFocusGetSelectedTeamId
 * Call this method to get the ID of the team that is currently selected in the team focus control.
 */
function teamFocusGetSelectedTeamId() {
  var dataKey = 'team-focus-team-id';
  return $('#teamFocusControlWrapper span.team-focus-selected').data(dataKey);
}

/*
 * teamFocusGetSelectedTeamId
 * Call this method to get the ID of the team that is currently selected in the team focus control.
 */
function teamFocusGetHostControllerData() {
  var dataKey = 'team-focus-host-data';
  return $('#teamFocusControlWrapper span.team-focus-selected').data(dataKey);
}

function teamFocusGetLoadingFlag() {
  var dataKey = 'team-focus-loading';
  return $('#teamFocusControlWrapper span.team-focus-selected').data(dataKey);
}

function teamFocusGetReadOnlyFlag() {
  var dataKey = 'team-focus-read-only';
  return $('#teamFocusControlWrapper span.team-focus-selected').data(dataKey);
}
