/*
 * PMApp::kpi
 * This file contains JS implemented to support key performance indicators that appear on the worker dashboard
 */

$(document).on('turbolinks:load', function() {

  $('.collapse').on('show.bs.collapse', function() {
    $('.meter-expander svg').removeClass('fa-caret-down');
    $('.meter-expander svg').addClass('fa-caret-up');
  });

  $('.collapse').on('hide.bs.collapse', function() {
    $('.meter-expander svg').removeClass('fa-caret-up');
    $('.meter-expander svg').addClass('fa-caret-down');
  });

  $('#yearKPIMeter').click(function(event) {
    if (event.metaKey) {
      var yearToDateHours = $('#yearKPIMeter')
                              .data('ytd-hours');
      alert('The number of possible work hours this year is ' + yearToDateHours +
            '.\nNOTE: This number was computed when this page was last refreshed.')
    }
  });

});
