/*
 * PMApp::billing_documents
 * JS that pertains to billing documents
 */

import { showLoadingOverlay          } from "./loading_overlay";
import { pmappPreventDefaults        } from './pmapp.js.erb';
import { teamFocusSetupEventHandlers } from './team_focus.js.erb';

$(document).on('turbolinks:load', function() {
  if ($('body.billing_documents').length > 0) {
    teamFocusSetupEventHandlers();
    billingDocumentsSetupActiveDocumentEventHandlers()
  }
});


// public methods

export function billingDocumentsHandleMilestoneSelect() {
  var selectedMilestoneIds = billingDocumentsConstructSelectedMilestoneIds();

  var docId = $('#formContainer').data(
    'doc-id'
  );

  var periodId = null;
  var periodMode = null;
  if ($('#periodModeHidden').length > 0) {
    periodMode = $('#periodModeHidden').val();
  }
  else {
    periodId = $('#periodSelect').val();
  }

  var updateInvoiceSummaryUrl = $('#formContainer').data(
    'update-invoice-summary-url'
  );

  var params = {
    milestones:  selectedMilestoneIds
  };

  if (docId) {
    params['id'] = docId;
  }

  if (periodId) {
    params['billing_period_id'] = periodId;
  }

  if (periodMode) {
    params['period_mode'] = periodMode;
  }

  // TODO - get update agreements check state => override_agreements

  $.ajax({
    data:  params,
    type:  'POST',
    url:   updateInvoiceSummaryUrl,
    async: true
  });
}

export function billingDocumentsSetupActiveDocumentEventHandlers() {

  // change the up/down arrow in the ready list "tab" control
  $('#collapsibleList').on('show.bs.collapse', function() {
    $('#collapsibleListExpandCollapseLink').html(
      '<i class="fas fa-angle-up"></i>'
    );
  });

  $('#collapsibleList').on('hide.bs.collapse', function() {
    $('#collapsibleListExpandCollapseLink').html(
      '<i class="fas fa-angle-down"></i>'
    );
  });

  billingDocumentsSetupMilestoneEventHandlers();

  // show the loading overlay upon submission of the active document form
  $('#billingDocumentFormSubmitButton').click(function(e) {
    showLoadingOverlay();
  });

  billingDocumentsSetupPeriodLinkEventHandlers();
  billingDocumentsSetupUpdateAgreementsEventHandlers();
  billingDocumentsSetupPrimaryActionsEventHandlers();
}

export function billingDocumentsSetupMilestoneEventHandlers() {
  // update the invoice summary and primary actions when a milestone is checked or unchecked
  var checkSelector = billingDocumentsConstructMilestoneCheckBoxSelector();
  $(checkSelector).change(billingDocumentsHandleMilestoneSelect);

  // make billable link
  var linkSelector = '.make-billable-link';
  $(linkSelector).click(function(e) {
    e = e || window.event
    pmappPreventDefaults(e);

    var linkIcon = e.target || e.srcElement;
    var triggeringLink = $(linkIcon).closest('a');

    var makeBillableUrl = $(triggeringLink).attr('href');

    var milestoneId = $(triggeringLink).data('milestone-id');
    var selectedMilestoneIds = billingDocumentsConstructSelectedMilestoneIds();
    var params = {
      milestone_id: milestoneId,
      milestones:   selectedMilestoneIds
    }

    var docId = $(triggeringLink).data('doc-id');
    if (docId) {
      params['id'] = docId;
    }

    var locId = $(triggeringLink).data('location-id');
    if (locId) {
      params['location_id'] = locId;
    }

    var customerId = $(triggeringLink).data('customer-id');
    if (customerId) {
      params['cusotmer_id'] = customerId;
    }

    // TODO - get value of update agreements => override_agreements

    $.ajax({
      data:  params,
      type:  'POST',
      url:   makeBillableUrl,
      async: true
    });

  });
}

export function billingDocumentsSetupPeriodLinkEventHandlers() {
  var linkSelector = '.billing-period-link';
  $(linkSelector).click(function(e) {
    e = e || window.event
    pmappPreventDefaults(e);

    var linkIcon = e.target || e.srcElement;
    var triggeringLink = $(linkIcon).closest('a');

    var newPeriodUrl = $(triggeringLink).attr('href');

    var selectedMilestoneIds = billingDocumentsConstructSelectedMilestoneIds();
    var params = {
      milestones: selectedMilestoneIds
    }

    // TODO - get value of update agreements check; this needs to be used to set the check checked or unchecked
    //        again before and after the edit

    $.ajax({
      data:  params,
      type:  'GET',
      url:   newPeriodUrl,
      async: true
    });
  });
}

export function billingDocumentsSetupPrimaryActionsEventHandlers() {
  $('.billing-doc-delete-link').click(function(e) {
    e = e || window.event


    var linkIcon = e.target || e.srcElement;
    var triggeringLink = $(linkIcon).closest('a');

    var confirmationMessage = $(triggeringLink).data('pmappconfirm');
    if (confirmationMessage && confirmationMessage.length > 0) {
      var result = confirm(confirmationMessage);
      if (! result) {
        pmappPreventDefaults(e);
      }
      else {
        showLoadingOverlay();
      }
    }
    else {
      showLoadingOverlay();
    }
  });

  // TODO add click event handler for preview link
}

export function billingDocumentsSetupUpdateAgreementsEventHandlers() {
  var selectSelector = '#periodSelect';
  $(selectSelector).change(function(e) {
    e = e || window.event
    var triggeringSelect = e.target || e.srcElement;

    var periodId = $(triggeringSelect).val();

    var updateAgreementsUrl = $(triggeringSelect).data(
      'url'
    );

    var selectedMilestoneIds = billingDocumentsConstructSelectedMilestoneIds();
    var params = {
      billing_period_id: periodId,
      milestones:       selectedMilestoneIds
    }

    $.ajax({
      data:  params,
      type:  'GET',
      url:   updateAgreementsUrl,
      async: true
    });
  });

  var checkSelector = '#billingPeriodControlsContainer input[type=checkbox]';
  $(checkSelector).change(function(e) {
    e = e || window.event
    var triggeringCheck = e.target || e.srcElement;

    var selectSelector = '#periodSelect';
    var periodId = $(selectSelector).val();

    var updateAgreementsUrl = $(triggeringCheck).data(
      'url'
    );

    var selectedMilestoneIds = billingDocumentsConstructSelectedMilestoneIds();
    var params = {
      billing_period_id: periodId,
      milestones:       selectedMilestoneIds
    }

    if ($(triggeringCheck).prop('checked')) {
      params["override_agreements"] = true;
    }

    $.ajax({
      data:  params,
      type:  'GET',
      url:   updateAgreementsUrl,
      async: true
    });
  });
}


// private methods

function billingDocumentsConstructSelectedMilestoneIds() {
  var selectedMilestoneIds = [];

  var checkSelector = billingDocumentsConstructMilestoneCheckBoxSelector();
  $(checkSelector).each(
    function(index, checkBoxElement) {
      if ($(checkBoxElement).prop('checked')) {
        var checkBoxValue = $(checkBoxElement).val();
        selectedMilestoneIds.push(checkBoxValue);
      }
    }
  );

  return selectedMilestoneIds;
}

function billingDocumentsConstructMilestoneCheckBoxSelector() {
  return '#billingDocumentForm ' +
         '.milestone-item-row ' +
         'input[type=checkbox]';
}
