/*
 * PMApp::periodable.js.erb
 */

import { pmappPreventDefaults } from './pmapp.js.erb';

// public methods

/*
 * periodableSetupEventHandlers
 * initializaton needed when most of the dashboard changes (for example, if the period or focus team changes)
 * -- ---- -- -- --
 * generateOutsideParams: if given, a handle to a function for fetching params from other components and such
 *                        that need to be passed the server along with params that are specific to the
 *                        events handled here;  see periodableGenerateOutsideParams below for more
 *                        information
 */
export function periodableSetupEventHandlers(generateOutsideParams) {
  $('.period-option').click(function(e) {
    e = e || window.event

    // we don't want these links doing their usual link things...
    pmappPreventDefaults(e);

    // but we do want them doing their bootstrap things
    var optionLink = e.target || e.srcElement;
    var dropDown = $(optionLink).closest('.dropdown-menu');
    dropDown.dropdown('hide');

    var newPeriod = $(optionLink).data('value');

    var periodSelector = periodableBuildSelector();
    var periodUrl = $(periodSelector).data('url');

    var periodParams = periodableFetchParams();
    periodParams['periodable_value'] = newPeriod;

    var outsideParams = periodableGenerateOutsideParams(generateOutsideParams);

    var params = Object.assign(periodParams, outsideParams);

    $.ajax({
      data:  params,
      type:  'POST',
      url:   periodUrl,
      async: true
    });
   
  });
}

/*
 * periodableFetchParams
 * construct an object that defines params for submitting to the server; the params will describe the current
 * state of the period selector
 */
export function periodableFetchParams() {
  var periodSelector = periodableBuildSelector();
  var period = $(periodSelector).data('period');

  // startTime will be a string, endTime, if present, will also be a string
  var startTime = $(periodSelector).data('start-time');
  var endTime   = $(periodSelector).data('end-time');

  return {
    periodable_value:      period,
    periodable_start_time: startTime,
    periodable_end_time:   endTime
  }
}


// helpers

function periodableBuildSelector() {
  return '.job-dashboard-period-select';
}

/*
 * periodableGenerateOutsideParams
 * checks to see if the given argument is a function and if so uses the function to generate outside params;
 * if it is not a function the outside params will be set to an empty object
 * -- ---- -- -- --
 * generateOutsideParams: this is expected to be null, or a handle to a function; if a handle to a function,
 *                        the function should not expect any arguments and should return an object that can
 *                        be used as params to send the server
 */
function periodableGenerateOutsideParams(generateOutsideParams) {
  var outsideParams;

  if (generateOutsideParams && typeof(generateOutsideParams) == typeof(Function)) {
    outsideParams = generateOutsideParams();
  }
  else {
    outsideParams = { }
  }

  return outsideParams;
}
