/*
 * PMApp::milestones
 * JS that pertains to milestones
 */

import { showLoadingOverlay          } from "./loading_overlay";
import { pmappPreventDefaults        } from './pmapp.js.erb';
import { teamFocusSetupEventHandlers } from './team_focus.js.erb';


$(document).on('turbolinks:load', function() {
  if ($('body.milestones.dashboard').length > 0 || $('body.milestones.review_index').length > 0) {
    teamFocusSetupEventHandlers();
  }

  if ($('body.milestones.cost_recovery_report').length > 0) {
    const stuckHeaderClassName = 'stuck';

    teamFocusSetupEventHandlers();

    $(window).scroll(function(e) {
      var offset = $('#headingContainer').offset();
      if (offset.top - $(window).scrollTop() == 0) {
        $('#headingContainer').addClass(stuckHeaderClassName);
      } else {
        $('#headingContainer').removeClass(stuckHeaderClassName);
      }
    });
  }

  milestonesSetupReviewBillingDashboardSortHanlders();
});


// methods -- ---- -- -- --

export function milestonesSetupReviewBillingDashboardSortHanlders() {
  var linkSelector = '#dashboardDetailsContainer ' +
                     'a.review-dashboard-sort-link'

  $(linkSelector).click(function(event) {
    event = event || window.event
    pmappPreventDefaults(event);

    var icon = event.target || event.srcElement;
    var clickedLink = $(icon).closest('a');

    var requestPath = $(clickedLink).attr("href");
    var sortParam   = $(clickedLink).data("sort");

    showLoadingOverlay();

    // TODO - dataType is necessary here since requestPath refers to an action that ordinarily would be an
    //        HTML action instead of a JS action, but we want it to respond as a JS action in this case; while I
    //        am not opposed to actions that can go either way (a form that can render in the main window or in
    //        a modal, for example), in this case, we are giving the action two different purposes (render the
    //        dashboard and sort the dashboard) because MOST of what is needed for one action is also needed for
    //        the other; this needs to be refactored to have an action specifically for sorting
    $.ajax({
      data:     { review_sort: sortParam },
      type:     'GET',
      dataType: 'script',
      url:      requestPath,
      async:    true
    });
  });
}
