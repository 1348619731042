/*
 * PMApp::loading_overlay
 * JS for the loading overlay used throughout PMApp
 */

export function showLoadingOverlay() {
  if ($('div.loading-overlay').length > 0) {
    $('div.loading-overlay').show();
    $('body').css("overflow", "hidden");
  }
}

export function hideLoadingOverlay() {
  if ($('div.loading-overlay').length > 0) {
    $('div.loading-overlay').hide();
    $('body').css("overflow", "");
  }
}

export function doReload() {
  // if the current page has a loading overlay, use it
  showLoadingOverlay();
  location.reload();
}
