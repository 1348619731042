/*
 * PMApp::metricable.js.erb
 * JS that supports functionality provided by the Metricable controller concern
 */

/*
 * constants
 * -- ---- -- -- --
 * darkBkgMetricLabelClass:  the class to add to a pie slice label or bar chart bar label if the fill/bkg color
 *                           is a dark color
 * lightBkgMetricLabelClass: the class to add to a pie slice label or bar chart bar label if the fill/bkg color
 *                           is a light color
 */

export var lightBkgMetricLabelClass = "txt-invert";
export var darkBkgMetricLabelClass  = "txt-normal";


// public -- ---- -- -- --

/*
 * metricableColorBarChartBarLabels
 * adds supplementary classes to bar chart bar labels to optimize contrast between the labels and the segment
 * background colors
 */
export function metricableColorBarChartBarLabels() {
  $('.bar-chart-segment').each(function(index) {
    var webColor = $(this).attr('fill');
    $(this).next('text[class^=bar-chart-segment-label]').addClass(
      metricableSelectMetricLabelClass(webColor)
    );
  });
}

/*
 * metricableSelectMetricLabelClass
 * given a background color, determines which label class to use to ensure decent contrast between the
 * given color and the label
 * -- ---- -- -- --
 * webColor: the given background color
 */
export function metricableSelectMetricLabelClass(webColor) {
  var returnValue = lightBkgMetricLabelClass;

  var rgb = metricableWebColorToRgb(webColor);

  var luminance = ((0.299 * rgb.red) + (0.587 * rgb.green) + (0.114 * rgb.blue))/255;
  if (luminance > 0.5) {
    returnValue = darkBkgMetricLabelClass;
  }
  
  return returnValue;
}


// private -- ---- -- -- --

/*
 * metricableCompColorToRgb
 * converts "rgb(RRR, GGG, BBB)" to { red: <red>, green: <green>, blue: <blue> }
 * -- ---- -- -- --
 * compColor: the string to convert
 */
function metricableCompColorToRgb(compColor) {
  var result = compColor.substring(4, compColor.length - 1).replace(/ /g, '').split(',');
  return result ? {
    red:   parseInt(result[0]),
    green: parseInt(result[1]),
    blue:  parseInt(result[2])
  } : null;
}

 /*
  * metricableHexColorToRgb
  * converts "#XXXXXX" to { red: <red>, green: <green>, blue: <blue> }
  * -- ---- -- -- --
  * hex: the string to convert
  */
function metricableHexColorToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    red:   parseInt(result[1], 16),
    green: parseInt(result[2], 16),
    blue:  parseInt(result[3], 16)
  } : null;
}

/*
 * metricableWebColorToRgb
 * converts "#XXXXXX" or "rgb(RRR, GGG, BBB)" to { red: <red>, green: <green>, blue: <blue> }
 * -- ---- -- -- --
 * webColor: the string to convert
 */
function metricableWebColorToRgb(webColor) {
  var rgb = null;

  if (webColor.charAt(0) == '#') {
    rgb = metricableHexColorToRgb(webColor);
  }
  else {
    rgb = metricableCompColorToRgb(webColor);
  }

  return rgb;
}
