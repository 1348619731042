/*
 * PMApp::attachable
 * Place JS to support the Attachable component in this file
 */

import { ajaxPrologue, ajaxEpilogue, pmappPreventDefaults } from './pmapp.js.erb';

/*
 * TODO
 * -- ---- -- -- --
 * modalId: the ID of the modal in which the Attachable will be rendered; undefined if the Attachable has already
 *          been rendered directly on the page (not in a modal)
 */
export function attachableSetupEventHandlers(modalId) {

  // we hide the mouse icon here, instead of via CSS, so that the CSS can given the button/link an initial
  // display setting; this way jQuery hide/show will toggle between display:none and whatever is set in the CSS
  $('.mouse').hide();

  attachableSetupDropZoneEventHandlers();

  $('.attachable-controls-toggle').click(function(event) {
    pmappPreventDefaults(event);

    var e = event || window.event
    var triggeringLinkIcon = e.target || e.srcElement;
    var triggeringLink = $(triggeringLinkIcon).closest('a');
    var triggeringLinkId = triggeringLink.attr('id');

    triggeringLink.hide();
    var showSelector = '.attachable-controls-toggle.';
    if (triggeringLink.hasClass('mobile')) {
      showSelector += 'mouse';
      $(showSelector).show();

      $('#attachableControlContainer').hide();
      $('#attachableControlButton').show();
    }
    else {
      showSelector += 'mobile';
      $(showSelector).show();

      $('#attachableControlContainer').show();
      $('#attachableControlButton').hide();
    }
  });

  $('#attachableControlButton').on('change', function(event) {
    attachableRemoveUploadedDocument(false);
  });

  var modalElement = $('#attachableAttachmentIdField').closest('modal');
  if (modalId) {
    $('#' + modalId).on('hide.bs.modal', function(event) {
      attachableRemoveUploadedDocument(true);
    });
  }
  else {
    $(window).on('unload', function(event) {
      attachableRemoveUploadedDocument(true);
    });
  }
}

export function attachableSetupDropZoneEventHandlers() {
  $('#attachableDropZone').on('dragenter dragover dragleave drop', pmappPreventDefaults);
  $('#attachableDropZone').on('dragenter', attachableActivateDropArea);
  $('#attachableDropZone').on('dragleave', attachableDeactivateDropArea);
  $('#attachableDropZone').on('drop', attachableHandleDrop);

  $( window ).on('dragover drop', pmappPreventDefaults);
}

function attachableRemoveUploadedDocument(hiding) {
  var unloadLink = $('#attachableUnloadLink');

  if (unloadLink.length > 0) {
    var unloadUrl = unloadLink.attr('href');

    $.ajax({
      data:  { hiding: hiding },
      type:  'POST',
      url:   unloadUrl,
      async: true
    });
  }
}

function attachableActivateDropArea(event) {
  $('#attachableDropZone').removeClass("idle");
  $('#attachableDropZone').removeClass("uploading");
  $('#attachableDropZone').addClass("active");
}

function attachableDeactivateDropArea(event) {
  $('#attachableDropZone').removeClass("active");
  $('#attachableDropZone').removeClass("uploading");
  $('#attachableDropZone').addClass("idle");
}

/*
 * attachableHandleDrop
 * handle a "user drops a file in the drop zone" event
 * -- ---- -- -- --
 * event
 */
function attachableHandleDrop(event) {
  $('#attachableDropZone').removeClass("idle");
  $('#attachableDropZone').removeClass("active");
  $('#attachableDropZone').addClass("uploading");

  // get needed parameters
  var dropZoneElement = $(event.target);
  var uploadDocumentPath = dropZoneElement.data('url');

  // get dropped file list
  var dt = event.originalEvent.dataTransfer;
  var files = dt.files;

  if (files.length > 0 ) {
    $('#attachableControlButton').val('');
    var file = files[0];

    var formData = new FormData();
    formData.append('attachable[content]', file); // TODO - this used to say receipt_docuemnt[content]
    formData.append('authenticity_token', $('[name="csrf-token"]')[0].content);

    fetch(uploadDocumentPath, {
      method: "post",
      headers: {
        // this isn't strictly necessary, but we may as well be as specific as is possible
        'Accept': 'text/javascript'
      },
      body:   formData
    })
    .then(response => response.json())
    .then(data => {
      ajaxPrologue();
      $('#' + data.id_field_id).attr('value', data.id);
      $('#' + data.wrapper_id).html(data.new_html);
      $('#attachableDropZone').removeClass("uploading");
      $('#attachableDropZone').removeClass("active");
      $('#attachableDropZone').addClass("idle");
      if (! data.id)attachableSetupDropZoneEventHandlers();
      if (data.error_html) $('#' + data.error_cid).html(data.error_html);
      ajaxEpilogue();
    });
  }
}
