/*
 * PMApp::projects
 * JS that is specific to the Projects controller
 */

import { pmappJobsInitializeNewEditForm } from './pmapp_jobs.js.erb';
import { showLoadingOverlay             } from "./loading_overlay";
import { pmappPreventDefaults           } from './pmapp.js.erb';
import { teamFocusSetupEventHandlers    } from './team_focus.js.erb';


$(document).on('turbolinks:load', function() {
  if ($('body.projects.new').length  || $('body.projects.create').length ||
      $('body.projects.edit').length || $('body.projects.update').length) {
    projectsInitializeNewEditForm();
  }

  if ($('body.projects.prompt_for_quote').length) {
    teamFocusSetupEventHandlers();

    $('form.prompt-for-quote-form').submit(function() {
      showLoadingOverlay();
    });
  }
});


// helpers -- ---- -- -- --

function projectsInitializeNewEditForm() {
  pmappJobsInitializeNewEditForm();

  // use click here, instead of change, to avoid an infinite loop of event handlers firing
  $('input[id^="groupBudgetCheckbox"]').click(function(e) {

    // if the subject check is now checked, uncheck all of the line item budget checkboxes in the group
    var groupNumber = $(this).prop("value");
    if (this.checked) checkUncheckAllBudgetLineItems(groupNumber, false)
  });

  // use click here, instead of change, to avoid an infinite loop of event handlers firing
  $('input[id^="lineBudgetCheckbox"]').click(function(e) {

    // if the subject check is now checked, uncheck the corresponding group budget checkbox and check all sibling
    // line item budget checkboxes; otherwise, check the corresponding group budget checkbox and uncheck all
    // sibling line item budget checkboxes

    var groupCheckedValue = true;
    if (this.checked) groupCheckedValue = false;

    var groupNumber = $(this).data("group");
    checkUncheckBudgetGroup(groupNumber, groupCheckedValue);
    checkUncheckAllBudgetLineItems(groupNumber, ! groupCheckedValue)

    // also, we cannot have the budget split into line items and have one group task, so, if a budget line
    // item is checked, we need to uncheck the group task
    if (this.checked) {

      // if the group task was checked, then we should also check all of the line item checks for that group
      if (isCheckedTaskGroup(groupNumber)) checkUncheckAllTaskLineItems(groupNumber, true);
      checkUncheckTaskGroup(groupNumber, false);
    }

  });

  // use click here, instead of change, to avoid an infinite loop of event handlers firing
  $('input[id^="groupTaskCheckbox"]').click(function(e) {

    // if the subject check is now checked, uncheck all of the line item task checkboxes in the group
    var checkedValue = true;
    var groupNumber = $(this).prop("value");
    if (this.checked) {
      checkUncheckAllTaskLineItems(groupNumber, false);

      // also make sure the budget group is checked
      checkUncheckBudgetGroup(groupNumber, true);
    }
  });

  // use click here, instead of change, to avoid an infinite loop of event handlers firing
  $('input[id^="lineTaskCheckbox"]').click(function(e) {
    // if the subject check is now checked, uncheck the corresponding group task checkbox
    var groupNumber = $(this).data("group");
    if (this.checked) checkUncheckTaskGroup(groupNumber, false);
  });

  $('#resetComponentChecksButton').click(function(e) {
    e = e || window.event
    e.preventDefault();
    e.stopPropagation();

    // budget checks
    $('input[id^="groupBudgetCheckbox"]').prop("checked", false);
    $('input[id^="lineBudgetCheckbox"]').prop("checked", false);
    $('input[id^="ungroupedLineBudgetCheckbox"]').prop("checked", false);

    // task checks
    $('input[id^="groupTaskCheckbox"]').prop("checked", true);
    $('input[id^="lineTaskCheckbox"]').prop("checked", false);
    $('input[id^="ungroupedLineTaskCheckbox"]').prop("checked", true);

    // material item checks
    $('input[id^="lineMaterialItemCheckbox"]').prop("checked", true);
  });
}

function buildTaskGroupCheckboxSelector(groupNumber) {
  return '#groupTaskCheckbox' + groupNumber;
}

function checkUncheckAllBudgetLineItems(groupNumber, checked) {
  $('input[id^="lineBudgetCheckbox"]').each(function() {
    if ($(this).data("group") == groupNumber) {
      $(this).prop('checked', checked);
    }
  });
}

function checkUncheckAllTaskLineItems(groupNumber, checked) {
  $('input[id^="lineTaskCheckbox"]').each(function() {
    if ($(this).data("group") == groupNumber) {
      $(this).prop('checked', checked);
    }
  });
}

function checkUncheckBudgetGroup(groupNumber, checked) {
  var checkBoxSelector = '#groupBudgetCheckbox' + groupNumber;
  $(checkBoxSelector).prop('checked', checked);
  if (checked) checkUncheckAllBudgetLineItems(groupNumber, ! checked)
}

function checkUncheckBudgetLineitem(groupNumber, lineNumber, checked) {
  var checkBoxSelector = '#lineBudgetCheckbox' + lineNumber +
                         '[data-group=' + groupNumber;
  $(checkBoxSelector).prop('checked', checked);
}

function checkUncheckTaskGroup(groupNumber, checked) {
  var checkBoxSelector = buildTaskGroupCheckboxSelector(groupNumber);
  $(checkBoxSelector).prop('checked', checked);
}

function checkUncheckTaskLineitem(groupNumber, lineNumber, checked) {
  var checkBoxSelector = '#lineTaskCheckbox' + lineNumber +
                         '[data-group=' + groupNumber;
  $(checkBoxSelector).prop('checked', checked);
}


function isCheckedTaskGroup(groupNumber) {
  var checkBoxSelector = buildTaskGroupCheckboxSelector(groupNumber);
  return $(checkBoxSelector).prop('checked');
}
