/*
 * PMApp::pmapp_sliders
 */

$(document).on('turbolinks:load', function() {
  $('.pmapp-slider-grab').click(function(event) {
    event = event || window.event

    var clickedGrab = event.target || event.srcElement;
    pmappSlidersOpenClose(clickedGrab);
  });
});


/*
 * pmappSlidersClose
 * use this function to programmatically close a slider, if it is open; this function will have no effect
 * if the specified slider is already closed
 * -- ---- -- -- --
 * sliderDiv: the outer-most div of he slider to close; this div should have the classe pmapp-slider
 */
export function pmappSlidersClose(sliderDiv) {
  sliderDiv.removeClass('active');
}


// event handlers -- ---- -- -- --

/*
 * pmappSlidersOpenClose
 * use this handler when a user clicks on a slider grab
 * -- ---- -- -- --
 * clickedGrab: a handle to the grab that was clicked; this is currently not used; actually, when any grab
 *              is clicked, any open slider will be closed and any closed slider will be open, weird
 */
function pmappSlidersOpenClose(clickedGrab) {
  var sliderDiv = $('.pmapp-slider-grab').closest('div.pmapp-slider');
  var opening = ! sliderDiv.hasClass('active');

  var allSliders = $('.pmapp-slider');
  pmappSlidersClose(allSliders);

  if (opening) {
    sliderDiv.addClass('active');
  }
}


