/*
 * PMApp::worker_avatar_list
 * JS for worker avatar lists
 */

// public functions -- ---- -- -- --

/*
 * workerAvatarListInitializePopovers
 * initializes popovers for a worker avatar list
 * -- ---- -- -- --
 * bodylessSelector: a CSS selector [prefix] that can be used to select those avatars that should have
 *                   bodyless popovers
 * withBodySelector: a CSS selector [prefix] that can be used to select those avatars that should have bodies
 *                   in their popovers
 * allSelector:      a CSS selector [prefix] that can be used to select all avatars in the list
 */
export function workerAvatarListInitializePopovers(bodylessSelector, withBodySelector, allSelector) {

  /*
   * the default popover template looks like this:
   *
   * <div class="popover" role="tooltip">
   *   <div class="arrow"></div>
   *   <h3 class="popover-header"></h3>
   *   <div class="popover-body"></div>
   * </div>
   */

  // task listable
  var bodylessTemplate = '<div class="popover" role="tooltip">' +
                         '<div class="arrow"></div>' +
                         '<div class="popover-header"></div>';

  var withBodyTemplate = '<div class="popover" role="tooltip">' +
                           '<div class="arrow"></div>' +
                           '<div class="popover-header"></div>' +
                           '<div class="popover-body"></div>' +
                         '</div>';


  // keep bootstrap from stripping rails data attribtes from embedded links
  var bootstrapSanitizerWhiteList = $.fn.tooltip.Constructor.Default.whiteList
  bootstrapSanitizerWhiteList.a.push('data-confirm')
  bootstrapSanitizerWhiteList.a.push('data-remote')
  bootstrapSanitizerWhiteList.a.push('data-method')

  if (bodylessSelector) {
    var bodylessPopoverSelector = bodylessSelector + ' [data-toggle=popover]';
    $(bodylessPopoverSelector).popover({ html:        true,
                                         customClass: 'worker-avatar-list-popover',
                                         placement:   'bottom',
                                         template:    bodylessTemplate,
                                         trigger:     'click' })
  }

  if (withBodySelector) {
    var withBodyPopoverSelector = withBodySelector + ' [data-toggle=popover]';
    $(withBodyPopoverSelector).popover({ html:        true,
                                         customClass: 'worker-avatar-list-popover',
                                         placement:   'bottom',
                                         template:    withBodyTemplate,
                                         trigger:     'click' })
  }

  // add an event handler to close any neighboring popovers whenever the user requests a popover
  var allPopoverSelector = allSelector + ' [data-toggle=popover]';
  $(allPopoverSelector).on('show.bs.popover', workerAvatarListHideOtherPopovers);
}

/*
 * workerAvatarListDestroyeAssigneePopovers
 * dispose of popovers that have been created for an assignee section
 * -- ---- -- -- --
 * listContainerSelector: a CSS selector to select the list container
 */
export function workerAvatarListDestroyPopovers(listContainerSelector) {
  var selector = listContainerSelector + ' [data-toggle=popover]';
  $(selector).popover('dispose');
}


// event handlers -- ---- -- -- --

/*
 * workerAvatarListHideOtherPopovers
 * whenever an assignee popover is opened, close any of its neighboring popovers
 */
function workerAvatarListHideOtherPopovers(event) {
  event = event || window.event

  var workerAvatar = event.target || event.srcElement;

  var listContainer = $(workerAvatar).closest('[class*=worker-avatar-list]');
  listContainer.find('[data-toggle=popover]').each(function(index) {
    if (this != workerAvatar) {
      $(this).popover('hide');
    }
  });
}
