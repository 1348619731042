/*
 * PMApp::customer_dashboards
 * JS that is specific to the Customer Dashboards controller
 */

import { periodableSetupEventHandlers,
         periodableFetchParams          } from './periodable.js.erb';
import { jobListableSetupEventHandlers,
         jobListableFetchFilterParams,
         jobListableFetchSortParams     } from './job_listable.js.erb';
import { showLoadingOverlay             } from "./loading_overlay";
import { pmappPreventDefaults           } from './pmapp.js.erb';
import { teamFocusSetupEventHandlers    } from './team_focus.js.erb';


/*
 * constants
 */

const pwaDescSelector         = '.pwa-usage-container ' +
                                '.graph-desc'
const pwaInfoToggledLinkClass = 'toggled-on';
const pwaPairsSelector        = '.pwa-usage-container ' +
                                '.pwa-pairs'



$(document).on('turbolinks:load', function() {
  if ($('body.customer_dashboards.dashboard').length > 0) {
    teamFocusSetupEventHandlers();
    customerDashboardsSetupPeriodSelectEventHandlers();


    if ($('.location-select-option').length > 0) {
      customerDashboardsSetupLocationSelectEventHandlers();
    }

    if ($('#allTeamsCheck').length > 0) {
      $('#allTeamsCheck').change(function(event) {
        var e = event || window.event
        var allTeamsCheck = e.target || e.srcElement;

        var isChecked = $(allTeamsCheck).prop('checked');
        var targetPath = $(allTeamsCheck).data('url');

        var params = periodableFetchParams();
        if (isChecked) {
          params["all_teams"] = "true";
        }

        showLoadingOverlay();

        $.ajax({
          data:  params,
          type:  'POST',
          url:   targetPath,
          async: true
        });
      });
    }

    if ($('#pwaInfoToggleLink').length > 0) {

      // initialize the PWA info and toggle link state
      $('#pwaInfoToggleLink').removeClass(pwaInfoToggledLinkClass);
      $(pwaDescSelector).hide();
      $(pwaPairsSelector).hide();

      customerDashboardsSetupAllocationUsageEventHandlers();
    }

    customerDashboardsSetupJobListsEventHandlers();
  }
});



// public methods

/*
 * customerDashboardsFetchParamsForJobList
 * this method will be passed to JobListable so that "period" params can be passed to server along with any
 * JobListable params whenever a JobListable event occurs
 * -- ---- -- -- --
 * sectionName: when JobListable calls this method, it passes in the section name that identifies the job list
 *              for which the method is being called; in this case, we do the same thing regardless of the
 *              job list identified by this argument
 */
export function customerDashboardsFetchParamsForJobList(sectionName) {
  var locationParams = customerDashboardsFetchLocationParams();
  var periodParams   = periodableFetchParams();
  var allTeamsParams = customerDashboardsFetchAllTeamsParams();

  return Object.assign(locationParams, periodParams, allTeamsParams);
}

export function customerDashboardsGetInfoToggleState() {
  return $('#pwaInfoToggleLink').hasClass(pwaInfoToggledLinkClass);
}

export function customerDashboardsSetInfoToggleState(infoToggledOn) {
  if (infoToggledOn) {
    $('#pwaInfoToggleLink').addClass(pwaInfoToggledLinkClass);
    $(pwaDescSelector).show();
    $(pwaPairsSelector).show();
  }
  else {
    $('#pwaInfoToggleLink').removeClass(pwaInfoToggledLinkClass);
    $(pwaDescSelector).hide();
    $(pwaPairsSelector).hide();
  }
}

/*
 * customerDashboardsSetupAllocationUsageEventHandlers
 * configures event handlers for the Allocation Usage section of the customer dashboard summary tile
 */
export function customerDashboardsSetupAllocationUsageEventHandlers() {
  $('#pwaInfoToggleLink').click(function(event) {
    var e = event || window.event

    pmappPreventDefaults(e);

    var toggleLinkIcon = e.target || e.srcElement;
    var toggleLink = $(toggleLinkIcon).closest('a');

    var infoToggledOn = customerDashboardsGetInfoToggleState();
    customerDashboardsSetInfoToggleState(! infoToggledOn);
  });
}

/*
 * customerDashboardsSetupJobListsEventHandlers
 * configures event handlers for the Job Lists on the customer dashboard
 */
export function customerDashboardsSetupJobListsEventHandlers() {
  jobListableSetupEventHandlers('.entire-page',
                                customerDashboardsFetchParamsForJobList);

  $('a.job-action-option-delete').click(function() {
    showLoadingOverlay();
  });
}

export function customerDashboardsSetupLocationSelectEventHandlers() {
  $('.location-select-option').click(function(event) {
    var e = event || window.event

    // we don't want these links doing their usual link things...
    pmappPreventDefaults(e);

    // but we do want them doing their bootstrap things
    var optionLink = e.target || e.srcElement;
    var dropDown = $(optionLink).closest('.dropdown-menu');
    dropDown.dropdown('hide');

    var targetPath = $(optionLink).attr('href')

    var params = periodableFetchParams();

    var allTeamsIsChecked = $('#allTeamsCheck').prop('checked');
    if (allTeamsIsChecked) {
      params["all_teams"] = "true";
    }

    $.ajax({
      data:  params,
      type:  'POST',
      url:   targetPath,
      async: true
    });

    showLoadingOverlay();
  });
}

/*
 * customerDashboardsSetupPeriodSelectEventHandlers
 # configures event handler for the period select on the customer dashboard
 */
export function customerDashboardsSetupPeriodSelectEventHandlers() {
  periodableSetupEventHandlers(customerDashboardsFetchOtherParamsForPerioding);
}


// helpers

function customerDashboardsFetchAllTeamsParams() {
  var allTeamsChecked = $('#allTeamsCheck').prop('checked');

  var allTeams = null;
  if (allTeamsChecked) {
    allTeams = "true";
  }

  return {
    all_teams: allTeams
  }
}

function customerDashboardsFetchLocationParams() {
  var customerId = $('#customerNameField').data(
                     'selected'
                   );
  var locationId = $('#locationSelect').data(
                     'selected'
                   );
  
  return {
    id:          customerId,
    location_id: locationId 
  }
}

function customerDashboardsFetchOtherParamsForPerioding() {
  var locationParams = customerDashboardsFetchLocationParams();
  var allTeamsParams = customerDashboardsFetchAllTeamsParams();
  var filterParams   = jobListableFetchFilterParams();
  var sortParams     = jobListableFetchSortParams();

  return Object.assign(locationParams, allTeamsParams, filterParams, sortParams);
}
