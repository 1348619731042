/*
 * PMApp::rootable
 * JS that is part of the Rootable component
 */

import { squirrelableGetData } from './squirrelable.js.erb';


// public functions -- ---- -- -- --

export function update(eventJSON, actionName, updatePath) {

  // unpack any data that was directly supplied by the rootable controller
  var params = JSON.parse(eventJSON);

  // add any data that was squirreled away by the root controller
  var squirreledParams = squirrelableGetData(actionName);
  if (squirreledParams) {
    params = Object.assign(params, squirreledParams);
  }

  // invoke the root controllers action with the combined data
  $.ajax({
    data:  params,
    type:  'POST',
    url:   updatePath,
    async: true
  });
}
