/*
 * PMApp::customers
 * JS that is specific to the Customers controller
 */

import { showLoadingOverlay               } from "./loading_overlay";
import { darkBkgMetricLabelClass,
         lightBkgMetricLabelClass,
         metricableSelectMetricLabelClass } from './metricable.js.erb';
import { pmappPreventDefaults             } from './pmapp.js.erb';
import { teamFocusAddFieldsToParams,
         teamFocusSetupEventHandlers      } from './team_focus.js.erb';


$(document).on('turbolinks:load', function() {
  if ($('body.customer-index').length > 0) {
    teamFocusSetupEventHandlers();

    // for the bar chart icons on the index page
    customersSetupAllOpenEffortLinkEventHandler();
    customersSetupOpenEffortLinkEventHandlers();

    customersSetupStartFilterEventHandler();

    customersSetupPillEventHandlers();
    customersSetupQuickLinksEventHandlers();
    customersSetupFilterLinkEventHandlers();

    customersSetupLocExpanderEventHandlers();

    $(window).resize(customersMaybeFixHeight);
  }

  // new/edit form
  if ($('body.customers').length > 0) {
    $('form.customer-form').submit(function() {
      showLoadingOverlay();
    });

    if ($('#possibleDuplicatesRow').length > 0) {
      $([document.documentElement, document.body]).animate({
        scrollTop: $('#possibleDuplicatesRow').offset().top
      }, 500);
    }
  }

});


// -- ---- -- -- --
// METRICS
// -- ---- -- -- --

// event handlers -- ---- -- -- --

/*
 * customersHandleCustomerCardLinkClick
 * invoked when the user clicks a customer abbreviation link in the pie table; we allow the link to do its usual
 * thing, which should handle the "scroll to position" bit, but we need to make sure we slide the customer cards
 * back in view here
 */
function customersHandleCustomerCardLinkClick() {
  // get a handle to the "unpie" link
  var unpieLink = $('#allOpenEffortMetricsLink')[0];

  // pretend like the user clicked the unpie link
  customersHandleUnpieLinkClick(unpieLink);
}

/*
 * customersHandleCsvLinkClick
 * invoked when the user clicks the CSV link
 */
function customersHandleCsvLinkClick() {
  var params = customersFetchFilterSettings(false, false);

  $("#csvWorkTypesHiddenField").attr("value", params.work_types);
  $("#csvTeamsHiddenField").attr("value", params.teams);

  $("#csvWorkTypesHiddenField").closest("form").submit();
}

/*
 * customersHandleFilterClick
 * this handler should be invoked if either the "start filtering" link or the filter controls' filter link is clicked;
 * this handler will first gather the current filter settings and the set of customers/locations that are currently
 * listed; next the handler will open the filters modal such that all of the gathered information can be passed to
 * the server when the modal is dismissed
 * -- ---- -- -- --
 * filterLink: a handle to the link that was clicked to open the modal
 */
function customersHandleFilterClick(filterLink) {
  var params = null;

  if ($('#criteriaContainer').length > 0) {
    var starting = false;
    params = customersFetchFilterSettings(false, false);
    params.starting = false;
  }
  else {
    params = { starting: true }
  }

  customersFilter(filterLink, params);
}

/*
 * customersHandleOpenEffortsLinkClick
 * invoked when the user clicks an open-efforts-metrics link for a specific customer
 * -- ---- -- -- --
 * openEffortLink: handle to the clicked link
 */
function customersHandleOpenEffortsLinkClick(openEffortLink) {
  // first remove the click handler
  $(openEffortLink).off('click');

  var params = customersFetchFilterSettings(false, false);
  params = teamFocusAddFieldsToParams(params);

  customersHandleLink(params, openEffortLink);
}

/*
 * customersHandlePieLinkClick (100% JS)
 * when the user clicks the pie chart icon in the customers index heading, this handler will hide the default
 * container (open efforts bar charts) and show the pies container
 * -- ---- -- -- --
 * pieLink: js element; the pie chart link in the header
 */
function customersHandlePieLinkClick(pieLink) {
  // hide the pie chart link
  $(pieLink).hide();

  // first make sure the pies are positioned, vertically aligned with the top of the default listing
  // this happens off-screen so nobody is the wiser
  var defaultHeight = $('#indexDefaultContentWrapper').height();
  var newPiesTop = '-' + defaultHeight.toString() + 'px';
  $('#indexPiesContentWrapper').css('top', newPiesTop);

  // slide the default container off-screen to the left
  $('#indexDefaultContentWrapper').animate({ left: "-100vw" }, 500);

  // slide the pies container on to the screen from the right
  // once the pies container is in position, adjust the hieght of the default container to allow the footer
  // to come up to where you would expect it to be when the pies are showing
  $('#indexPiesContentWrapper').animate( { left: "0vw" }, 500, customersFixHeight);

  // show the bar chart link and set up the click event handler so the user can click the bar chart icon
  // to go back to the the default container
  $('#allOpenEffortMetricsLink').show();
  customersSetupUnpieLinkEventHandler();
}

function customersMaybeFixHeight() {
  var piesLeft = $('#indexPiesContentWrapper').css('left');
  if (piesLeft == "0px") {
    // pies are showing
    customersFixHeight();
  }
}

function customersFixHeight() {
  // TODO height-mgt should be a constant
  var piesHeight = $('#indexPiesContentWrapper > .height-mgt').height();
  var newDefaultHeight = piesHeight.toString() + 'px';
  var finalPiesTop = '-' + newDefaultHeight;
  $('#indexDefaultContentWrapper').height(newDefaultHeight);
  $('#indexPiesContentWrapper').css('top', finalPiesTop);
}

/*
 * customersHandlePieColorSwatchClick
 * starts the process of opening the color swatch dialog
 * -- ---- -- -- --
 * swatchIcon: handle to the icon on which the color swatch link resides
 * swtachLink: handle to the color swatch link
 */
function customersHandlePieColorSwatchClick(swatchIcon, swatchLink) {
  // get the current color, use it to initialize the color picker, also keep it around to reset the
  // color if the user does not save their changes
  var startColor = $(swatchLink).css('color');

  // the customer ID is use to find the bits that should be updated as the color changes
  var customerId = $(swatchLink).data('customer');
  $('#pieColorSwatchDialog input[name=id]').attr('value', customerId);

  // initialize the color picker, set up an event handler to update the color in the swatch and
  // in the pie as the user moves the selections around
  $('#pieColorSwatchDialog input.minicolor').minicolors(
    { inline: true,
      opacity: false,
      change: function(webColor) {
        customersColorSlice(customerId, webColor);
      }
    }
  );

  $('#pieColorSwatchDialog input.minicolor').minicolors('value', startColor);

  // position the color picker along side the swatch
  var rowsContainerPosition = $('#pieTableContainer').offset();
  var linkPosition = $(swatchLink).offset();
  var linkWidth = $(swatchLink).width();
  var left = (linkPosition.left - rowsContainerPosition.left) + linkWidth + 5;
  var top = linkPosition.top - rowsContainerPosition.top;
  $('#pieColorSwatchDialog').css('left', left);
  $('#pieColorSwatchDialog').css('top', top);

  // show the color picker
  $('#pieColorSwatchDialog').show();

  // set up event handler to dismiss the color picker
  $('html').click(function(e) {
    e = e || window.event
    var clickedElement = e.target || e.srcElement;
    if ($(clickedElement).attr('id') != 'pieColorSwatchDialog') {
      if ($(clickedElement).closest('#pieColorSwatchDialog').length == 0) {
        customersPieColorSwatchReset(customerId, startColor);
      }
    }
  });

  $('#pieColorSwatchResetLink').click(function(e) {
    pmappPreventDefaults(e);
    customersPieColorSwatchReset(customerId, startColor);
  });
}

/*
 * customersHandlePieMonthChange
 * the pie charts page displays one month at a time; this method makes the changes necessary when the user
 * chooses to change the month
 * -- ---- -- -- --
 * newMonthNumber: the month number (1-12) of the month chosen by the user
 */
function customersHandlePieMonthChange(newMonthNumber) {
  $('[id^=pieTableCard]').removeClass('active');
  $('#pieTableCard' + newMonthNumber.toString()).addClass('active');
}

/*
 * customersHandlePieTableSort
 * recover the pie table data from the page (stashed on the page as a JSON string) and send it to the server;
 * the server will sort the data as necessary and cause the browser to redraw the pie table
 * -- ---- -- -- --
 * headingLink: this will tell use how the data is to be sorted
 */
function customersHandlePieTableSort(headingLink) {
  var sortColumn = $(headingLink).data('sort');
  var tableData  = $('#pieTableContainer').data(
      'content'
  );

  var activeCardId = $('[id^=pieTableCard].active').attr('id');
  var showingMonth = activeCardId.replace('pieTableCard', '');

  var params = { sort:  sortColumn,
                 month: showingMonth,
                 data:  JSON.stringify(tableData) }

  customersHandleLink(params, $(headingLink));
}

/*
 * customersHandlePillClick
 * invoked when the user clicks an "X" in a filter controls pill
 * -- ---- -- -- --
 * pillLink: a handle to the "X" link that was clicked
 */
function customersHandlePillClick(pillLink) {
  // need to know which kind of pill was clicked, that will tell us which "changed" flag should be true
  var workTypesChanged = false;
  var teamsChanged = false;
  var container = $(pillLink).closest('.criteria-pill-container');
  if ($(container).data('worktypes')) {
    workTypesChanged = true;
  }
  else {
    teamsChanged = true;
  }

  var params = customersFetchFilterSettings(workTypesChanged, teamsChanged);

  customersFilter(pillLink, params);
}

/*
 * customersHandleQuickLinkClick
 * this is the eent handler for the "all" (globe) and "team" (undo) icon links in the criteria controls
 * -- ---- -- -- --
 * quickLink: handle to the clicked link
 */
function customersHandleQuickLinkClick(quickLink) {
  showLoadingOverlay();

  var workTypeParams = quickLink.data('worktypes');
  var teamParams = quickLink.data('teams');

  var workTypesChanged = true;
  if (workTypeParams == 'read') {
    workTypesChanged = false;
    workTypeParams = customersFetchFilterData('work-type-criteria-container',
                                              'worktypes');
  }

  var teamsChanged = true;
  if (teamParams == 'read') {
    teamsChanged = false;
    teamParams = customersFetchFilterData('team-criteria-container',
                                          'teams');
  }

  var params = customersBuildFilterSettings(workTypeParams, workTypesChanged, teamParams, teamsChanged);

  customersFilter(quickLink, params);
}

/*
 * customersHandleUnpieLinkClick (100% JS)
 * WHILE THE PIES CONTAINER IS SHOWING, when the user clicks the bar chart icon in the customers index heading,
 * this handler will hide the pies container and show the default (open efforts bar charts) container
 * -- ---- -- -- --
 * unpieLink: js element; the bar chart icon link in the header
 */
function customersHandleUnpieLinkClick(unpieLink) {
  // hide the pie chart link
  $(unpieLink).hide();
  $(unpieLink).off('click'); // TODO - make a function to remove whatever click handler is there

  // first allow the default container back to its natural height and re-align the pies container accordingly
  $('#indexDefaultContentWrapper').css('height', '');
  var defaultHeight = $('#indexDefaultContentWrapper').height();
  var newPiesTop = '-' + defaultHeight.toString() + 'px';
  $('#indexPiesContentWrapper').css('top', newPiesTop);

  // slide the pies container off-screen to the right
  $('#indexPiesContentWrapper').animate( { left: "100vw" }, 500);

  // slide the default container on to the screen from the left
  $('#indexDefaultContentWrapper').animate({ left: "0vw" }, 500);

  // show the pie chart link (the user can click this to go back to the bar charts container)
  $('#showPiesMetricsLink').show();
}


// public functions

/*
 * customersColorPieSliceLabels
 * adds supplementary classes to pie slice labels to optimize contrast between the labels and the slice
 * background colors
 */
export function customersColorPieSliceLabels() {
  $('[class^=slice-o-pie-]').each(function(index) {
    var webColor = $(this).attr('fill');
    $(this).next('text.slice-label').addClass(
      metricableSelectMetricLabelClass(webColor)
    );
  });
}

/*
 * customersSetupAllOpenEffortLinkEventHandler
 * adds the link handler to the bar chart icon-link next to the title on the customers index page
 */
export function customersSetupAllOpenEffortLinkEventHandler() {
  $('#allOpenEffortMetricsLink').click(function(e) {
    e = e || window.event
    pmappPreventDefaults(e);

    showLoadingOverlay();

    var triggeringIcon = e.target || e.srcElement;
    var triggeringLink = $(triggeringIcon).closest('a');
    customersHandleOpenEffortsLinkClick(triggeringLink);
  });
}

/*
 * customersSetupCsvLinkEventHandler
 * adds the link handler to the CSV icon-link next to the title on the customers index page
 */
export function customersSetupCsvLinkEventHandler() {
  $('#exportMetricsToCSVLink').click(function(e) {
    e = e || window.event
    pmappPreventDefaults(e);
    customersHandleCsvLinkClick();
  });
}

/*
 * customersSetupCustomerCardLinkEventHandlers
 * sets up event handlers for the customer abbreviation links in the pie table; clicking one of these links
 * should send the user back to the customer cards list and scroll so that the clicked customer is in view
 */
export function customersSetupCustomerCardLinkEventHandlers() {
  $('.pie-table-customer-abbr-link').click(function(e) {
    customersHandleCustomerCardLinkClick();
  });
}

/*
 * customersSetupFilterLinkEventHandlers
 * sets up event handlers for the filter icon link that appears alongside to the filter criteria container
 * on the customers index page
 */
export function customersSetupFilterLinkEventHandlers() {
  $('#filterButton').click(function(e) {
    var iconLink = customersGetIconLinkFromEvent(e);
    customersHandleFilterClick(iconLink.link);
  });
}

/*
 * customersSetupFiltersModalEventHandlers
 * sets up event handlers for select all and reset links on the index page's filters modal
 */
export function customersSetupFiltersModalEventHandlers() {
  var modalSel   = '#filtersModalForm';
  var awtLinkSel = 'a.select-all-work-types-link';
  var wtOptsSel  = '#workTypeOptions';
  $(modalSel + ' ' + awtLinkSel).click(function(e) {
    e = e || window.event
    pmappPreventDefaults(e);

    var selector = modalSel + ' ' + wtOptsSel + ' input[type=checkbox]';
    $(selector).prop('checked', true);
  });

  var rwtLinkSel = 'a.reset-work-types-link';
  $(modalSel + ' ' + rwtLinkSel).click(function(e) {
    e = e || window.event
    pmappPreventDefaults(e);

    var selector = modalSel + ' ' + wtOptsSel + ' input[type=checkbox]';
    $(selector).prop('checked', false);
    $(selector + '.focus').prop('checked', true);
  });

  var atLinkSel = 'a.select-all-teams-link';
  var tOptsSel  = '#teamOptions';
  $(modalSel + ' ' + atLinkSel).click(function(e) {
    e = e || window.event
    pmappPreventDefaults(e);

    var selector = modalSel + ' ' + tOptsSel + ' input[type=checkbox]';
    $(selector).prop('checked', true);
  });

  var rtLinkSel = 'a.reset-teams-link';
  $(modalSel + ' ' + rtLinkSel).click(function(e) {
    e = e || window.event
    pmappPreventDefaults(e);

    var selector = modalSel + ' ' + tOptsSel + ' input[type=checkbox]';
    $(selector).prop('checked', false);
    $(selector + '.focus').prop('checked', true);
  });
}

/*
 * customersSetupLocExpanderEventHandlers
 * establishes handlers on bootstrap collapse events for hiding and showing the customer-level metrics
 * when locations are showing or hidden
 */
export function customersSetupLocExpanderEventHandlers() {
  // hide the customer level analytics when showing locations
  $('[id^=collapseLocs]').on('show.bs.collapse', function(e) {
    e = e || window.event
    var triggeringCollapsible = e.target || e.srcElement;
    $('#' + customersGetCustOpenEffortsId(triggeringCollapsible)).hide();
  });

  // show the customer level analytics when not showing locations
  $('[id^=collapseLocs]').on('hide.bs.collapse', function(e) {
    e = e || window.event
    var triggeringCollapsible = e.target || e.srcElement;
    $('#' + customersGetCustOpenEffortsId(triggeringCollapsible)).show();
  });
}

/*
 * customersSetupOpenEffortLinkEventHandlers
 * adds a link handler to the bar chart icon-link on each customer card on the customers index page
 */
export function customersSetupOpenEffortLinkEventHandlers() {
  $('.open-effort-customer-link').click(function(e) {
    var iconLink = customersGetIconLinkFromEvent(e);

    // replace bar chart icon with spinner while waiting for results
    customersToggleOpenEffortLinkIcon(iconLink.icon);

    // request results
    customersHandleOpenEffortsLinkClick(iconLink.link);
  });
}

/*
 * customersSetupPieColorSwatchEventHandlers
 * attaches a handlers to the pie table color swatches
 */
export function customersSetupPieColorSwatchEventHandlers() {
  $('.pie-color-swatch-link').click(function(e) {
    var iconLink = customersGetIconLinkFromEvent(e);
    customersHandlePieColorSwatchClick(iconLink.icon, iconLink.link);
  });
}

/*
 * customersSetupPieLinkEventHandler
 * attaches a handler to the pie chart icon link in the customers index header action area; the pie chart icon
 * link only appears after all open efforts metrics have been loaded
 */
export function customersSetupPieLinkEventHandler() {
  $('#showPiesMetricsLink').click(function(e) {
    var iconLink = customersGetIconLinkFromEvent(e);
    customersHandlePieLinkClick(iconLink.link);
  });
}

/*
 * customersSetupPieMonthLinkEventHandler
 * set up event handlers that allow the user to switch the pie chart month; the pie charts themselves are
 * managed with a bootstrap carousel
 */
export function customersSetupPieMonthLinkEventHandler() {
  $('#pieCarousel').carousel('pause');

  $('#pieCarousel').on('slide.bs.carousel', function(e) {
    var newMonthNumber = $(e.relatedTarget).data('month');
    customersHandlePieMonthChange(newMonthNumber);
  });
}

/*
 * customersSetupPieTableSortEventHandlers
 * attaches a handler to the pie table headings; when a pie table heading is clicked, the table should be
 * sorted by the clicked heading's column
 */
export function customersSetupPieTableSortEventHandlers() {
  $('[id^=pieTableCard] .headings a.heading').click(function(e) {
    e = e || window.event
    pmappPreventDefaults(e);

    var triggeringLink = e.target || e.srcElement;
    customersHandlePieTableSort(triggeringLink);
  });
}

/*
 * customersSetupPillEventHandlers
 * attaches a handler to all pill links
 */
export function customersSetupPillEventHandlers() {
  $('.criteria-pill a').click(function(e) {
    var iconLink = customersGetIconLinkFromEvent(e);
    customersHandlePillClick(iconLink.link);
  });
}

/*
 * customersSetupQuickLinksEventHandlers
 * attaches a handler to the all (globe icon) and team (undo icon) links inside the filter criteria controls
 * on the customers index page
 */
export function customersSetupQuickLinksEventHandlers() {
  $('.criteria-quick-links a').click(function(e) {
    var iconLink = customersGetIconLinkFromEvent(e);
    customersHandleQuickLinkClick(iconLink.link);
  });
}

/*
 * customersSetupStartFilterEventHandler
 * by default, the customers index does not show the filter controls; instead it only presents a filter icon
 * link in the page header actions area; this method sets up the event handler for that filter icon link
 */ 
export function customersSetupStartFilterEventHandler() {
  $('#startFilterButton').click(function(e) {
    var iconLink = customersGetIconLinkFromEvent(e);
    customersHandleFilterClick(iconLink.link);
  });
}

/*
 * customersSetupUnpieLinkEventHandler
 * when the pie charts are being displayed, the pie chart icon link in the header actions area is replaced
 * with the bar chart icon link; this method attaches the appropriate handle to that link
 */
export function customersSetupUnpieLinkEventHandler() {
  $('#allOpenEffortMetricsLink').click(function(e) {
    var iconLink = customersGetIconLinkFromEvent(e);
    customersHandleUnpieLinkClick(iconLink.link);
  });
}

// private

/*
 * customersBuildFilterSettings
 * this method exists just to keep params consistent
 */ 
function customersBuildFilterSettings(workTypesData, workTypesChanged, teamsData, teamsChanged) {
  return { work_types:         workTypesData,
           work_types_changed: workTypesChanged,
           teams:              teamsData,
           teams_changed:      teamsChanged };
}

/*
 * customersCollectOpenEffortsMetricsData
 * builds a comma separated list of IDs (either customer or location); if an ID appears in the list, it is
 * because metrics are being displayed for the customer/location
 * -- ---- -- -- --
 * level: CustomersController::OPEN_EFFORT_CUST_METRICS_CLASS or CustomersController::OPEN_EFFORT_LOC_METRICS_CLASS
 */
function customersCollectOpenEffortsMetricsData(level) {
  var params = 'all';

  var openAllLink = $('#allOpenEffortMetricsLink');

  if (openAllLink.length == 0 ||
      (openAllLink.length > 0 && openAllLink.data('showall') == 'no')) {
    var ids = [];
    $('.open-effort-metrics.' + level + ' > .row').each(function(index, element) {
      ids.push($(element).data(level + 'id'));
    });
    params = ids.join(',');
  }

  return params
}

/*
 * customersColorSlice
 * used to recolor a pie slice when the user is selecting a new color
 * -- ---- -- -- --
 * customerId: the ID of the customer to which the swatch belongs; the ID is used to tie the swatch and the
 *             customer's pie slices together
 * bkgColor:   the color to use for the slice's background color
 */
function customersColorSlice(customerId, bkgColor) {
  $('.slice-o-pie-' + customerId.toString()).attr('fill', bkgColor);
  $('.slice-o-pie-' + customerId.toString())
    .next('text.slice-label')
    .removeClass(darkBkgMetricLabelClass + ' ' + lightBkgMetricLabelClass);
  $('.slice-o-pie-' + customerId.toString())
    .next('text.slice-label')
    .addClass(metricableSelectMetricLabelClass(bkgColor));

  var swatchLinkSelector = 'a[data-customer=' + customerId.toString() +
                           '].pie-color-swatch-link';
  $(swatchLinkSelector).css('color', bkgColor);
}

/*
 * customersCompColorToRgb
 * converts "rgb(RRR, GGG, BBB)" to { red: <red>, green: <green>, blue: <blue> }
 * TODO - this should really be in a more general JS file
 * -- ---- -- -- --
 * compColor: the string to convert
 */
function customersCompColorToRgb(compColor) {
  var result = compColor.substring(4, compColor.length - 1).replace(/ /g, '').split(',');
  return result ? {
    red:   parseInt(result[0]),
    green: parseInt(result[1]),
    blue:  parseInt(result[2])
  } : null;
}

/*
 * customersDismissPickColorDialog
 * resets the pick color dialog so that it can be restarted the next time a color swatch is clicked
 */
function customersDismissPickColorDialog() {
  $('#pieColorSwatchDialog').hide();

  $('#pieColorSwatchDialog input.minicolor').minicolors('destroy');

  // remove the color reset handlers
  $('#pieColorSwatchResetLink').off('click');
  $('html').off('click');
}

/*
 * customersFetchFilterData
 * reads the specified data field from the specified criteria container
 * -- ---- -- -- --
 * criteriaContClass: specifies the criteria contianer
 * dataKey:           specifies the data field
 */
function customersFetchFilterData(criteriaContClass, dataKey) {
  var returnValue = null;

  var criteriaContainerID = 'criteriaContainer';
  if ($('#' + criteriaContainerID).length > 0) {
    var specCriteriaContainerSelector = '#' + criteriaContainerID + ' .' + criteriaContClass;
    var specPillContainer =
          $(specCriteriaContainerSelector + ' .criteria-pill-container');

    returnValue = specPillContainer.data(dataKey);
  }

  return returnValue;
}

/*
 * customersFetchFilterSettings
 * builds the work_types/teams part of params needed by filter actions
 * -- ---- -- -- --
 * workTypesChanged: allows the calling program to set the "work types changed" flag in the filter settings params
 * teamsChanged:     allows the calling program to set the "teams changed" flag in the filter settings params
 */
function customersFetchFilterSettings(workTypesChanged, teamsChanged) {
  var workTypesData = customersFetchFilterData('work-type-criteria-container',
                                               'worktypes');
  var teamsData = customersFetchFilterData('team-criteria-container',
                                           'teams');

  var settings = customersBuildFilterSettings(workTypesData, workTypesChanged, teamsData, teamsChanged);

  return settings;
}

/*
 * customersFilter
 * really the same has customersHandleLink, but the post to the server will include lists of IDs indicating
 * which customers/locations are currently displaying metrics
 * -- ---- -- -- --
 * filterPathLink: the link that provides the server path to which to post
 * params:         the params to send to the server, not including the customer/location data metioned in the
 *                 method description
 */
function customersFilter(filterPathLink, params) {
  params.customers =
    customersCollectOpenEffortsMetricsData('customer');
  params.locations =
    customersCollectOpenEffortsMetricsData('location');

  params = teamFocusAddFieldsToParams(params);

  customersHandleLink(params, filterPathLink);
}

/*
 * customersGetCustOpenEffortsId
 * when a user opens or closes a locations collapsible on the customers index page, we may need to show/hide
 * open-efforts-metrics containers; this method is used to find the IDs of those containers
 * -- ---- -- -- --
 * triggeringCollapsible: the collapsible that was triggered by the user
 */
function customersGetCustOpenEffortsId(triggeringCollapsible) {
  var customerId = $(triggeringCollapsible).attr('id').
                    replace('collapseLocs', '');
  var openEffotsId = 'openEffortCustomerContainer' + customerId
  return openEffotsId;
}

/*
 * customersGetIconLinkFromEvent
 * this method implements the common part of icon link event handlers
 * -- ---- -- -- --
 * e: the event being handled
 */
function customersGetIconLinkFromEvent(e) {
  e = e || window.event
  pmappPreventDefaults(e);

  var triggeringIcon = e.target || e.srcElement;
  var triggeringLink = $(triggeringIcon).closest('a');

  return { icon: triggeringIcon, link: triggeringLink }
}

/*
 * customersHandleLink
 * reads the href (path) from a link and then posts to that link with the given params
 * -- ---- -- -- --
 * params:         the params to send to the server
 * triggeringLink: the link that provides the server path to which to post
 */
function customersHandleLink(params, triggeringLink) {
  var targetPath = triggeringLink.attr('href');

  $.ajax({
    data:  params,
    type:  'POST',
    url:   targetPath,
    async: true
  });
}

/*
 * customersPieColorSwatchReset
 * resets the pie color swatch to its original color; also rests the corresponding pie slices and dismisses the
 * pick color dialog
 * -- ---- -- -- --
 * customerId: the ID of the customer to which the swatch belongs; the ID is used to tie the swatch and the
 *             customer's pie slices together
 * resetColor: the original color
 */
function customersPieColorSwatchReset(customerId, resetColor) {
  customersDismissPickColorDialog();
  customersColorSlice(customerId, resetColor);
}

/*
 * customersToggleOpenEffortLinkIcon
 * when a customer-specific open-efforts-metrics link is clicked, it should first change to a spinner, and then
 * once the metrics are show, the link should go away altogether; the ELSE portion of this method is not currently
 * used
 * -- ---- -- -- --
 * openEffortsIcon: handle to the icon in question
 */
function customersToggleOpenEffortLinkIcon(openEffortsIcon) {
  if ($(openEffortsIcon).hasClass('fa-chart-bar')) {
    $(openEffortsIcon).removeClass('fa-chart-bar');
    $(openEffortsIcon).addClass('fa-spinner fa-spin');
  }
  else {
    $(openEffortsIcon).removeClass('fa-spinner fa-spin');
    $(openEffortsIcon).addClass('fa-chart-bar');
  }
}
