/*
 * PMApp::filterable
 * JS for the filterable component
 */

import { showLoadingOverlay   } from "./loading_overlay";
import { pmappPreventDefaults } from './pmapp.js.erb';
import { squirrelableGetData  } from './squirrelable.js.erb';


// -- ---- -- -- --
// initialization
// -- ---- -- -- --

/*
 * filterableInitializeItemListAsFilterable
 * sets up the event handler for item list filter controls
 * -- ---- -- -- --
 * sectionName:           the section name of the item list for which we are initializing filering capability
 * generateOutsideParams: if given, this should be a function that returns an object that can be used as
 *                        params to send to the server; the object should contain any parameters that need to
 *                        be sent to the server if the server is to handle filtering; if not given, client
 *                        side filtering will be assumed
 */
export function filterableInitializeItemListAsFilterable(sectionName, generateOutsideParams) {
  var controlSelector = filterableBuildFilterControlSelector(sectionName);
  var totalSlug = $(controlSelector).data('filterable-total');

  $(controlSelector).find('a.filterable-common-button').each(function(index, button) {
    if (generateOutsideParams && typeof(generateOutsideParams) === typeof(Function)) {
      if ($(button).data('slug') == totalSlug) {
        $(button).click(function(event) {
          showLoadingOverlay();
          filterableHandleTotalTapOnServer(event, generateOutsideParams);
        });
      }
      else {
        $(button).click(function(event) {
          showLoadingOverlay();
          filterableHandleFilterTapOnServer(event, generateOutsideParams);
        });
      }
    }
    else {
      if ($(button).data('slug') == totalSlug) {
        $(button).click(function(event) {
          filterableHandleTotalTap(event);
        });
      }
      else {
        $(button).click(function(event) {
          filterableHandleFilterTap(event);
        });
      }
    }
  });

  // just in case there were presets
  filterableApplyFilters(sectionName);

  // "tag" the last visible button for styling purposes
  filterableTagLastVisibleFilterButton(controlSelector);
}



// event handlers

/*
 * filterableHandleFilterTap
 * handles a tap on any filter button other than the "total" button; this handler provides client-side filtering
 * -- ---- -- -- --
 * event: teh subject event
 */
function filterableHandleFilterTap(event) {
  var tappedLink = filterablePreHandleButtonTap(event);
  var filterControlContainer = $(tappedLink).closest('.filterable-container');
  var sectionName = $(filterControlContainer).data('filterable-section');

  filterableUpdateControlsForFilterTap(tappedLink);

  var slugs = filterableApplyFilters(sectionName);

  var numFilters = $(filterControlContainer).find('a.filterable-common-button').length;
  if (slugs.length == numFilters) {
    slugs = null;
  }

  filterableReportFilterToServer(filterControlContainer, sectionName, slugs);
}

/*
 * filterableHandleFilterTapOnServer
 * if, when initialized, Filterable is provided a function for generating data to use when filtering, then
 * Filterable will send any filtering-change request to the server instead of handling the filtering here
 * (client-side); this handler addresses that case for a tap on any button other than the "all items" button
 * -- ---- -- -- --
 * event:                 the subject event
 * generateOutsideParams: the function for generating data to use when filtering
 */
function filterableHandleFilterTapOnServer(event, generateOutsideParams) {
  var tappedLink = filterablePreHandleButtonTap(event);
  filterableUpdateControlsForFilterTap(tappedLink);
  filterableRequestServerFiltering(tappedLink, generateOutsideParams);
}

/*
 * filterableHandleTotalTap
 * handler for a tap on the "all items" filter button; this handler provides client-side filtering
 * -- ---- -- -- --
 * event: the subject event
 */
function filterableHandleTotalTap(event) {
  var tappedLink = filterablePreHandleButtonTap(event);
  var filterControlContainer = $(tappedLink).closest('.filterable-container');
  var sectionName = $(filterControlContainer).data('filterable-section');
  filterableClear(sectionName);
}

/*
 * filterableHandleTotalTapOnServer
 * if, when initialized, Filterable is provided a function for generating data to use when filtering, then
 * Filterable will send any filtering-change request to the server instead of handling the filtering here
 * (client-side); this handler addresses that case for a tap on the "all items" button
 * -- ---- -- -- --
 * event:                 the subject event
 * generateOutsideParams: the function for generating data to use when filtering
 */
function filterableHandleTotalTapOnServer(event, generateOutsideParams) {
  var tappedLink = filterablePreHandleButtonTap(event);
  var filterControlContainer = $(tappedLink).closest('.filterable-container');
  filterableEngageAllButtons(filterControlContainer);
  filterableRequestServerFiltering(tappedLink, generateOutsideParams);
}


// public functions

/*
 * filterableApplyFilters
 * call this method to apply the filters selected in the filter control to the respective item list
 * -- ---- -- -- --
 * sectionName: identifies the item list to which the subject filter control pertains
 */
export function filterableApplyFilters(sectionName) {
  var filterControlContainerSelector = filterableBuildFilterControlSelector(sectionName);

  // itemListClass is the class that should be used, in conjunction with sectionName, to select the subject list
  var itemListClass = $(filterControlContainerSelector).data('filterable-list');

  // itemClass is the class that should be used to select items in the list
  var itemClass = $(filterControlContainerSelector).data('filterable-item');

  // itemListSectionKey is the data key where we can find the section data key used in the subject item list
  var itemListSectionKey =
    $(filterControlContainerSelector).data('filterable-section-key');

  // get a handle to the list that corresponds to the tapped filter controls
  // TODO - this is working by coincedence, the section data key must be provided
  var itemListSelector = '.' + itemListClass + '[data-' + itemListSectionKey + '=' + sectionName + ']';
  var itemList = $(itemListSelector)[0];

  // hide all task list items
  $(itemList).find('.' + itemClass).hide();

  // build a list of all engaged buttons and use that to hide/show list items
  var slugs = filterableGetEngagedFiltersCore($(filterControlContainerSelector)[0]);
  var slugDivSelector;
  slugs.forEach(function(slug, index) {
    slugDivSelector = '.filterable-slugs' +
                      '[data-slugs*="' + slug + '"]';
    $(itemList).find(slugDivSelector).each(function(index, itemElement) {
      $(itemElement).closest('.' + itemClass).show();
    });
  });

  return slugs;
}

/*
 * filterableClear
 * call this method to engage all filter buttons and show all list items (client-side filtering), effectively
 * clearing any filtering
 * -- ---- -- -- --
 * sectionName:           identifies the item list to which the subject filter control pertains
 * generateOutsideParams: if given, this should be a function that returns an object that can be used as
 *                        params to send to the server; the object should contain any parameters that need to
 *                        be sent to the server if the server is to handle filtering; if not given, client
 *                        side filtering will be assumed
 */
export function filterableClear(sectionName, generateOutsideParams) {
  var filterControlContainerSelector = filterableBuildFilterControlSelector(sectionName);

  if ($(filterControlContainerSelector).length > 0) {
    // now get the references we need...
    // class name for the subject item list
    var itemListClass = $(filterControlContainerSelector).data('filterable-list');
    // data key where we can find the section data key used in the subject item list
    var itemListSectionKey =
      $(filterControlContainerSelector).data('filterable-section-key');
    // class name given to all item list items
    var itemClass = $(filterControlContainerSelector).data('filterable-item');

    var filterControlContainer = $(filterControlContainerSelector)[0];
    var engagedSlugsBefore = filterableGetEngagedFiltersCore(filterControlContainer);
    filterableEngageAllButtons(filterControlContainer);
    var engagedSlugsAfter = filterableGetEngagedFiltersCore(filterControlContainer);

    // if engaging all buttons did not change anything, we are done
    if (engagedSlugsBefore.length != engagedSlugsAfter.length) {
      // show all items in the subject item list
      var itemListSelector = '.' + itemListClass + '[data-' + itemListSectionKey + '=' + sectionName + ']';
      var itemList = $(itemListSelector)[0];
      $(itemList).find('.' + itemClass).show();

      // tell the server we just cleared the filter state
      var slugs = null;
      var outside = null;
      if (generateOutsideParams) outside = generateOutsideParams(sectionName);
      filterableReportFilterToServer(filterControlContainerSelector, sectionName, slugs, outside);
    }
  }
}

export function filterableCountVisibleFilterButtons(sectionName) {
  var controlSelector = filterableBuildFilterControlSelector(sectionName);
  return $(controlSelector).find('a.filterable-visible-button').length
}

export function filterableGetEngagedFilters(hostContainer) {
  var filterControlContainer = $(hostContainer).find('.filterable-container');
  return filterableGetEngagedFiltersCore(filterControlContainer);
}

/*
 * filterableGetItemSlugs
 * gets the filter slugs associated with a specific list item
 * -- ---- -- -- --
 * itemSelector: a CSS selector that identifies a specific list item
 */
export function filterableGetItemSlugs(itemSelector) {
  var rVal = { };

  var slugElementSelector = itemSelector + ' .filterable-slugs'
  var slugString = $(slugElementSelector).data('slugs');

  if (slugString && slugString.length > 0) {
    var slugs = slugString.split(" ");
    slugs.forEach(function(slug) {
      rVal[slug] = 1;
    });
  }

  return rVal;
}

/*
 * filterableUpdateFiltersControl
 * given a Filterable controls set, this method will hide/show buttons based on their counts and will apply
 * classes to ensure the control is rendered propoerly
 * -- ---- -- -- --
 * sectionName: identifies the item list for which to update the filters control
 * itemSlugs:   a list of filter slugs for which the counts may have changed, mapped to the change (-1 means 
 *              the count has decreased by 1, 0 means the count has not changed, 1 means the count has increased
 *              by one...you get the idea)
 */
export function filterableUpdateFiltersControl(sectionName, itemSlugs) {
  var controlSelector = filterableBuildFilterControlSelector(sectionName);

  // update the total button, it should always be showing
  var totalSlug = $(controlSelector).data('filterable-total');
  var totalButtonSelector = controlSelector + ' a.filterable-common-button.' + totalSlug;

  var countSelector = totalButtonSelector + ' .filterable-actual-count';
  var oldCount = parseInt($(countSelector).text());
  var totalNewCount = oldCount + itemSlugs[totalSlug];
  $(countSelector).text(totalNewCount.toString());

  $(controlSelector).find('a.filterable-common-button').each(function(index, button) {
    // get the slug for the button
    var slug = $(button).data('slug');

    if (slug != totalSlug) {
      // assume the button should be hidden
      $(button).removeClass('filterable-visible-button');

      // get the old count from the button
      var targetButtonSelector = controlSelector + ' a.filterable-common-button.' +
                                 slug;
      var countSelector = targetButtonSelector + ' .filterable-actual-count';
      var oldCount = parseInt($(countSelector).text());

      var newCount = oldCount;
      if (itemSlugs[slug]) {
        var delta = itemSlugs[slug];
        newCount = oldCount + delta;
        $(countSelector).text(newCount.toString());
      }

      if (newCount > 0 && newCount < totalNewCount) {
        $(button).addClass('filterable-visible-button');
      }
    } // if (slug != totalSlug)
  }); // loop (find/each)

  // "tag" the last showing button for styling purposes
  filterableTagLastVisibleFilterButton(controlSelector);
}


// helpers

function filterableBuildFilterControlSelector(sectionName) {
  return '.filterable-container' +
         '[data-filterable-section=' + sectionName + ']';
}

function filterableEngageAllButtons(filterControlContainerSelector) {
  var buttonSelector = 'a.filterable-common-button';
  var disengagedClassName = 'filterable-disengaged';
  $(filterControlContainerSelector).find(buttonSelector).removeClass(disengagedClassName);
}

function filterableGetButtonCount(button) {
  var buttonCountString = button.find('.filterable-actual-count').html();
  return parseInt(buttonCountString);
}

function filterableGetEngagedFiltersCore(filterControlContainer, totalSlug) {
  var filterNames = [];

  var buttonSelector = 'a.filterable-common-button';
  $(filterControlContainer).find(buttonSelector).each(function(index, filterButton) {
    if (! $(filterButton).hasClass('filterable-disengaged') &&
        ! $(filterButton).hasClass(totalSlug)) {
      var classes = $(filterButton).attr('class');
      var filterName = classes.replace('filterable-common-button', '').
                               replace('filterable-visible-button', '').
                               replace('filterable-last-button', '').trim();

      filterNames.push(filterName);
    }
  });

  return filterNames;
}

/*
 * filterablePreHandleButtonTap
 * we have four separate handlers for filter button tap events; this method encapsulates the peramble to those
 * handlers that extracted the tapped button's link from the event itself
 * -- ---- -- -- --
 * event: a filter button tap event
 */
function filterablePreHandleButtonTap(event) {
  event = event || window.event
  pmappPreventDefaults(event);

  var somePartOfAFilterButton = event.target || event.srcElement;
  var tappedLink = $(somePartOfAFilterButton).closest('a');

  return tappedLink;
}

/*
 * filterableReportFilterToServer
 * filtering is largely handled in the browser, but needs to be reported the server to allow the server to
 * react to the filtering in anyway it sees fit
 * -- ---- -- -- --
 * buttons: a non-jQuery handle to the filterable buttons container
 * section: the section name of the item list
 * slugs:   a list of slugs indicating which filters are engaged; null or empty if not filtering
 * outside: if given, an object containing outside parameters that should be sent along with the filtering
 *          parameters
 */
function filterableReportFilterToServer(buttons, section, slugs, outside) {
  var filterUrl = $(buttons).data('filterable-url');

  // unpack any data that was directly supplied by the rootable controller
  var filteringParams = { section:   section,
                          slugs:     slugs    }

  var params;
  if (outside) {
    params = Object.assign(filteringParams, outside);
  }
  else {
    params = filteringParams;
  }

  // add any data that was squirreled away by the root controller
  // TODO - check to see if this breaks the job dashboards
  var squirreledParams = squirrelableGetData('filterable_filter');
  if (squirreledParams) {
    params = Object.assign(params, squirreledParams);
  }

  // if the item list's hosting controller enabled filtering, but does not want to be notified when filtering
  // changes, the url data value will be set to "true"
  if (filterUrl != "true") {
    $.ajax({
      data:  params,
      type:  'POST',
      url:   filterUrl,
      async: true
    });
  }
}

/*
 * 
 * filterableRequestServerFiltering
 * if, when initialized, Filterable is provided a function for generating data to use when filtering, then
 * Filterable will send any filtering-change request to the server instead of handling the filtering here
 * (client-side); this method uses the provided function and submits the filtering-change request to the server
 * -- ---- -- -- --
 * tappedLink:            the link for the tapped button
 * generateOutsideParams: the function for generating data to use when filtering
 */
function filterableRequestServerFiltering(tappedLink, generateOutsideParams) {
  var filterControlContainer = $(tappedLink).closest('.filterable-container');
  var sectionName = $(filterControlContainer).data('filterable-section');
  var slugs = filterableGetEngagedFiltersCore(filterControlContainer);
  var outside = generateOutsideParams(sectionName);

  filterableReportFilterToServer(filterControlContainer, sectionName, slugs, outside);
}

/*
 *
 * filterableTagLastVisibleFilterButton
 * we need to round the corners of the last visible button; since there is no way to do this with CSS only,
 * we place a class on the last visible button with JS
 * -- ---- -- -- --
 * controlSelector: a CSS selector that will select the filter control button array
 */
function filterableTagLastVisibleFilterButton(controlSelector) {
  var activeButtonsSelector = controlSelector + ' a.filterable-common-button' +
                              '.filterable-visible-button'
  $(activeButtonsSelector).removeClass('filterable-last-button');
  $(activeButtonsSelector).last().addClass('filterable-last-button');
}

function filterableUpdateControlsForFilterTap(tappedLink) {
  var filterControlContainer = $(tappedLink).closest('.filterable-container');
  var sectionName = $(filterControlContainer).data('filterable-section');

  // totalSlug is the slug that corresponds to the "all items" button
  var totalSlug = $(filterControlContainer).data('filterable-total');

  var buttonSelector = 'a.filterable-common-button';
  var disengagedClassName = 'filterable-disengaged';

  // decide if, before the updates we need to make, we are filtering
  var filtering = false;
  var disengagedTotalSelector = buttonSelector + '.' + totalSlug + '.' + disengagedClassName;
  if ($(filterControlContainer).find(disengagedTotalSelector).length > 0) {
    filtering = true;
  }

  if (filtering) {
    // in most cases, when filtering, tapping any other button will simply toggle that button
    if ($(tappedLink).hasClass(disengagedClassName)) {
      $(tappedLink).removeClass(disengagedClassName);
    }
    else {
      $(tappedLink).addClass(disengagedClassName);

      // if all buttons are disengaged, engage them all
      if ($(filterControlContainer).find(buttonSelector + '.' + disengagedClassName).length ==
          $(filterControlContainer).find(buttonSelector).length) {
        $(filterControlContainer).find(buttonSelector).removeClass(disengagedClassName);
        filtering = false;
      }
    }
  }
  else { // not filtering
    // when we are not filtering, tapping any button other than the total tasks button will disengage all
    // buttons except for the one tapped
    if (! $(tappedLink).hasClass(totalSlug)) {
      $(filterControlContainer).find(buttonSelector).addClass(disengagedClassName);
      $(tappedLink).removeClass(disengagedClassName);
      filtering = true;
    }
  }

  // at this point filtering tells us if we are filtering following the changes we have made
  if (filtering) {
    // check to see if the selected filters are equivalent to not filtering
    var totalItems = 0;
    var selectedItems = 0;
    $(filterControlContainer).find(buttonSelector).each(function(index, button) {
      if ($(button).data('slug') != totalSlug) {
        if (! $(button).hasClass(disengagedClassName)) {
          selectedItems += filterableGetButtonCount($(button));
        }
      }
      else {
        totalItems = filterableGetButtonCount($(button));
      }
    });

    if (selectedItems == totalItems) {
      $(filterControlContainer).find(buttonSelector).removeClass(disengagedClassName);
    }
  }
}
