/*
 * PMApp::non_labor_expenses
 * Place JS to support non-labor expneses related functionality in this file
 */

import { attachableSetupEventHandlers } from './attachable.js.erb';
import { initializeDateTimePickers    } from './date_time_pickers.js.erb';
import { pmappPreventDefaults         } from './pmapp.js.erb';


// methods

/*
 * SetupMaterialItemFormModalEventHandlers
 * sets up the event handlers on the new/edit NLE modal form to allow the quantity, unit_cost, and
 * extended cost to recompure when the user changes any of the fields
 */

export function nonLaborExpensesSetupMaterialItemFormModalEventHandlers() {
  $('#quantityTextInput').change(function() {
    nonLaborExpensesHandleQuantityFieldChange();
  });

  $('#unitCostTextInput').change(function() {
    nonLaborExpensesHandleUnitCostFieldChange();
  });

  $('#extendedCostTextInput').change(function() {
    nonLaborExpensesHandleExtendedCostFieldChange();
  });
}

/*
 * SetupMultiSelectModalFocusEventHandlers
 * the quantity on task and quantity installed fields in task-NLE association modals default to special
 * text values; most of the time users will want to replace that text with a number; this method sets up
 * event handlers on the quantity on task and quantity installed fields to auto select the default text
 * content when the field receives focus
 */

export function nonLaborExpensesSetupMultiSelectModalFocusEventHandlers() {
  var controlsContainerSelector = '.controls-container';
  var plannedFieldLocalSelector = 'input.planned-quantity-text-field'
  var plannedFieldSelector = controlsContainerSelector + ' ' + plannedFieldLocalSelector;
  nonLaborExpensesSetupSelectAllOnClickEventHandler(
    plannedFieldSelector,
    'not specified'
  );

  var installedFieldLocalSelector = 'input.installed-quantity-text-field'
  var installedFieldSelector = controlsContainerSelector + ' ' + installedFieldLocalSelector;
  nonLaborExpensesSetupSelectAllOnClickEventHandler(
    installedFieldSelector,
    'none'
  );
}

/*
 * SetupMultiSelectModalHideShowControlsEventHandlers
 * sets up the event handlers to hide and show the supplemental fields for each option in a multi select modal
 * (there are many including the associate task and NLE modals and the receipt modal) when the options are
 * checked or unchecked
 * -- ---- -- -- --
 * multiCheckControlClassName: TODO
 * optionCheckClassName:       TODO
 */

export function nonLaborExpensesSetupMultiSelectModalHideShowControlsEventHandlers(
  multiCheckControlClassName,
  optionCheckClassName
) {
  var checkboxSelector = '.' + multiCheckControlClassName + ' .' + optionCheckClassName;
  $(checkboxSelector).change(function(event) {
    var e = event || window.event
    var triggeringCheck = e.target || e.srcElement;

    var itemContainer = $(triggeringCheck).closest(
      '.pmapp-multi-select-option'
    );

    var controlsContainerSelector = '.controls-container';

    if (triggeringCheck.checked) {
      itemContainer.find(controlsContainerSelector).show();
    }
    else {
      itemContainer.find(controlsContainerSelector).hide();
    }
  });
}

/*
 * SetupReceiptModalEventHandlers
 * TODO
 */

export function nonLaborExpensesSetupReceiptModalEventHandlers() {
  initializeDateTimePickers();

  // setup event handlers for the team select
  $('#' + nonLaborExpensesBuildTeamSelectId()).change(function(event) {
    var e = event || window.event
    var triggeringSelect = e.target || e.srcElement;
    nonLaborExpensesHandleSelectChange(triggeringSelect);
  });

  attachableSetupEventHandlers('nonLaborExpensesModal');

  nonLaborExpensesSetupMultiSelectModalHideShowControlsEventHandlers(
    'nle-list-control',
    'material-item-check'
  );
}

/*
 * SetupRemoveItemEventHandler
 * used when a new material item is added to the modal so that the item can be removed if desired
 * -- ---- -- -- --
 * item_selector: a CSS selector that should result in the item container
 */

export function nonLaborExpensesSetupRemoveItemEventHandler(item_selector) {
  $(item_selector + ' .remove-material-item-link').click(function(event) {
    pmappPreventDefaults(event);

    var e = event || window.event
    var triggeringLinkIcon = e.target || e.srcElement;

    // TODO here we select and remove an option from the multi select; this should probably be generically
    //      implemented in pmapp_forms.js.erb
    var materialItem = $(triggeringLinkIcon).closest('.pmapp-multi-select-option');
    materialItem.remove();

    var remainingMaterialItems = $('.nle-list-control').find(
      '.pmapp-multi-select-option'
    );

    if (remainingMaterialItems.length > 0) {
      // the multi select is not empty; tag the last option
      var lastMaterialItem = remainingMaterialItems[remainingMaterialItems.length - 1];
      $(lastMaterialItem).addClass('last');
    }
    else {
      // the multi select is now empty; adjust its appearance accordingly
      $('#materialItemsContainer').addClass(
        'empty'
      );
    }

  });
}

/*
 * SetupTaskMultiSelectModalSumsEventHandlers
 * sets up event handlers on quantity on task and quantity installed fields in the Associate Task(s) with NLE
 * model to compute and display the corresponding sums
 */

export function nonLaborExpensesSetupTaskMultiSelectModalSumsEventHandlers() {
  var controlsContainerSelector = '.controls-container';
  var plannedFieldSelector = 'input.planned-quantity-text-field'
  var plannedTotalFieldSelector = '#quantityOnTaskTotalField';

  nonLaborExpensesSetupQuantitySumEventHandler(
    controlsContainerSelector,
    plannedFieldSelector,
    plannedTotalFieldSelector
  )

  var installedFieldSelector = 'input.installed-quantity-text-field'
  var installedTotalFieldSelector = '#quantityInstalledTotalField';

  nonLaborExpensesSetupQuantitySumEventHandler(
    controlsContainerSelector,
    installedFieldSelector,
    installedTotalFieldSelector
  )
}


// event handlers

/*
 * HandleExtendedCostFieldChange
 * on the new/edit NLE modal, if the user changed the extended cost, we assume they want to override the unit cost
 */

function nonLaborExpensesHandleExtendedCostFieldChange(triggeringField) {
  const extCostField = nonLaborExpensesGetExtendedCostField();
  const extCost = nonLaborExpensesReadCurrencyFieldValue(extCostField);

  if (! isNaN(extCost)) {
    const quantityField = nonLaborExpensesGetQuantityField();
    const quantity = parseInt($(quantityField).val());

    if (! isNaN(quantity)) {
      var unitCost = extCost;
      var approximate = false;
      const unitCostField = nonLaborExpensesGetUnitCostField();

      if (quantity > 1) {
        unitCost = extCost/quantity;
        var extCostInt = Math.round(100 * extCost);
        var unitCostInt = Math.round(100 * unitCost);
        var computedExtCostInt = unitCostInt * quantity;

        if (computedExtCostInt != extCostInt) {
          unitCost = Math.trunc(unitCost * 100)/100
          approximate = true;
        }
      }

      nonLaborExpensesWriteCurrencyFieldValue(unitCostField, unitCost, approximate);
    }
  }
}

/*
 * HandleQuantityFieldChange
 * on the new/edit NLE modal, if the quantity changed, we update the extended cost to match the quantity and unit cost
 */

function nonLaborExpensesHandleQuantityFieldChange() {
  const quantityField = nonLaborExpensesGetQuantityField();
  const quantity = parseFloat($(quantityField).val());

  if (! isNaN(quantity)) {
    const unitCostField = nonLaborExpensesGetUnitCostField();
    const unitCost = nonLaborExpensesReadCurrencyFieldValue(unitCostField);

    if (! isNaN(unitCost)) {
      const extCostField = nonLaborExpensesGetExtendedCostField();
      const approximate = false
      nonLaborExpensesWriteCurrencyFieldValue(extCostField, quantity * unitCost, approximate);
    }
  }
}

/*
 * HandleSelectChange
 * used to handle changes to the team select on the receipt modal form
 * -- ---- -- -- --
 * triggeringSelect: a JS handle to the team select
 */

function nonLaborExpensesHandleSelectChange(triggeringSelect) {
  var selectedTeamId = nonLaborExpensesGetSelectedTeamId();

  var params = { team_id: selectedTeamId };
  var updateUrl = nonLaborExpensesGetUpdateUrl(triggeringSelect);

  $.ajax({
    data:  params,
    type:  'POST',
    url:   updateUrl,
    async: true
  });
}

/*
 * HandleUnitCostFieldChange
 * on the new/edit NLE modal, if the user changed the unit cost, we update the extended cost to match the
 * quantity and unit cost
 */

function nonLaborExpensesHandleUnitCostFieldChange() {
  const unitCostField = nonLaborExpensesGetUnitCostField();
  const unitCost = nonLaborExpensesReadCurrencyFieldValue(unitCostField);


  if (! isNaN(unitCost)) {
    const quantityField = nonLaborExpensesGetQuantityField();
    const quantity = parseFloat($(quantityField).val());

    if (! isNaN(quantity)) {
      const extCostField = nonLaborExpensesGetExtendedCostField();
      const approximate = false;
      nonLaborExpensesWriteCurrencyFieldValue(extCostField, quantity * unitCost, approximate);
    }
  }
}


// helpers

// BuildTeamSelectId
function nonLaborExpensesBuildTeamSelectId() {
  return 'teamSelect';
}

// GetExtendedCostField
function nonLaborExpensesGetExtendedCostField() {
  return $('#extendedCostTextInput')[0];
}

// GetQuantityField
function nonLaborExpensesGetQuantityField() {
  return $('#quantityTextInput')[0];
}

// GetSelectedTeamId
function nonLaborExpensesGetSelectedTeamId() {
  var rval = null;
  rval = $('#' + nonLaborExpensesBuildTeamSelectId()).children('option:selected').val();
  return rval;
}

// GetUnitCostField
function nonLaborExpensesGetUnitCostField() {
  return $('#unitCostTextInput')[0];
}

/*
 * GetUpdateUrl
 * used to read a URL from a select; the read URL is supposedly for notifying the server of changes to the
 * select
 * -- ---- -- -- --
 * triggeringSelect: the subject select
 */

function nonLaborExpensesGetUpdateUrl(triggeringSelect) {
  var updateUrlKey = 'change-url';
  return $(triggeringSelect).data(updateUrlKey);
}

/*
 * ReadCurrencyFieldValue
 * used to read the value from a currency field; returns the read value as a float
 * -- ---- -- -- --
 * currencyField: the field from which to read
 */

function nonLaborExpensesReadCurrencyFieldValue(currencyField) {
  const currencyText = $(currencyField).val().replace(/[^\d\.]/g, '');
  return parseFloat(currencyText);
}

/*
 * SetupSelectAllOnClickEventHandler
 * sets up a click event handler on the text fields, selected by the given CSS selector, that will "select all"
 * of the content of the text field if the text field currently contains the given, enabling string 
 * -- ---- -- -- --
 * textFieldSelector: the given CSS selecter; selects the fields that should received the event handler
 * enablingValue:     the given, enabling string; a text field must contain this value if the event
 *                    handler is to select the text field's content
 */

function nonLaborExpensesSetupSelectAllOnClickEventHandler(textFieldSelector, enablingValue) {
  $(textFieldSelector).on('click', function(event) {
    var e = event || window.event
    var triggeringTextField = e.target || e.srcElement;

    var value = $(triggeringTextField).val();
    if (value.trim().toLowerCase().localeCompare(enablingValue.toLowerCase()) == 0) {
      $(triggeringTextField).select();
    }
  });
}

/*
 * SetupQuantitySumEventHandler
 * used to set up the event handlers that sum the quantity to task fields and the quantity installed fields
 * in the Associate Task(s) with NLE modal and display the totals
 * -- ---- -- -- --
 * controlsContainerSelector: the quantity to task and quantity installed text fields in the modal are wrapped
 *                            in a container element; this argument is a CSS selector for those container elements
 * textFieldSelector:         the CSS selector for selecting the text fields within the controls container
 * totalFieldSelector:        the CSS selector for selecting the total field to update; the total field is
 *                            expected to be a div or span or something that responds to the jQuery text method
 */

function nonLaborExpensesSetupQuantitySumEventHandler(
  controlsContainerSelector,
  textFieldSelector,
  totalFieldSelector
) {
  $(controlsContainerSelector + ' ' + textFieldSelector).on('input', function(event) {
    var total = 0;
    $(controlsContainerSelector).each(function(index) {
      if ($(this).css('display') != 'none') {
        var value = parseInt($(this).find(textFieldSelector).val());
        if (! isNaN(value) && value > 0) {
          total += value;
        }
      }
    });

    $(totalFieldSelector).text(total.toString());
  });
}

/*
 * WriteCurrencyFieldValue
 * used to write a float, to a text field as a currency value
 * -- ---- -- -- --
 * currencyField: the field to which to write
 * rawValue:      the given float
 * approximate:   true if the value should be presented as an approximate value; false otherwise
 */

function nonLaborExpensesWriteCurrencyFieldValue(currencyField, rawValue, approximate) {
  const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
  var cookedValue = formatter.format(rawValue);
  if (approximate) {
    cookedValue = "~ " + cookedValue;
    $(currencyField).addClass('approximate');
  }
  else {
    $(currencyField).removeClass('approximate');
  }

  $(currencyField).val(cookedValue);
}
