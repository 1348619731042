/*
 * PMApp:non_task_buckets
 * Place JS here to support functionality specific to the Non Task Time Buckets controller
 */

import "jquery-minicolors/jquery.minicolors.min";

import { customerSelectorSetupEventHandlers } from './customer_selector.js.erb';
import { teamFocusSetupEventHandlers } from './team_focus.js.erb';
import { hideShowCategoryControls } from './billable.js.erb';


$(document).on('turbolinks:load', function() {
  if ($('body.non_task_buckets').length) {
    if ($('body.index').length == 0) {
      customerSelectorSetupEventHandlers();

      nonTaskBucketsOverheadToggle();

      $('#overheadCheckbox').change(function() {
        nonTaskBucketsHandleOverheadChange();
      });
    }
    else {
      teamFocusSetupEventHandlers();
    }

    $('.minicolor').minicolors({ theme: 'bootstrap' });
  }
});


// event handlers -- ---- -- -- --

function nonTaskBucketsHandleOverheadChange() {
  nonTaskBucketsOverheadToggle();
}


// functions -- ---- -- -- --

/*
 * nonTaskBucketsOverheadToggle
 * This function is used to hide/show controls on a time bucket edit page based on whether or not the user has
 * indicated that the time bucket should be an overhead time bucket.
 */
function nonTaskBucketsOverheadToggle() {
  if ($('#overheadCheckbox').prop("checked")) {
    // overhead task

    // We won't clear the controls, that way, if the user changes their mind, whatever they selected before will
    // still be selected.  We will need to remember to ignore these values in the controller if overhead is true.

    // show the PTO check
    $('#ptoCheckboxWrapper').show();

    // hide the customer controls
    $('#customerControlsWrapper').hide();

    // hide the category and work_type selectors
    $('#categoryControlsWrapper').hide();
    $('#workTypeControlsWrapper').hide();

    // hide the billable check
    $('#billableCheckboxWrapper').hide();
  }
  else {
    // not an overhead task

    // hide the PTO check
    $('#ptoCheckboxWrapper').hide();
    $('#ptoCheckboxWrapper input').prop("checked", false);

    // make sure the customer selector is enabled
    $('#customerControlsWrapper').show();

    // make sure the categroy and work type selectors are enabled
    hideShowCategoryControls('categoryControlsWrapper',
                             'workTypeControlsWrapper');

    // make sure the billable check is showing
    $('#billableCheckboxWrapper').show();
  }
}
