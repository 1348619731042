/*
 * PMApp::customer_requests
 * Place JS here to support functionality specific to the Customer Reqeusts controller
 */

const subjectClass = 'd-none';


export function customerRequestsToggleMcdDisplay(require_mcd) {
  var mcdFieldContainer =  $('#mustCompleteByField').closest('div.row');
  var hasClass = mcdFieldContainer.hasClass(subjectClass);

  if (require_mcd) {
    if (hasClass) {
      mcdFieldContainer.removeClass(subjectClass);
    }
  }
  else {
    if (! hasClass) {
      mcdFieldContainer.addClass(subjectClass);
    }
  }
}
