/*
 * PMApp:customer_selector
 * This file is part of the PMApp Customer Selector component
 */

import { pmappPreventDefaults } from './pmapp.js.erb';

// functions -- ---- -- -- --

/*
 * customerSelectorAddFieldsToParams
 * This function is used as a helper by other customer selector JS functions as well as by other PMApp JS functions.
 * This function is used to infuse customer selector params into a more general params list to be submitted to
 * some fucntionality-specific action.
 * -- ---- -- -- --
 * params: the params list into which the customer selector params are to be infused (see description)
 */
export function customerSelectorAddFieldsToParams(params) {
  var selectedCustomerId = customerSelectorGetSelectedCustomerId();
  var selectedLocationId = customerSelectorGetSelectedLocationId();
  var workerId = customerSelectorGetWorkerId();
  var teamId = customerSelectorGetTeamId();
  var filteringFlag = customerSelectorGetFilteringFlag();
  var hostControllerData = customerSelectorGetHostControllerData();

  params['customer_selector_customer'] = selectedCustomerId;
  params['customer_selector_location'] = selectedLocationId;
  params['customer_selector_worker_id']       = workerId;
  params['customer_selector_team_id']         = teamId;
  params['customer_selector_filtering']  = filteringFlag;
  params['customer_selector_host_data']       = hostControllerData;

  return params;
}

/*
 * customerSelectorSetupEventHandlers
 * This function is called with the cutsomer selector is first rendered to set up the necessary event handlers.
 * -- ---- -- -- --
 * generateBaseParams: if present, a function to generate a base set of params to which the customer selector
 *                     params should be added before hitting the update control action
 */
export function customerSelectorSetupEventHandlers(generateBaseParams) {

  $('#customerSelectorCustomerSelector').change(function(e) {
    e = e || window.event
    var triggeringSelector = e.target || e.srcElement;

    var params;
    if (generateBaseParams && typeof(generateBaseParams) === typeof(Function))
    {
      params = generateBaseParams();
    }
    else {
      params = {};
    }
    customerSelectorUpdateControls(triggeringSelector, params);
  });

  $('#customerSelectorLocationSelector').change(function(e) {
    e = e || window.event
    var triggeringSelector = e.target || e.srcElement;

    var params;
    if (generateBaseParams && typeof(generateBaseParams) === typeof(Function))
    {
      params = generateBaseParams();
    }
    else {
      params = {};
    }
    customerSelectorUpdateControls(triggeringSelector, params);
  });

  customerSelectorSetupFilteringEventHandlers();
}

/*
 * customerSelectorSetupFilteringEventHandlers
 * This function sets up the necessary event handles for the customer selector filtering control.  This function
 * is separate from customerSelectorSetupEventHandlers because it needs to be re-executed each time the
 * filtering control is redrawn.
 */
export function customerSelectorSetupFilteringEventHandlers() {
  $('#customerSelectorFilterLinksWrapper a').click(function(e) {
    e = e || window.event
    pmappPreventDefaults(e);

    var triggeringElement = e.target || e.srcElement;
    customerSelectorChangeFiltering(triggeringElement);
  });

  $('#customerSelectorFilterLinksWrapper').hover(
    function(e) { // hover in
      $('#customerSelectorFilterLinksWrapper .customer-selector-filter-links').show();

      // close the customer selector so the select options don't obscure the filtering options
      $('#customerSelectorCustomerSelector').blur();
    },
    function(e) { // hover out
      $('#customerSelectorFilterLinksWrapper .customer-selector-filter-links').hide();
    },
  );
}


// event handlers -- ---- -- -- --

/*
 * customerSelectorChangeFiltering
 * This handler is fired whenever the user picks a new filtering level.
 * -- ---- -- -- --
 * filteringElement: the element of the filtering control the user selected to incidate that they wish to
 *                   change the filtering level
 */
function customerSelectorChangeFiltering(filteringElement) {
  var filteringLink = $(filteringElement).closest('a');

  var params = customerSelectorAddFieldsToParams({});

  var filteringKey = 'filter';
  params['customer_selector_filtering'] = $(filteringLink).data(filteringKey);

  var updateUrlKey = 'url';
  var updateUrl = $(filteringLink).data(updateUrlKey);

  $.ajax({
    data:  params,
    type:  'GET',
    url:   updateUrl,
    async: true
  });
}

/*
 * customerSelectorUpdateControls
 * This handler fires when the user changes the customer or location
 * -- ---- -- -- --
 * triggeringSelector: the selector (either customer or location) that caused the event to fire
 * givenParams:        the set of params, generated by some outside entity, to which customer selector
 *                     params will be added before hitting the update controls action
 */
function customerSelectorUpdateControls(triggeringSelector, givenParams) {
  var params = customerSelectorAddFieldsToParams(givenParams);

  var updateUrlKey = 'url';
  var updateUrl = $(triggeringSelector).data(updateUrlKey);

  $.ajax({
    data:  params,
    type:  'POST',
    url:   updateUrl,
    async: true
  });
}


// helpers -- ---- -- -- --

function customerSelectorBuildCustomerSelectorId() {
  return 'customerSelectorCustomerSelector';
}

function customerSelectorBuildLocationSelectorId() {
  return 'customerSelectorLocationSelector';
}

function customerSelectorBuildTeamFieldId() {
  return 'customerSelectorTeamField';
}

function customerSelectorBuildWorkerFieldId() {
  return 'customerSelectorWorkerField';
}

function customerSelectorBuildFilteringFlagFieldId() {
  return 'customerSelectorFilterFlagField';
}

function customerSelectorBuildHostControllerDataFieldId() {
  return 'customerSelectorHostDataField';
}

function customerSelectorGetSelectedCustomerId() {
  return $('#' + customerSelectorBuildCustomerSelectorId()).children('option:selected').val();
}

function customerSelectorGetSelectedLocationId() {
  return $('#' + customerSelectorBuildLocationSelectorId()).children('option:selected').val();
}

function customerSelectorGetTeamId() {
  return $('#' + customerSelectorBuildTeamFieldId()).val();
}

function customerSelectorGetWorkerId() {
  return $('#' + customerSelectorBuildWorkerFieldId()).val();
}

function customerSelectorGetFilteringFlag() {
  return $('#' + customerSelectorBuildFilteringFlagFieldId()).val();
}

function customerSelectorGetHostControllerData() {
  return $('#' + customerSelectorBuildHostControllerDataFieldId()).val();
}
