/*
 * PMApp::squirrelable
 * JS that is part of the Squirrelable component
 */

// public functions -- ---- -- -- --

export function squirrelableGetData(actionName) {
  var rVal;

  // get anything explicity squirreled away for the specified action
  var explicitlySquirreledData = squirrelableGetDataSimple(actionName);

  // get anything squirreled away for all actions
  var generallySquirreledData;
  var selector = squirrelableBuildSelector('squirreled-all');
  if ($(selector).length > 0) {
    var squirreledJSON = $(selector).text();
    generallySquirreledData = JSON.parse(squirreledJSON);
  }

  if (explicitlySquirreledData && generallySquirreledData) {
    rVal = Object.assign(explicitlySquirreledData, generallySquirreledData);
  }

  if (explicitlySquirreledData && ! generallySquirreledData) {
    rVal = explicitlySquirreledData;
  }

  if (! explicitlySquirreledData && generallySquirreledData) {
    rVal = generallySquirreledData;
  }

  return rVal;
}


export function squirrelableGetDataSimple(actionName) {
  var rVal;

  // get anything explicity squirreled away for the specified action
  var selector = squirrelableBuildSelector(actionName);
  if ($(selector).length > 0) {
    var squirreledJSON = $(selector).text();
    rVal = JSON.parse(squirreledJSON);
  }

  return rVal;
}

export function squirrelableBuildSelector(actionName) {
  return '[data-action=' + actionName + '].squirreled-data';
}
