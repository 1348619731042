/*
 * PMApp::date_time_pickers
 */

import "tempusdominus-bootstrap-4"
$.fn.datetimepicker.Constructor.Default = $.extend({}, $.fn.datetimepicker.Constructor.Default, {
  icons: {
    time:     'far fa-clock',
    date:     'fas fa-calendar',
    up:       'fas fa-arrow-up',
    down:     'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next:     'fas fa-chevron-right',
    today:    'fas fa-calendar-check',
    clear:    'fas fa-trash',
    close:    'fas fa-times'
  }
});

$(document).on('turbolinks:load', function() {
  initializeDateTimePickers();
});

export function initializeDateTimePickers() {
  $('.pmapp-datetime-picker').datetimepicker();

  $('.pmapp-date-picker').datetimepicker({
    format: 'L'
  });

  $('.pmapp-time-picker').datetimepicker({
    format: 'LT'
  });
}
