/*
 * PMApp::sortable
 * This file is includes JS that directly supports the sortable component
 */

import { showLoadingOverlay   } from "./loading_overlay";
import { pmappPreventDefaults } from './pmapp.js.erb';

// functions -- ---- -- -- --

export function sortableRememberSortDirection(sectionName, direction) {
  var dirControlSelector = sortableBuildDirectionControlSelector(sectionName);
  var dirLinkSelector = dirControlSelector + ' a';

  $(dirLinkSelector).data('direction', direction);
}

export function sortableSetupFullControlEventHandlers(sectionName, generateOutsideParams) {
  sortableSetupIndexControlEventHandlers(sectionName, generateOutsideParams);
  sortableSetupDirectionControlEventHandlers(sectionName, generateOutsideParams);
}

function sortableGenerateOutsideParams(sectionName, generateOutsideParams) {
  var outsideParams;

  if (generateOutsideParams && typeof(generateOutsideParams) === typeof(Function)) {
    outsideParams = generateOutsideParams(sectionName);
  }
  else {
    outsideParams = { };
  }

  return outsideParams;
}

/*
 * sortableSetupIndexControlEventHandlers
 * call this function when using the sortable index select control to configure the necessary event handlers
 */
export function sortableSetupIndexControlEventHandlers(sectionName, generateOutsideParams) {
  var indexControlSelector = sortableBuildIndexControlSelector(sectionName);

  // stop clicks on the current index icon from reloading the page
  $(indexControlSelector).find('.sortable-current a').click(function(e) {
    e = e || window.event
    pmappPreventDefaults(e);
  });

  // set up event handler for opening the sort index option list
  $(indexControlSelector).find('.sortable-index-hover-zone').hover(
    function(e) { // hover in
      $(indexControlSelector).find('.sortable-options-inner').css('display', 'block');
    },
    function(e) { // hover out
      $(indexControlSelector).find('.sortable-options-inner').css('display', 'none');
    }
  );

  // set up event handler for selecting a new sort index
  $(indexControlSelector).find('.sortable-link').click(function(e) {
    e = e || window.event
    pmappPreventDefaults(e);

    var triggeringElement = e.target || e.srcElement;

    showLoadingOverlay();
    var outsideParams = sortableGenerateOutsideParams(sectionName, generateOutsideParams);
    sortableChangeIndex(triggeringElement, outsideParams);
  });
}

/*
 * sortableSetupDirectionControlEventHandlers
 * call this function when using the sortable direction control to configure the necessary event handlers
 */
export function sortableSetupDirectionControlEventHandlers(sectionName, generateOutsideParams) {
  var dirControlSelector = sortableBuildDirectionControlSelector(sectionName);

  // set up an event handler for tapping the direction change control
  $(dirControlSelector + ' a').click(function(e) {
    e = e || window.event
    pmappPreventDefaults(e);

    var directionIcon = e.target || e.srcElement;
    var directionLink = $(directionIcon).closest('a');

    var outsideParams = sortableGenerateOutsideParams(sectionName, generateOutsideParams);
    sortableChangeDirection(directionLink, outsideParams);
  });
}


// event handlers -- ---- -- -- --

/*
 * sortableChangeDirection
 * This handler is fired whenever the user taps the change direction control
 * -- ---- -- -- --
 * dirLink: the change direction control link
 */
function sortableChangeDirection(dirLink, outsideParams) {
  // get the list section name and the sort url
  var control = $(dirLink).closest('.sortable-direction-control-wrapper');
  var sectionName = $(control).data('section');
  var updateUrl = $(control).data('url');

  var currentSlug = $('#sortableIndexControlWrapper' + sectionName).data(
    'slug'
  );

  // get the current direction and toggle it
  var currentDirection = $(dirLink).data('direction');
  var newDirection;
  if (currentDirection == 0) {
    newDirection = 1;
  }
  else {
    newDirection = 0;
  }

  var params = outsideParams;
  params['slug']      = currentSlug
  params['section']   = sectionName
  params['direction'] = newDirection

  $.ajax({
    data:  params,
    type:  'POST',
    url:   updateUrl,
    async: true
  });
}

/*
 * sortableChangeIndex
 * This handler is fired whenever the user picks a new sort index
 * -- ---- -- -- --
 * indexElement: the element of the sortable control the user selected to incidate that they wish to
 *               change the sort index
 */
function sortableChangeIndex(indexElement, outsideParams) {
  // get the list section name and sort url
  var control = $(indexElement).closest('.sortable-index-control-wrapper');
  var sectionName = $(control).data('section');
  var updateUrl = $(control).data('url');
  var selectedSlug = $(indexElement).data('slug');

  // we never send the direction when switching indexes, we prefer to have the direction start out in a
  // more natural (default) order for the new index
  var params = outsideParams;
  params['slug']    = selectedSlug
  params['section'] = sectionName

  $.ajax({
    data:  params,
    type:  'POST',
    url:   updateUrl,
    async: true
  });
}

export function sortableGetCurrentDirection(sectionName) {
  var dirControlSelector = sortableBuildDirectionControlSelector(sectionName);
  var dirElementSelector = dirControlSelector + ' a';
  var currentDirection = $(dirElementSelector).data('direction');

  return currentDirection;
}

export function sortableGetActiveSortIndex(sectionName) {
  var indexControlSelector = sortableBuildIndexControlSelector(sectionName);
  var selectedSlug = $(indexControlSelector).data('slug');

  return selectedSlug;
}


// private -- ---- -- -- --

function sortableBuildDirectionControlSelector(sectionName) {
  return '#sortableDirectionControlWrapper' + sectionName;
}

function sortableBuildIndexControlSelector(sectionName) {
  return '#sortableIndexControlWrapper' + sectionName;
}
