/*
 * PMApp::job_listable.js.erb
 */

import { filterableClear,
         filterableGetEngagedFilters,
         filterableInitializeItemListAsFilterable,
         filterableCountVisibleFilterButtons       } from "./filterable.js.erb";
import { showLoadingOverlay                        } from "./loading_overlay";
import { pmappPreventDefaults                      } from './pmapp.js.erb';
import { rosterableInitializeAsDraggable           } from './rosterable.js.erb';
import { sortableSetupFullControlEventHandlers,
         sortableSetupIndexControlEventHandlers,
         sortableGetCurrentDirection,
         sortableGetActiveSortIndex                } from './sortable.js.erb';
import { workerAvatarListInitializePopovers,
         workerAvatarListDestroyPopovers           } from './worker_avatar_list.js.erb';


var jobListableFetchOutsideParams;


// -- ---- -- -- --
// initialization
// -- ---- -- -- --

/*
 * jobListableSetupEventHandlers
 * sets up event handlers for all job lists on the current page
 * -- ---- -- -- --
 * draggableHostSelector: a CSS selector that will find a DOM element to which dragging elements should be added 
 * fetchOutsideParams:    a function that will return an object that specifies outside parameters that should
 *                        be added to JobListable parameters when triggering server actions
 */
export function jobListableSetupEventHandlers(draggableHostSelector, fetchOutsideParams) {
  jobListableInitializeContributorPopovers();
  jobListableInitializeForDragAndDropOwnerAssignment(draggableHostSelector);

  if (fetchOutsideParams && typeof(fetchOutsideParams) === typeof(Function)) {
    jobListableFetchOutsideParams = fetchOutsideParams;
  }

  $('.job-listable').each(function(index, itemList) {
    var sectionName = $(itemList).data('section');
    jobListableSetupFilterEventHandlers(sectionName);
    sortableSetupFullControlEventHandlers(sectionName, jobListableFetchOtherParamsForSorting);
    jobListableSetupPagingEventHandlers(sectionName);
  });
}


// -- ---- -- -- --
// paging
// -- ---- -- -- --

// public

export function jobListableSetupPagingEventHandlers(sectionName) {
  var pageControlsWrapperSelector = '.job-listable-paging-wrapper' +
                                    '[data-section=' + sectionName + ']';

  // set up event handler for the "Page x of y" form
  $(pageControlsWrapperSelector + ' form').submit(function(e) {
    e = e || window.event
    pmappPreventDefaults(e);

    var triggeringForm = e.target || e.srcElement;

    jobListableHandlePageNumberFormChange(triggeringForm);
  });

  // set up event handlers on the "<< < ... > >>" links
  $(pageControlsWrapperSelector + ' a.paging-link').click(function(e) {
    e = e || window.event
    pmappPreventDefaults(e);

    var pagingLinkIcon = e.target || e.srcElement;
    var pagingLink = $(pagingLinkIcon).closest('a');

    jobListableHandlePagingLinkTap(pagingLink);
  });
}

// event handlers

function jobListableHandlePageNumberFormChange(triggeringForm, fetchOutSideParams) {
  var wrapperSelector   = '.job-listable-paging-wrapper';
  var containerSelector = '.job-listable-page-controls';

  var numPages    = $(triggeringForm).closest(containerSelector).data('count');
  var pageNumber  = $(triggeringForm).find('input[type=text]').val();
  var currentPage = $(triggeringForm).closest(containerSelector).data('page');

  pageNumber  = parseInt(pageNumber);
  numPages    = parseInt(numPages);
  currentPage = parseInt(currentPage);

  if (pageNumber > 0 && pageNumber <= numPages && pageNumber != currentPage) {
    var sectionName = $(triggeringForm).closest(wrapperSelector).data('section');
    var pagingUrl   = $(triggeringForm).closest(containerSelector).data('url');

    jobListableSubmitPageChangeToServer(sectionName, pageNumber, pagingUrl);
  }
  else {
    var pageNumber  = $(triggeringForm).find('input[type=text]').val(currentPage);
  }
}

/*
 * jobListableHandlePagingLinkTap
 * invoked when the user taps one of the paging "<< < ... > >>" links
 * -- ---- -- -- --
 * pagingLink:         a handle to the link that was tapped
 */
function jobListableHandlePagingLinkTap(pagingLink) {
  var wrapperSelector   = '.job-listable-paging-wrapper';
  var containerSelector = '.job-listable-page-controls';

  var pageNumber  = $(pagingLink).data('page');
  var sectionName = $(pagingLink).closest(wrapperSelector).data('section');
  var pagingUrl   = $(pagingLink).closest(containerSelector).data('url');

  jobListableSubmitPageChangeToServer(sectionName, pageNumber, pagingUrl);
}

// helpers

/*
 * jobListableSubmitPageChangeToServer
 * job item list paging is handled by the server
 * -- ---- -- -- --
 * sectionName:        identifies the subject job item list
 * pageNumber:         the page number that has been requested
 * pagingUrl:          the server URL to which to send the page change request
 */
function jobListableSubmitPageChangeToServer(sectionName, pageNumber, pagingUrl) {

  var pageParams = {
    section: sectionName,
    page:    pageNumber
  }

  var filterParams = jobListableFetchFilterParams(sectionName);
  var sortParams   = jobListableFetchSortParams(sectionName);

  var params;
  if (jobListableFetchOutsideParams) {
    var outsideParams = jobListableFetchOutsideParams(sectionName);
    var params = Object.assign(pageParams, filterParams, outsideParams, sortParams);
  }
  else {
    var params = Object.assign(pageParams, filterParams, sortParams);
  }

  $.ajax({
    data:  params,
    type:  'POST',
    url:   pagingUrl,
    async: true
  });
}


// -- ---- -- -- --
// item list sorting
// -- ---- -- -- --

// public

/*
 * jobListableSetupSortableIndexControlEventHandlers
 * for the teams dashboard, we configure Sortable to pass filtering information to the sortable_sort action;
 * this configuration is accomplished by passing a function that returns the filtering information to the
 * sortable index event handlers; whenever the sortable index control is redrawn via Rails view template, we
 * must reinstall the event handlers, but we cannot pass a JS function around between view template partials;
 * this function exists to allows to cope with that constraint
 * -- ---- -- -- --
 * sectionName:        identifies the item list for which we need to reinstall event handlers
 * fetchOutsideParams: if given, a function that returns an object specifying params that should be sent to
 *                     the server in response to any server-handled event; such params are not managed by
 *                     JobListable and will be needed by the server to properly handle the event
 */
export function jobListableSetupSortableIndexControlEventHandlers(sectionName, fetchOutsideParams) {
  if (fetchOutsideParams && typeof(fetchOutsideParams) === typeof(Function)) {
    jobListableFetchOutsideParams = fetchOutsideParams;
  }
  sortableSetupIndexControlEventHandlers(sectionName, jobListableFetchOtherParamsForSorting);
}

/*
 * jobListableFetchSortParams
 * while this method is sorting related, it is also filtering related; when filtering is changed, the
 * current sorting state must be sent along with the filtering information; this method is used to fetch the
 * sorting information for that purpose
 * -- ---- -- -- --
 * sectionName: identifies the item list for which we need to fetch sorting information
 */
export function jobListableFetchSortParams(sectionName) {
  var returnValue = { };

  var indexKey     = "sort_index";
  var directionKey = "sort_direction";

  if (sectionName) {
    var sortIndexSlug = sortableGetActiveSortIndex(sectionName);
    var sortDirection = sortableGetCurrentDirection(sectionName);

    returnValue[indexKey]     = sortIndexSlug;
    returnValue[directionKey] = sortDirection;
  }
  else {
    var primaryKey = "sorts";
    returnValue[primaryKey] = { };

    $('.job-listable').each(function(index, jobListContainer) {
      var section = $(jobListContainer).data('section');

      returnValue[primaryKey][section] = { };
      returnValue[primaryKey][section][indexKey]     = sortableGetActiveSortIndex(section);
      returnValue[primaryKey][section][directionKey] = sortableGetCurrentDirection(section);
    });
  }

  return returnValue;
}

// helpers

/*
 * jobListableFetchOtherParamsForSorting
 * fetches any JobListable-managed params that are needed by the server to handle a sorting event and combines
 * those params with the params given by the hosting "controller"
 * -- ---- -- -- --
 * sectionName: specifies the job list for which to fetch params
 */
function jobListableFetchOtherParamsForSorting(sectionName) {
  var returnValue;

  var filterParams = jobListableFetchFilterParams(sectionName);

  var otherParams;
  if (jobListableFetchOutsideParams) otherParams = jobListableFetchOutsideParams(sectionName);

  if (otherParams) {
    returnValue = Object.assign(filterParams, otherParams);
  }
  else {
    returnValue = filterParams;
  }

  return returnValue;
}


// -- ---- -- -- --
// filtering
// -- ---- -- -- --

// public functions

/*
 * jobListableFetchFilterParams
 * while this method is filtering related, it is also sorting related; when the sort index is changed, the
 * current filtering state must be sent along with the sorting information; this method is used to fetch the
 * filtering information for that purpose
 * -- ---- -- -- --
 * sectionName: if given, identifies the item list for which we need to fetch filtering information; if not
 *              given, filtering inforamtion will be fetched for all job lists
 */
export function jobListableFetchFilterParams(sectionName) {
  var returnValue = { };

  var containerSelector;
  if (sectionName) {
    var containerSelector = '#jobListableFiltersContainer' + sectionName;
  }
  else {
    var containerSelector = '[id^=jobListableFiltersContainer]';
  }

  var primaryKey = "filters";
  $(containerSelector).each(function(index, filtersContainer) {
    var filterSlugs = filterableGetEngagedFilters(filtersContainer);
    if (sectionName) {
      returnValue[primaryKey] = filterSlugs;
    }
    else {
      if (! returnValue[primaryKey]) returnValue[primaryKey] = { };
      
      var containerId = $(filtersContainer).attr('id');
      var section = containerId.replace('jobListableFiltersContainer', '');

      returnValue[primaryKey][section] = filterSlugs;
    }
  });

  return returnValue;
}

export function jobListableSetupFilterEventHandlers(sectionName) {
  var pageControlsWrapperSelector = '.job-listable-paging-wrapper' +
                                    '[data-section=' + sectionName + ']';

  if ($(pageControlsWrapperSelector).length > 0) {
    filterableInitializeItemListAsFilterable(sectionName, jobListableFetchOtherParamsForFiltering);
  }
  else {
    filterableInitializeItemListAsFilterable(sectionName);
  }

  jobListableHideShowFilterControls(sectionName);
}

/*
 * jobListableHideShowFilterControls
 * the presentation of the filter controls (in a bootstrap collapse) is specific to teams (at least in theory), this
 * method is used to hide/show the collapse in response to various events
 * -- ---- -- -- --
 * sectionName: identifies the item list for which the filter controls should be hidden/shown
 */
export function jobListableHideShowFilterControls(sectionName) {

  var filterButtonSelector   = '#jobListableFilterButton' + sectionName;
  var filterCollapseSelector = '#jobListableFilterCollapse' + sectionName;

  var numActiveFilters = filterableCountVisibleFilterButtons(sectionName);

  if (numActiveFilters <= 1) {
    // close the filter collapse
    $(filterCollapseSelector).collapse('hide');

    // hide the filter button
    $(filterButtonSelector).hide();

    // clear all filtering
    filterableClear(sectionName, jobListableFetchOtherParamsForFiltering);
  }
  else {
    $(filterButtonSelector).show();
  }
}


// helpers

/*
 * jobListableFetchOtherParamsForFiltering
 * fetches any JobListable-managed params that are needed by the server to handle a filtering event and combines
 * those params with the params given by the hosting "controller"
 * -- ---- -- -- --
 * sectionName: specifies the job list for which to fetch params
 */
function jobListableFetchOtherParamsForFiltering(sectionName) {
  var sortParams  = jobListableFetchSortParams(sectionName);
  var otherParams = jobListableFetchOutsideParams(sectionName);
  return Object.assign(sortParams, otherParams);
}


// -- ---- -- -- --
// contributors
// -- ---- -- -- --

// public

/*
 * jobListableInitializeContributorPopovers
 * initializes ALL job items so that clicking a contributor results in a bootstrap popover
 * -- ---- -- -- --
 * downSelector: a CSS selector that will isolate a part of the page on which to initialize contributor popovers;
 *               null or the empty string if all popovers are to be initialized
 */
export function jobListableInitializeContributorPopovers(downSelector) {
  var useDownSelector = downSelector; 
  if (! downSelector) useDownSelector = '';

  var withBodySelector = useDownSelector + ' .job-contributors-container >';
  var bodylessSelector = useDownSelector + ' .job-contributors-container ' +
                         '.owner-wrapper';
  var allSelector      = useDownSelector + ' .job-contributors-container';
  workerAvatarListInitializePopovers(bodylessSelector, withBodySelector, allSelector)
}

/*
 * jobListableDestroyContributorPopovers
 * dispose of popovers that have been created for the contributors section
 * -- ---- -- -- --
 * itemSelector: a CSS selector that will select any job list items for which the contributor popovers should be
 *               destroyed
 */
export function jobListableDestroyContributorPopovers(itemSelector) {
  var selector = itemSelector + ' .job-contributors-container';
  workerAvatarListDestroyPopovers(selector);
}


// -- ---- -- -- --
// drag/drop set owner
// -- ---- -- -- --

// initialization

/*
 * jobListableInitializeForDragAndDropOwnerAssignment
 * this method sets up hot spots for dropping workers, from the team roster, in order to make the dropped worker
 * the new job owner
 * -- ---- -- -- --
 * draggableHostSelector: a CSS selector that identifies the element on the page to which draggable elements
 *                        will be added when dragging
 */
function jobListableInitializeForDragAndDropOwnerAssignment(draggableHostSelector) {
  // the server will decide if dragging team members to assign the project owner should be allowed
  // if so, there will be at least one element with the class JobListable::SET_OWNER_HOTSPOT_CLASS_NAME
  if ($('.set-owner-hotspot').length > 0) {
    rosterableInitializeAsDraggable(draggableHostSelector, 'set-owner-hotspot');
    jobListableSetupDragAndDropOwnerAssignmentEventHandlers();
  }
}

/*
 * jobListableSetupDragAndDropOwnerAssignmentEventHandlers
 * this method is designed to allow re-installing the event handlers for a specific list
 * -- ---- -- -- --
 * downSelector: a CSS selector that will isolate a part of the page on which to initialize contributor popovers;
 *               null or the empty string if all popovers are to be initialized
 */
export function jobListableSetupDragAndDropOwnerAssignmentEventHandlers(downSelector) {
  var useDownSelector = downSelector; 
  if (! downSelector) useDownSelector = '';

  var hotspotSelector;
  if ($(useDownSelector).length == 1 &&
      $(useDownSelector).hasClass('set-owner-hotspot')) {
    // the down selector is selecting a job item
    hotspotSelector = useDownSelector + '.set-owner-hotspot'; 
  }
  else {
    hotspotSelector = useDownSelector + ' ' + '.set-owner-hotspot'; 
  }

  // the server will decide if dragging team members to assign the project owner should be allowed
  // if so, there will be at least one element with the class JobListable::SET_OWNER_HOTSPOT_CLASS_NAME
  if ($(hotspotSelector).length > 0) {

    $(hotspotSelector).on("rosterableEnter", function (e) {
      e = e || window.event
      var droppable = e.target || e.srcElement;
      if (jobListableAcceptDropTeamMember(e.detail.teamMemberId)) {
        $(droppable).addClass("will-accept");
      }
    });

    $(hotspotSelector).on("rosterableExit", function (e) {
      e = e || window.event
      var droppable = e.target || e.srcElement;
      $(droppable).removeClass("will-accept");
    });

    $(hotspotSelector).on("rosterableReceive", jobListableHandleDropTeamMember);
  }
}

// event handlers

/*
 * jobListableHandleDropTeamMember
 * handle the user dropping an team member (worker) from a team roster on a job hot spot
 */

function jobListableHandleDropTeamMember(e) {
  e = e || window.event
  var droppable = e.target || e.srcElement;

  // un-highlight the receiving droppable
  $(droppable).removeClass("will-accept");

  // handle the drop itself
  var teamMemberId = e.detail.teamMemberId;

  // the UI should have indicated that the team member could not be dropped on the hotspot, but there really
  // isn't anything preventing the user from doing it anyway, so we check to be sure it should be allowed
  // to make sure nothing happends
  if (jobListableAcceptDropTeamMember(droppable, teamMemberId)) {
    var setOwnerUrl  = $(this).data("set-owner-url");

    $.ajax({
      data:  { worker_id: teamMemberId, assign_tasks: 1 },
      type:  'POST',
      url:   setOwnerUrl,
      async: true
    });

    showLoadingOverlay();
  }
}

// private functions

/*
 * jobListableAcceptDropTeamMember
 * checks if a a team member (worker) from a team roster can be dropped on a owner assignment hotspot
 * -- ---- -- -- --
 * droppable:    the receiving droppable
 * teamMemberId: the ID of the team member in question
 */
function jobListableAcceptDropTeamMember(droppable, teamMemberId) {
  var rVal = true;

  // don't accept if the team member in question is already the owner
  var ownerAvatarSelector = '.job-contributors-container ' +
                            '.owner-wrapper ' +
                            'figure[data-owner-id=' + teamMemberId + ']';
  if ($(droppable).find(ownerAvatarSelector).length > 0) {
    rVal = false;
  }

  return rVal;
}
