/*
 * PMApp::tinymce
 */

import "tinymce";
import "tinymce/models/dom";
import "tinymce/icons/default";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/autolink";
import "tinymce/themes/silver";

$(document).on('turbolinks:load', function() {
  var mentionlessClassName = 'tinymce-mentionless';
  if ($('.' + mentionlessClassName).length > 0) {
    pmappTinyMCEInitializeMentionless(mentionlessClassName);
  }

  var mentionableClassName = 'tinymce-mentionable';
  if ($('.' + mentionableClassName).length > 0) {
    pmappTinyMCEInitializeMentionable(mentionableClassName);
  }

  var readOnlyClassName = 'tinymce-readonly';
  if ($('.' + readOnlyClassName).length > 0) {
    pmappTinyMCEInitializeReadOnly(readOnlyClassName);
  }
});

// without this, sometimes tiny text areas don't initialize properly
// ref: https://github.com/spohlenz/tinymce-rails/issues/145
$(document).on('turbolinks:request-end', function() {
  if (tinymce) { tinymce.remove(); }
});


// public functions

export function pmappTinyMCEInitializeMentionable(class_name) {
  var mentionableConfig = pmappTinyMCEBuildBasicConfiguration(class_name);

  mentionableConfig["setup"] = function(editor) {

    function pmappTinyMCEMentionSelected(autoCompleteApi, range, value) {
      editor.selection.setRng(range);
      editor.insertContent('<span class="mention mceNonEditable">@' + value + '</span>');
      autoCompleteApi.hide();

      // I don't think I need to tell the server what was selected.  When the content is saved, PMApp can scan
      // the content for mentions and send notifications accordingly
    }

    editor.ui.registry.addAutocompleter('mentions',
                                         {
                                           ch: '@',
                                           minChars: 1,
                                           columns:  1,
                                           onAction: pmappTinyMCEMentionSelected,
                                           fetch: pmappTinyMCEFetchPotentialMentions,
                                           highlightOn: ['worker_name'],
                                           maxResults: 10
                                         });
  };

  tinymce.init(mentionableConfig);

  pmappTinyMCEDesignateAsMentionable(class_name);
}

export function pmappTinyMCEInitializeMentionless(class_name) {
  var mentionlessConfig = pmappTinyMCEBuildBasicConfiguration(class_name);
  tinymce.init(mentionlessConfig);
}

export function pmappTinyMCEInitializeReadOnly(class_name) {
  var readOnlyConfig = pmappTinyMCEBuildBasicConfiguration(class_name);
  readOnlyConfig["readonly"] = true;
  tinymce.init(readOnlyConfig);
}

export function pmappTinyMCESetupModalCleanupEventHandler(modalId, tinyMCEClassName) {
  $('#' + modalId).on('hide.bs.modal', function(event) {
    tinymce.remove('.' + tinyMCEClassName);
  });
}


// helpers

function pmappTinyMCEDesignateAsMentionable(class_name) {
  var textAreaLabel = $('.' + class_name).closest('.form-group').find('label')
  $("<sup class='pmapp-tinymce-mentionalble-designator'><i class='far fa-at'></i></sup>").insertAfter(textAreaLabel);
}

function pmappTinyMCEBuildBasicConfiguration(class_name) {
  return {
    selector: '.' + class_name,
    height: "200",
    content_css: '/assets/tinymce-cde52c4d4c6f12a20bd3c5a1d1d23dd2a0e2f4119c0c26e7a9df706dd0eb461e.css',
    content_style: '',
    browser_spellcheck: true,
    contextmenu: false,
    skin: false,
    smart_paste: false,
    paste_block_drop: true,
    paste_data_images: false,
    paste_postprocess: pmappTinyMCECleanHtmlNode,
    menubar: false,
    plugins: [ 'lists', 'link', 'autolink' ],
    toolbar: 'styleselect | ' +
      'bold italic underline | ' +
      'bullist numlist alignleft aligncenter alignright | ' +
      'link | ' +
      'undo redo | ' +
      'removeformat',
    style_formats: [
      { title: 'Normal text', block: 'p'  },
      { title: 'Heading 1',   block: 'h1' },
      { title: 'Heading 2',   block: 'h2' },
      { title: 'Heading 3',   block: 'h3' }
    ]
  };
}

function pmappTinyMCECleanHtmlNode(editor, args) {
  var node = args.node
  if (pmappTinyMCEIsDisallowedNode(node)) {
    
    /*
     * we are hosed here if node is a node we don't like, for example a form; I don't see any way to
     * change the type of node or replace it...we strip it of its attributes children, but that won't
     * stop it from hosing things up downstream...I think we may be able to fix this with TinyMCE 6
     * since it has args.content instead when may let us truly obliterate the how thing,
     * i.e. args.content = ""
     *
     * for now I am going to live with this as I expect it will be a very rare case indeed in which a
     * user will manage to just select one of the bad nodes
     */

    for (var i = 0; i < node.attributes.length; i++) {
      var attribute = node.attributes[i];
      node.removeAttribute(attributeName);
    }

    node.childNodes.forEach(function (childNode) {
      childNode.remove();
    });
  }
  else {
    $(node).find('form').contents().unwrap();
    pmappTinyMCEStripAttributesFromNode(node)
  }
}

function pmappTinyMCEFetchPotentialMentions(pattern) {
  return new Promise(function (resolve, reject) {
    $.ajax({
      data:  { pattern: pattern },
      type:  'GET',
      url:   "/workers/match_by_pattern",
      async: true,
      success: function(workers) {
        var potentialMentions = workers.map(function(worker) {
          return {
            type:  'cardmenuitem',
            value: worker.mention,
            label: worker.name,
            items: [
              {
                type: 'cardcontainer',
                items: [
                  {
                    type: 'cardimage',
                    src:  worker.avatar,
                    alt:  '',
                    classes: ['user-avatar', 'user-avatar-icon', 'user-avatar-mention']
                  },
                  {
                    type: 'cardtext',
                    text: worker.name,
                    name: 'worker_name'
                  }
                ]
              }
            ]
          }
        });
        resolve(potentialMentions);
      },
      error: function(error) {
        reject(error)
      }
    });
  });
}

function pmappTinyMCEIsDisallowedNode(node) {
  var returnValue = false;

  if ((node.nodeName == "IMG")    ||
      (node.nodeName == "IFRAME") ||
      (node.nodeName == "OBJECT") ||
      (node.nodeName == "FORM")   ||
      (node.nodeName == "INPUT")) {
    returnValue = true;
  }

  return returnValue;
}

function pmappTinyMCEStripAttributesFromNode(node) {
  // strip class and style attributes from the given node
  for (var i = 0; i < node.attributes.length; i++) {
    var attribute = node.attributes[i];
    var attributeName = attribute.name
    if ((attributeName == "class") ||
        (attributeName == "id") ||
        (attributeName == "style") ||
        (attributeName == "tabindex") ||
        (attributeName.startsWith("data-"))) {
      node.removeAttribute(attributeName);
    }
  }

  // do the same for each child node
  node.childNodes.forEach(function (childNode) {
    if (childNode.nodeType == Node.ELEMENT_NODE) {
      if (pmappTinyMCEIsDisallowedNode(node)) {
        childNode.remove();
      }
      else {
        pmappTinyMCEStripAttributesFromNode(childNode);
      }
    }
  });
}
