/*
 * PMApp::teams.js.erb
 * JS that is specific to the Teams controller
 */

import { periodableSetupEventHandlers,
         periodableFetchParams            } from './periodable.js.erb';
import { jobListableSetupEventHandlers,
         jobListableFetchFilterParams,
         jobListableFetchSortParams       } from './job_listable.js.erb';
import { showLoadingOverlay               } from "./loading_overlay";
import { darkBkgMetricLabelClass,
         lightBkgMetricLabelClass,
         metricableColorBarChartBarLabels } from './metricable.js.erb';
import { teamFocusSetupEventHandlers      } from './team_focus.js.erb';


// -- ---- -- -- --
// initialization
// -- ---- -- -- --

$(document).on('turbolinks:load', function() {

  if ($('body.teams.team_metrics_report').length > 0) {
    teamFocusSetupEventHandlers();
    metricableColorBarChartBarLabels();

    $('#monthsSelect').change(function(e) {
      e = e || window.event

      showLoadingOverlay();

      var monthsSelect = e.target || e.srcElement;
      var monthsSelectForm = $(monthsSelect).closest('form');

      $(monthsSelectForm).submit();
    });
  }

  if ($('body.teams.dashboard').length > 0) {
    teamFocusSetupEventHandlers();
    teamsSetupDashboardEventHandlers();
  }
});


// public

/*
 * teamsFetchParamsForJobList
 * this method will be passed to JobListable so that "period" params can be passed to server along with any
 * JobListable params whenever a JobListable event occurs
 * -- ---- -- -- --
 * sectionName: when JobListable calls this method, it passes in the section name that identifies the job list
 *              for which the method is being called; in this case, we do the same thing regardless of the
 *              job list identified by this argument
 */
export function teamsFetchParamsForJobList(sectionName) {
  return periodableFetchParams();
}

/*
 * teamsSetupDashboardEventHandlers
 * initializaton needed when most of the dashboard changes (for example, if the period or focus team changes)
 */
export function teamsSetupDashboardEventHandlers() {
  periodableSetupEventHandlers(teamsFetchOtherParamsForPerioding);
  jobListableSetupEventHandlers('.entire-page', teamsFetchParamsForJobList);
}


// -- ---- -- -- --
// period
// -- ---- -- -- --

// helpers

function teamsFetchOtherParamsForPerioding() {
  var filterParams = jobListableFetchFilterParams();
  var sortParams   = jobListableFetchSortParams();

  return Object.assign(filterParams, sortParams);
}
