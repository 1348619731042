/*
 * PMApp::periodic_work_allocations
 * JS that pertains to periodic work allocations
 */

// public methods

export function periodicWorkAllocationsSetupFormEventHandlers() {

  $('#pwaLocationSelect').change(function(e) {
    e = e || window.event
    var locationSelect = e.target || e.srcElement;

    var form = $(locationSelect).closest("form");

    var updateUrl = $(locationSelect).data('pwalocurl');

    $.ajax({
      data:  form.serialize(),
      type:  'POST',
      url:   updateUrl,
      async: true
    });
  });
}
