/*
 * PMApp::billable
 * The JS in this file is related to the functionality found in the Billable controller concern
 */

// event handlers -- ---- -- -- --

/*
 * handleBillingTeamChange
 * this method gets the IDs currently selected for the billing team and category and sends them to a path that
 * is expected to hit the change_billing_team action in some controller
 * -- ---- -- -- --
 * billingTeamSelect:         a JS handle to the billing team select
 * categoryControlsWrapperId: the ID of the wrapper div that contains the Category select
 * billingTeamParamsKey:      the key to use when sending the billing team ID to the controller action
 * categoryParamsKey:         the key to use when sending the category ID to the controller action
 * pathDataKey:               the data key for finding the path the controller action
 */
export function handleBillingTeamChange(billingTeamSelect, categoryControlsWrapperId, billingTeamParamsKey,
                                        categoryParamsKey, pathDataKey) {
  handleBillingTeamChangeCore(billingTeamSelect, categoryControlsWrapperId, billingTeamParamsKey,
                                       categoryParamsKey, pathDataKey, null);
}


// public functions -- ---- -- -- --

/*
 * handleBillingTeamChangeCore
 * this method provides the core functionality for the handleBillingTeamChange event handler (above); this method
 * exist to allow other modules to wrap the core functionality for more specialized purposes
 * -- ---- -- -- --
 * billingTeamSelect:         a JS handle to the billing team select
 * categoryControlsWrapperId: the ID of the wrapper div that contains the Category select
 * billingTeamParamsKey:      the key to use when sending the billing team ID to the controller action
 * categoryParamsKey:         the key to use when sending the category ID to the controller action
 * pathDataKey:               the data key for finding the path the controller action
 * extraParams:               data that should be sent along with the data that is normally sent with the
 *                            handleBillingTeamChange event
 */
export function handleBillingTeamChangeCore(billingTeamSelect, categoryControlsWrapperId, billingTeamParamsKey,
                                            categoryParamsKey, pathDataKey, extraParams) {
  var selectedBillingTeamId = $(billingTeamSelect).children('option:selected').val();

  if (selectedBillingTeamId > 0) {
    var categorySelectSelector = getCategorySelectSelector(categoryControlsWrapperId);
    var selectedCategoryId = $(categorySelectSelector).children('option:selected').val();

    var params = Object.assign(
      {
        [billingTeamParamsKey]: selectedBillingTeamId,
        [categoryParamsKey]:    selectedCategoryId
      },
      extraParams);

    var changeBillingTeamPath = $(billingTeamSelect).data(pathDataKey);

    $.ajax({
      data:  params,
      type:  'POST',
      url:   changeBillingTeamPath,
      async: true
    });
  }
}

/*
 * TODO - this should really have a module specific name
 * hideShowCategoryControls
 * The category and work type selectors are always rendered on various forms.  They are hidden (set to
 * display-none) if there is only one value to choose from so that they will act like hidden fields in such cases.
 * We do this instead of rendering hidden fields to keep the js for updating them simple. The category and
 * work type selectors will need to be updated (in js) whenever the billing team is changed. Call this functon
 * to hide or show the selectors based on the number of options that are available.
 * -- ---- -- -- --
 * categoryControlsWrapperId: the wrapper enclosing the catgory select
 * workTypeControlsWrapperId: the wrapper enclosing the work type select
 */
export function hideShowCategoryControls(categoryControlsWrapperId, workTypeControlsWrapperId) {
  var categoryControls = $('#' + categoryControlsWrapperId);
  var categorySelect   = $(getCategorySelectSelector(categoryControlsWrapperId));
  var numCategories    = categorySelect.children('option').length;
  // check > 2 here to account for the blank
  if (numCategories > 2) {
    categoryControls.show();
  }
  else {
    categoryControls.hide();
  }

  var workTypeControls = $('#' + workTypeControlsWrapperId);
  var workTypeSelect   = $('#' + workTypeControlsWrapperId + ' select');
  var numWorkTypes = workTypeSelect.children('option').length;
  // check > 2 here to account for the blank
  if (numWorkTypes > 2) {
    workTypeControls.show();
  }
  else {
    workTypeControls.hide();
  }
}



// helpers -- ---- -- -- --

function getCategorySelectSelector(categoryControlsWrapperId) {
  return '#' + categoryControlsWrapperId + ' select';
}
