/*
 * PMApp::hours_report
 * This file contains JS implemented to support the hours report page
 */

import { teamFocusAddFieldsToParams, teamFocusSetupEventHandlers } from './team_focus.js.erb';
import { pmappButtonSpinnerSetup } from './pmapp_button_spinner';


// page initialization -- ---- -- -- --

$(document).on('turbolinks:load', function() {
  if ($('body.hours_report').length > 0) {
    teamFocusSetupEventHandlers();

    $('[id^=activitiesDetails]').on('show.bs.collapse', function(e) {
      e = e || window.event
      var triggeringCollapsible = e.target || e.srcElement;
      $('#' + getHoursReportLinkId(triggeringCollapsible)).html("<i class='fas fa-caret-down'></i>");
    });

    $('[id^=activitiesDetails]').on('hidden.bs.collapse', function(e) {
      e = e || window.event
      var triggeringCollapsible = e.target || e.srcElement;
      $('#' + getHoursReportLinkId(triggeringCollapsible)).html("<i class='fas fa-caret-right'></i>");
    });

    pmappButtonSpinnerSetup('#hoursReportSubmitButton', true);
  }
});


// helpers -- ---- -- -- --

function getHoursReportLinkId(triggeringCollapsible) {
  var userId = $(triggeringCollapsible).attr('id').
               replace('activitiesDetails', '');
  var linkId = 'showHideActivitiesLink' + userId
  return linkId;
}
