/*
 * PMApp::pmapp_button_spinner
 * JS for adding/removing a loading spinner to/from a button
 */

import Rails from '@rails/ujs';

const contentSpinnerDivider = "<!--mark-->";


/*
 * clear
 * use this method to remove the loading spinner from a button
 * NOTE: this method will not remove the event handler added by setup
 */
export function pmappButtonSpinnerClear(buttonSelector, content) {
  $(buttonSelector).prop('disabled', false);
  $(buttonSelector).html(content);
}

/*
 * setup
 * adds an event handler that will add the loading spinner to a button when the button is clicked
 */

export function pmappButtonSpinnerSetup(buttonSelector, keepContent) {
  $(buttonSelector).click(function () {
    // disable the button and add the spinner
    pmappButtonAddSpinner(buttonSelector, keepContent);

    // make sure clicking the button stil submits the form
    var jQueryForm = $(buttonSelector).closest("form");
    var form = jQueryForm[0];
    if (jQueryForm.data('remote')) { 
      /*
       * Adding a click handler to a form button clobbers the default Rails UJS behavior, so we need to submit the
       * form (via Rails UJS) ourselves. If there is more than one button, we need to make sure that information
       * gets submitted too.
       */
      var button = $(buttonSelector)[0];
      Rails.setData(form, 'ujs:submit-button', { name: button.name, value: button.value });
      Rails.fire(form, 'submit');
    }
    else {
      // TODO - multiple buttons?
      form.submit();
    }
  });
}

export function pmappButtonAddSpinner(buttonSelector, keepContent) {
  // disable the button
  $(buttonSelector).prop('disabled', true);

  // construct the new inner HTML for the button
  var htmlContent = '';
  if (keepContent) {
    htmlContent += $(buttonSelector).html();
  }
  htmlContent += contentSpinnerDivider;
  htmlContent += '&nbsp;&nbsp;<i class="fa fa-spinner fa-spin loading-spinner"></i>';

  // install the new HTML
  $(buttonSelector).html(htmlContent);
}

export function pmappButtonRemoveSpinner(buttonSelector) {
  var htmlContent = $(buttonSelector).html();
  var splitContent = htmlContent.split(contentSpinnerDivider);
  $(buttonSelector).html(splitContent[0]);
}
