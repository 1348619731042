/*
 * PMApp::devise
 * JS needed for/employed on devise managed pages
 */

import { pmappButtonSpinnerSetup } from './pmapp_button_spinner';

$(document).on('turbolinks:load', function() {
  var formButtonSelector = '#' + 'deviseFormSubmitButton';
  pmappButtonSpinnerSetup(formButtonSelector, true);
});
