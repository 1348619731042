/*
 * PMApp::timers
 * This file contains JS implemented to support team, location, project, and worker dashboards.
 */

var PMAppTimers = [];


$(document).on('turbolinks:load', function() {
  initializeTimers();
});

export function initializeTimers() {
  preventStartTimerDoubleClicks();
  initializeSpecificTimers('body');
}

export function initializeSpecificTimers(downSelector) {
  var runningSelector = downSelector + ' .running-timer';
  $(runningSelector).each(function() {
    var spanId = $(this).attr("id");
    var timerDuration = parseInt($(this).data("duration"));
    var timerId = parseInt($(this).data("timer"));
    registerTimer(timerId, timerDuration, spanId);
  });

  // just to be clean
  var pausedSelector = downSelector + ' .paused-timer';
  $(pausedSelector).each(function() {
    var timerId = parseInt($(this).data("timer"));
    unregisterTimer(timerId);
  });
}

function intToTimerDisplay(duration_as_int) {
  var hours   = Math.floor(duration_as_int / 3600);
  var minutes = Math.floor((duration_as_int - (hours * 3600)) / 60);
  var seconds = duration_as_int - (hours * 3600) - (minutes * 60);

  var hours_as_string = hours.toString();
  if (hours < 10) hours_as_string = "0" + hours_as_string;
  var minutes_as_string = minutes.toString();
  if (minutes < 10) minutes_as_string = "0" + minutes_as_string;
  var seconds_as_string = seconds.toString();
  if (seconds < 10) seconds_as_string = "0" + seconds_as_string;

  return hours_as_string + ":" + minutes_as_string + ":" + seconds_as_string;
}

/*
 * preventStartTimerDoubleClicks
 * Without this method, if a worker double-clicks a start, or resume, timer link, the start timer action will
 * be triggered twice resulting in two timer records where only one was intended.  If the two records end up with
 # the same start time (created_at), one will have a stopped time and the other will not.  Since created_at is the
 # same for the two records there is no guaranteeing that the one with a nil stopped at will be the last record
 # in a query, but expect that only the last timer record will not be stopped.  Crashes ensue.  While the case
 # in which the un-stopped timer record is returned last does not cause a crash, it still results in extra, spurious
 # timer records.  This function facilitates the resolution of both issues.
 */
function preventStartTimerDoubleClicks() {
  // first, enable all start/resume timer links; this method will be called anytime a timer is displayed
  // an therefore after any activity is updated on the current display; thus, if a timer is started, resulting
  // in a timer link being disable, when the corresponding activity is updated, the disabled link will be
  // enabled again
  var startLinkSelector = '.timers-not-started, ' +
                          '.timers-resume-paused';
  $(startLinkSelector).prop('disabled', false);

  // next, remove all any event handlers we installed to disable start/resume links
  $(startLinkSelector).off('ajax:before');

  // finally, install event handlers to disable start/resume links when clicked so that a second (the last part
  // of a double) click will not cause the second, unwanted start timer action
  $(startLinkSelector).on('ajax:before', function (e) {
    e = e || window.event
    var triggeringLink = null;
    var triggeringElement = e.target || e.srcElement;

    if ($(triggeringElement).hasClass('timers-not-started') ||
        $(triggeringElement).hasClass('timers-resume-paused')) {
      triggeringLink = triggeringElement;
    }
    else {
      triggeringLink = $(triggeringElement).closest('a');
    }

    $(triggeringLink).prop('disabled', true);
  });
}

/*
 * registerTimer
 * This function replaces te deprecated registerTimer function. This version allows the calling program to
 * specify whether or not the timer count should be sync-hronized with other timer UI elements on the same page
 * for the same timer.
 */
function registerTimer (timer_id, start_time_in_seconds, timer_display_id) {
  // see if we already have an interval for the specified timer (timer_id)
  var timer = null;
  var num_timers = PMAppTimers.length;
  for (var i = 0 ; i < num_timers ; i++) {
    var i_timer = PMAppTimers[i];
    if (i_timer.id == timer_id) {
      timer = i_timer;
      break;
    }
  }

  if (timer == null) {
    // we do not already have an interval for the specified timer, so create a new one
    var new_interval = setInterval(updateTimers, 1000, timer_id);
    var display_list = { };
    display_list[timer_display_id] = start_time_in_seconds;
    var new_timer    = { id:           timer_id,
                         interval:     new_interval,
                         display_list: display_list }
    PMAppTimers.push(new_timer);
  }
  else {
    // we already have an interval for the specified timer, just add the new display id for that interval
    timer.display_list[timer_display_id] = start_time_in_seconds;
  }
}

function unregisterTimer(timer_id) {
  // see if we have an interval for the specified timer (timer_id)
  var timer_index = -1;
  var num_timers = PMAppTimers.length;
  for (var i = 0 ; i < num_timers ; i++) {
    var i_timer = PMAppTimers[i];
    if (i_timer.id == timer_id) {
      timer_index = i;
      break;
    }
  }

  if (timer_index >= 0) {
    PMAppTimers.splice(timer_index, 1);
  }
}

function updateTimers(timer_id) {
  var num_timers = PMAppTimers.length;
  for(var i = 0 ; i < num_timers ; i++) {
    var timer = PMAppTimers[i];
    if (timer.id == timer_id) {
      var display_list = timer.display_list;

      Object.keys(display_list).forEach((display_id, index) => {
        var time_in_seconds = display_list[display_id];
        $("#" + display_id).html(intToTimerDisplay(time_in_seconds));
        display_list[display_id] = time_in_seconds + 1;
      });

    }
  }
}
