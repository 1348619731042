/*
 * PMAPp::locations
 * JS that is specific to the locations controller
 */

import { showLoadingOverlay } from "./loading_overlay";
import { teamFocusSetupEventHandlers } from './team_focus.js.erb';


$(document).on('turbolinks:load', function() {
  if ($('body.locations').length > 0) {
    teamFocusSetupEventHandlers();

    $('form.location-form').submit(function() {
      showLoadingOverlay();
    });
  }
});
