/*
 * PMApp::tasks
 * Place JS here to support functionality specific to the Tasks controller
 */

import "jquery-minicolors/jquery.minicolors.min";
import ClipboardJS from "clipboard/dist/clipboard.min.js";

import { handleBillingTeamChangeCore, hideShowCategoryControls } from './billable.js.erb';
import { customerSelectorAddFieldsToParams, customerSelectorSetupEventHandlers } from './customer_selector.js.erb';
import { jobSelectorSetupEventHandlers, jobSelectorAddFieldsToParams } from './job_selector.js.erb';
import { squirrelableGetData } from "./squirrelable.js.erb";
import { taskListableInitializeForDragAndDropAssignments } from "./task_listable.js.erb";
import { teamFocusAddFieldsToParams, teamFocusSetupEventHandlers } from './team_focus.js.erb';
import { pmappTinyMCEInitializeMentionable, pmappTinyMCESetupModalCleanupEventHandler } from './tinymce.js.erb';


// -- ---- -- -- --
// INITIALIZATION

$(document).on('turbolinks:load', function() {

  // initialization for the task detail page
  if ($('body.task.show').length) {
    new ClipboardJS('.clipboard-btn');

    teamFocusSetupEventHandlers();
    taskListableInitializeForDragAndDropAssignments($('.task-listable')[0],
                                                      '.entire-page');
  }

  // initialization for the new/edit task form
  if ($('body.tasks.new').length  || $('body.tasks.create').length ||
      $('body.tasks.edit').length || $('body.tasks.update').length) {
    teamFocusSetupEventHandlers(tasksGenerateCustomerSelectorAndJobSelectorParams());
    tasksSetupFormEventHandlers();
  }
});


// -- ---- -- -- --
// TASK DETAIL HELPERS

// public

/*
 * tasksRerouteCompleteToCustomerRequest
 * when the user attempts to complete any task, the TasksController::complete action is invoked; however,
 * if the task is the only active task belonging to a CustomerRequest, the TasksController will instead
 * reroute the request to the CustomerRequestsController; this function facilitates that; since the
 * complete action will generally be invoked as "remote" it is able to trigger an invocation of this
 * function which will in turn invoke the CustomerRequestsController::finalize_prompt action
 * -- ---- -- -- --
 * taskId: the ID of the task being completed
 * url:    the URL to use to invoke the finalize_prompt action
 */
export function tasksRerouteCompleteToCustomerRequest(taskId, url) {

  var baseParams = { task_id: taskId };
  var squirreledParams = squirrelableGetData("finalizable_prompt");
  var params = Object.assign(baseParams, squirreledParams);
  
  $.ajax({
    data:  params,
    type:  'POST',
    url:   url,
    async: true
  });
}


// -- ---- -- -- --
// NEW/EDIT FORM HELPERS

// public

/*
 * tasksGenerateCustomerSelectorAndJobSelectorParams
 * used to embed state for the the customer and job selectors in the team focus control
 */
export function tasksGenerateCustomerSelectorAndJobSelectorParams() {
  var customerSelectorParams = tasksGenerateCustomerSelectorParams();
  var jobSelectorParams = tasksGenerateJobSelectorParams();
  
  return Object.assign(customerSelectorParams, jobSelectorParams);
}

/*
 * tasksSetupFormEventHandlers
 * initializes event handlers for the customer, job, and billing_team selectors, and sets the initial
 * display state for the category and work type selectors
 */
export function tasksSetupFormEventHandlers() {
  customerSelectorSetupEventHandlers(tasksGenerateJobSelectorAndTeamFocusParams);
  jobSelectorSetupEventHandlers(tasksGenerateCustomerSelectorAndTeamFocusParams);

  hideShowCategoryControls('categoryControlsWrapper',
                           'workTypeControlsWrapper');

  $('#billingTeamSelect').change(function(e) {
    e = e || window.event
    var billingTeamSelect = e.target || e.srcElement;
    tasksHandleBillingTeamChange(billingTeamSelect,
                                 'categoryControlsWrapper',
                                 'team_id',
                                 'category_id',
                                 'change-billing-team-url');
  });

  $('.minicolor').minicolors({ theme: 'bootstrap' });

  var tinyMCEClassName = 'task-description-tinymce';
  pmappTinyMCEInitializeMentionable(tinyMCEClassName);

  var modalId = 'timeBucketFormModal';
  pmappTinyMCESetupModalCleanupEventHandler(modalId, tinyMCEClassName);
}

// private

/*
 * tasksGenerateCustomerSelectorAndTeamFocusParams
 * used to embed state for the the customer and team focus selectors in the job selector
 */
function tasksGenerateCustomerSelectorAndTeamFocusParams() {
  var customerSelectorParams = tasksGenerateCustomerSelectorParams();
  var teamFocusParams = tasksGenerateTeamFocusParams();
  
  return Object.assign(customerSelectorParams, teamFocusParams);
}

/*
 * tasksGenerateCustomerSelectorParams
 * adds customer selector state to a tasks controller-specific object
 */
function tasksGenerateCustomerSelectorParams() {
  return customerSelectorAddFieldsToParams({});
}

/*
 * tasksGenerateCustomerSelectorAndTeamFocusParams
 * used to embed state for the the customer and team focus selectors in the job selector
 */
function tasksGenerateJobSelectorAndTeamFocusParams() {
  var jobSelectorParams = tasksGenerateJobSelectorParams();
  var teamFocusParams = tasksGenerateTeamFocusParams();
  
  return Object.assign(jobSelectorParams, teamFocusParams);
}

/*
 * tasksGenerateTeamFocusParams
 * adds team focus control state to a tasks controller-specific object
 */
function tasksGenerateTeamFocusParams() {
  return teamFocusAddFieldsToParams({});
}

/*
 * tasksGenerateJobSelectorParams
 * adds job selector state to a tasks controller-specific object
 */
function tasksGenerateJobSelectorParams() {
  return jobSelectorAddFieldsToParams({});
}

/*
 * tasksHandleBillingTeamChange
 * this event handler overrides handleBillingTeamChange for changes to the billing team on the tasks form
 * -- ---- -- -- --
 * billingTeamSelect:         a JS handle to the billing team select
 * categoryControlsWrapperId: the ID of the wrapper div that contains the Category select
 * billingTeamParamsKey:      the key to use when sending the billing team ID to the controller action
 * categoryParamsKey:         the key to use when sending the category ID to the controller action
 * pathDataKey:               the data key for finding the path the controller action
 */
function tasksHandleBillingTeamChange(billingTeamSelect, categoryControlsWrapperId, billingTeamParamsKey,
                                      categoryParamsKey, pathDataKey) {
  var customerAndJobSelectorParams = tasksGenerateCustomerSelectorAndJobSelectorParams();
  handleBillingTeamChangeCore(billingTeamSelect, categoryControlsWrapperId, billingTeamParamsKey,
                              categoryParamsKey, pathDataKey, customerAndJobSelectorParams);
}
