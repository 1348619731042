/*
 * PMApp::task_listable
 * JS for the task_listable component
 */

import "jquery-ui/ui/widgets/sortable"

import { filterableInitializeItemListAsFilterable } from "./filterable.js.erb";
import { showLoadingOverlay                       } from "./loading_overlay.js";
import { pmappPreventDefaults                     } from './pmapp.js.erb';
import { rosterableInitializeAsDraggable          } from "./rosterable.js.erb";
import { squirrelableGetData                      } from './squirrelable.js.erb';
import { teamFocusSetupEventHandlers              } from './team_focus.js.erb';
import { workerAvatarListInitializePopovers,
         workerAvatarListDestroyPopovers          } from './worker_avatar_list.js.erb';


// -- ---- -- -- --
// GENERAL
// -- ---- -- -- --

/*
 * taskListableInitialize
 * initializes all task lists on the current page
 * -- ---- -- -- --
 * newElementSelector: CSS selector that specifies the element on the page to which any new elements created
 *                     to support the task list, but not include the task list itself, should be attached;
 *                     for example, when dragging an element, the drag helper will be added to the element
 *                     specified by this selector
 * pcdtWorkerSource:   CSS selector that specifies the element on the page to which PCDT workers have been
 *                     added as a data element; the workers should be added as a delimited list of IDs;
 *                     the host controller is expected to generate this list as there may be more than one
 *                     task list, but it is only necessary to compute PCDTs once for all lists; undefined
 *                     if no PCDT requests are desired
 */
export function taskListableInitialize(newElementSelector, pcdtWorkerSource) {
  // minimize/normalize for all items on all lists
  taskListableSetupItemStateChangeEventHandler();
  taskListableSetupMinimizedItemControlsEventHandlers();

  $('.task-listable').each(function (index, taskList) {
    taskListableInitializeTaskList(taskList, newElementSelector);
  });

  if (pcdtWorkerSource) {
    taskListableRequestProjectedCompletionDateTimes(pcdtWorkerSource);
  }
}

/*
 * taskListableInitializeTaskList
 * initializes a task list on the current page (except for "change state" event handlers and PCDTs)
 * -- ---- -- -- --
 * taskList:           a handle to the task list to initialize as a DOM element
 * newElementSelector: CSS selector that specifies the element on the page to which any new elements created
 *                     to support the task list, but not include the task list itself, should be attached;
 *                     for example, when dragging an element, the drag helper will be added to the element
 *                     specified by this selector
 */
export function taskListableInitializeTaskList(taskList, newElementSelector) {
  // item state controls
  taskListableSetupMinimizedItemControlsEventHandlers();

  // clipboard
  taskListableSetupClipboardItemMenuEventHandlers();

  // assignments
  var assignable = taskListableIsAssignable(taskList);
  if (assignable) {
    taskListableInitializeForDragAndDropAssignments(taskList, newElementSelector);
  }
  taskListableInitializeAssigneePopovers(taskList, assignable);

  // sortable
  if (taskListableIsSortable(taskList)) {
    taskListableInitializeTaskListAsSortable(taskList);
  }

  // filter
  if (taskListableIsFilterable(taskList)) {
    var sectionName = $(taskList).data('section');
    filterableInitializeItemListAsFilterable(sectionName);
  }
}


/*
 * taskListableSetupClipboardItemMenuEventHandlers
 * sets up the event handlers necessary for the Copy URL context menu option to work 
 * -- ---- -- -- --
 * itemCssSelector: if provided, down-selects to a specific subset of task list items on the page; used to
 *                  reset event handlers when specific task list items are re-rendered
 */
export function taskListableSetupClipboardItemMenuEventHandlers(itemCssSelector = '') {
  $(itemCssSelector + ' a.action-clipboard').click(function(e) {
    pmappPreventDefaults(e);

    e = e || window.event
    var linkIcon = e.target || e.srcElement;
    var link = $(linkIcon).closest('a');
    var url = $(link).attr('href');

    var menu = $(link).closest('.dropdown-menu');
    menu.dropdown('hide');

    navigator.clipboard.writeText(url);

    var taskListableDiv = $(link).closest('.task-listable');
    var messagesDivId = $(taskListableDiv).data('msg-container');

    if (messagesDivId !== undefined) {
      $('#' + messagesDivId).html(
        "<div class='pmapp-messages'><div class='row'><div class='col-12'>" +
        "<div class='alert alert-info alert-dismissable fade show'>" +
        url + " added to clipboard" +
        "<button type='button' class='close' data-dismiss='alert' aria-label='Close'>" +
        "<span aria-hidden='true'>&times;</span>" +
        "</button></div></div></div>"
      );
    }
  });
}


/*
 * taskListableSetupMinimizedItemControlsEventHandlers
 * minimized task list items are too small to stack all three item state controls in the control bar without
 * interfering with item status information (i.e. OVERDUE, COMPLETED, etc); for this reason, minimized task
 * list items simply have a marker icon hovering over which will open a select that includes the three task
 * list item states; this method installs the event handlers for that control
 * -- ---- -- -- --
 * itemCssSelector: if provided, down-selects to a specific subset of task list items on the page; used to
 *                  reset event handlers when specific task list items are re-rendered
 */
export function taskListableSetupMinimizedItemControlsEventHandlers(itemCssSelector = '') {
  $(itemCssSelector + ' .item-state-select').hover(
    function(e) { // hover in
      var optionsDiv = taskListableFindItemStateSelectOptionsDiv(e)
      $(optionsDiv).css('display', 'block');
    },
    function(e) { // hover out
      var optionsDiv = taskListableFindItemStateSelectOptionsDiv(e)
      $(optionsDiv).css('display', 'none');
    }
  );

  $(itemCssSelector + ' .item-state-select-marker').click(
    function(e) {
      var optionsDiv = taskListableFindItemStateSelectOptionsDiv(e)
      $(optionsDiv).toggle();
    }
  );
}

// -- ---- -- -- --
// private methods

function taskListableFindItemStateSelectOptionsDiv(e) {
  e = e || window.event
  var triggeringElement = e.target || e.srcElement;
  var wrapper = $(triggeringElement).closest('.item-state-select');
  return $(wrapper).find('.item-state-select-options');
}


// -- ---- -- -- --
// PCDTS
// -- ---- -- -- --

/*
 * taskListableGetPcdtUrl
 * returns the URL that should be used to request PCDTs
 */
export function taskListableGetPcdtUrl() {
  var taskList = $('.task-listable').first();
  return taskList.data('pcdt-url');
}

/*
 * taskListableRequestProjectedCompletionDateTime
 * generate an asynchronous request to compute the PCDTs for a specific worker
 * -- ---- -- -- --
 * requestPcdtUrl: the URL to which to send the request
 * workerId:       the ID of the worker for which to send the request
 */
export function taskListableRequestProjectedCompletionDateTime(requestPcdtUrl, workerId) {
  $.ajax({
    data:    { worker_id: workerId },
    type:    'POST',
    url:     requestPcdtUrl,
    async:   true
  });
}

/*
 * taskListableRequestProjectedCompletionDateTimes
 * When a task list is rendered, the controller can opt to supply PCDTs or defer the computation of PCDTs to
 * subsequent, ansynchronous requests.  Use this method to generate those, subsequent, asynchronous requests.
 * -- ---- -- -- --
 * pcdtWorkerSource: CSS selector that specifies the element on the page to which PCDT workers have been
 *                   added as a data element; the workers should be added as a delimited list of IDs;
 *                   the host controller is expected to generate this list as there may be more than one
 *                   task list, but it is only necessary to compute PCDTs once for all lists
 */
export function taskListableRequestProjectedCompletionDateTimes(pcdtWorkerSource) {
  var workerIdString = $(pcdtWorkerSource).data('pcdt-workers').toString();
  var requestPcdtUrl = taskListableGetPcdtUrl();

  if (workerIdString.length > 0) {
    var workerIdList = workerIdString.split(',');
    workerIdList.forEach(function (workerId, index) {
      taskListableRequestProjectedCompletionDateTime(requestPcdtUrl, workerId);
    });
  }
}

/*
 * taskListableSetupItemStateChangeEventHandler
 * call this method to configure item minimize/normalize/maximize events on task list items
 * -- ---- -- -- --
 * itemCssSelector: if provided, down-selects to a specific subset of task list items on the page; used to
 *                  reset event handlers when specific task list items are re-rendered
 */
export function taskListableSetupItemStateChangeEventHandler(itemCssSelector = '') {
  $(itemCssSelector + ' .item-controls a').click(function(e) {
    taskListableHandleChangeItemState(e);
  });
}

// event handlers

/*
 * taskListableHandleChangeItemState
 * This method is used to change a task list item from minimized to normalized or vice versa.
 */
function taskListableHandleChangeItemState(event) {
  event = event || window.event
  pmappPreventDefaults(event);

  var icon = event.target || event.srcElement;
  var clickedLink = $(icon).closest('a');

  var taskList = $(clickedLink).closest('.task-listable');
  var section  = $(taskList).data('section');

  var changeCardUrl = $(clickedLink).attr('href');

  var workerId = $(clickedLink).data('worker-id');
  var taskId   = $(clickedLink).data('task-id');
  var pcdt     = $(clickedLink).data('pcdt');
  var statuses = $(clickedLink).data('statuses');

  // build a list of minimized task list items
  var minimizedItems = { };
  var minimizedItemSelector = '.task-listable-item ' +
                              '.item-controls' +
                              '.minimized'
  $(minimizedItemSelector).each(function(index, itemControls) {
    var list = $(itemControls).closest('.task-listable');
    var sect = $(list).data('section');

    if (! minimizedItems[sect]) {
      minimizedItems[sect] = [];
    }

    var item = $(itemControls).closest('.task-listable-item');
    var taskId = $(item).data('task-id');
    minimizedItems[sect].push(taskId);
  });

  // if the item corresponding to the clicked link is minimized now, it will not be after we notify the server
  // so remove it from the list of minimized items
  var clickedControls = $(clickedLink).closest('.item-controls');
  if ($(clickedControls).hasClass('minimized')) {
    if (minimizedItems[section] && minimizedItems[section].includes(taskId)) {
      var index = minimizedItems[section].indexOf(taskId);
      minimizedItems[section].splice(index, 1);
    }
  }

  // build a list of maximized task list items
  // TODO - this should probably be a function
  var maximizedItems = { };
  var maximizedItemSelector = '.task-listable-item ' +
                              '.item-controls' +
                              '.maximized'
  $(maximizedItemSelector).each(function(index, itemControls) {
    var list = $(itemControls).closest('.task-listable');
    var sect = $(list).data('section');

    if (! maximizedItems[sect]) {
      maximizedItems[sect] = [];
    }

    var item = $(itemControls).closest('.task-listable-item');
    var taskId = $(item).data('task-id');
    maximizedItems[sect].push(taskId);
  });

  // if the item corresponding to the clicked link is maximized now, it will not be after we notify the server
  // so remove it from the list of maximized items
  var clickedControls = $(clickedLink).closest('.item-controls');
  if ($(clickedControls).hasClass('maximized')) {
    // it is maximized now, it will not be after we notify the server
    if (maximizedItems[section] && maximizedItems[section].includes(taskId)) {
      var index = maximizedItems[section].indexOf(taskId);
      maximizedItems[section].splice(index, 1);
    }
  }

  // if the minimize link was clicked, add the relevant task to the minimized task list
  if ($(clickedLink).data('purpose') ==
      'minimize') {
    if (! minimizedItems[section]) {
      minimizedItems[section] = [];
    }

    var item = $(clickedControls).closest('.task-listable-item');
    var taskId = $(item).data('task-id');
    minimizedItems[section].push(taskId);
  }
  // if the maximize link was clicked, add the relevant task to the maximized task list
  else if ($(clickedLink).data('purpose') ==
           'maximize') {
    if (! maximizedItems[section]) {
      maximizedItems[section] = [];
    }

    var item = $(clickedControls).closest('.task-listable-item');
    var taskId = $(item).data('task-id');
    maximizedItems[section].push(taskId);
  }

  var params = { worker_id:       workerId,
                 task_id:         taskId,
                 pcdt:            pcdt,
                 statuses:        statuses,
                 section:         section,
                 minimized_items: minimizedItems,
                 maximized_items: maximizedItems }

  $.ajax({
    data:    params,
    type:    'POST',
    url:     changeCardUrl,
    async:   true
  });
}


// -- ---- -- -- --
// ASSIGNEE POPOVERS
// -- ---- -- -- --

/*
 * taskListableInitializeAssigneePopovers
 * initializes the assignee section on every task list item on the current view so that clicking an
 * assignee results in a bootstrap popover
 * -- ---- -- -- --
 * taskList:     the task list element itself
 * assignable:   true if the popovers should include the ability to edit assignments; false if the
 *               popover should only provide the assignee's full name
 * downSelector: a CSS selector to select only a portion of the current document
 */
export function taskListableInitializeAssigneePopovers(taskList, assignable, downSelector) {

  var section = $(taskList).data('section');
  var taskListSelector = taskListableBuildTaskListSelector(section);

  // downselector may be blank, in which case item Selector will allow for all items
  var itemSelector = taskListSelector;
  if (downSelector) {
    itemSelector += ' ' + downSelector;
  }

  var bodylessSelector, withBodySelector;
  if (assignable) {
    withBodySelector = itemSelector;
  }
  else {
    bodylessSelector = itemSelector;
  }

  workerAvatarListInitializePopovers(bodylessSelector, withBodySelector, itemSelector);
}

export function taskListableGetTaskListHandle(section) {
  var taskListSelector = taskListableBuildTaskListSelector(section);
  return $(taskListSelector)[0];
}

function taskListableBuildTaskListSelector(section) {
  return '.task-listable' +
         '[data-section=' + section + ']';
}

/*
 * taskListableDestroyeAssigneePopovers
 * dispose of popovers that have been created for an assignee section
 * -- ---- -- -- --
 * downSelector: a CSS selector to select only a portion of the current document
 */
export function taskListableDestroyAssigneePopovers(downSelector) {
  var selector = '';
  if (downSelector) {
    selector += downSelector + ' ';
  }
  selector += '.task-listable-assignees-container';
  workerAvatarListDestroyPopovers(selector);
}


// -- ---- -- -- --
// DRAG AND DROP ASSIGNMENTS
// -- ---- -- -- --

/*
 * taskListableInitializeForDragAndDropAssignments
 * initializes appropriately marked (via class) task list items on the current view as droppable and
 * configures them to accept draggables from a team roster with the purpose of assigning workers to tasks
 * -- ---- -- -- --
 * taskList:                  the task list element itself
 * dragElementParentSelector: a CSS selector that identifies the element to which the rosterable drag element
 *                            should be appended; this will also define the dragging range/scope
 */
export function taskListableInitializeForDragAndDropAssignments(taskList, dragElementParentSelector) {

  // we can only allow dropping team members on task list items if the server has provieded an
  // path to which to send the assignment requests
  rosterableInitializeAsDraggable(dragElementParentSelector, 'assignment-hot-spot');

  // and, the server will decide if assigning team members should be allowed for each item
  // such items will be designated with class TaskListable::ASSIGN_HOTSPOT_CLASS_NAME
  taskListableInitializeItemsForDragAndDropAssignments('', taskList);
}

/*
 * taskListableInitializeItemsForDragAndDropAssignments
 * assuming the team roster has been initialized for dragging, this function initializes appropriately marked
 # (via class) task list items on the current view as droppable and configures them to accept draggables from
 * a team roster with the purpose of assigning workers to tasks
 * -- ---- -- -- --
 * itemSelector: a CSS selector that can be used to specify a subset of appropriately marked task list items
 *               on the current view; use this argument, for example, to re-establish an item as a droppable
 *               after it has been redrawn; pass in the empty string to initialize all task list items
 * taskList:     a handle to the taskList (DOM element) containing the items to initialize, if not provided
 *               this function will find it by applying the jQuery closest method to the item(s) specified by
 *               itemSelector
 */
export function taskListableInitializeItemsForDragAndDropAssignments(itemSelector, taskList) {

  var useTaskList = taskList;
  if (! useTaskList) {
    useTaskList = $(itemSelector).closest(".task-listable")[0];
  }

  var useItemSelector = itemSelector;
  if (! useItemSelector) {
    var useItemSelector = "#" + $(useTaskList).attr("id") + " ";
  }

  // we can only allow dropping team members on task list items if the server has provieded an
  // path to which to send the assignment requests
  var assignmentUrl = $(useTaskList).data("assignment-url");
  if (assignmentUrl) {

    // and, the server will decide if assigning team members should be allowed for each item
    // such items will be designated with class TaskListable::ASSIGN_HOTSPOT_CLASS_NAME
    $(useItemSelector + '.assignment-hot-spot').on("rosterableEnter", function (e) {
      e = e || window.event
      var droppable = e.target || e.srcElement;
      if (taskListableAcceptDropTeamMember(droppable, e.detail.teamMemberId)) {
        $(droppable).addClass("will-accept");
      }
    });

    $(useItemSelector + '.assignment-hot-spot').on("rosterableExit", function (e) {
      e = e || window.event
      var droppable = e.target || e.srcElement;
      $(droppable).removeClass("will-accept");
    });

    $(useItemSelector + '.assignment-hot-spot').on(
      "rosterableReceive", taskListableHandleDropTeamMemberOnTask
    );
  }
}

/*
 * taskListableIsAssignable
 * returns true if the task list permits task assignments
 * -- ---- -- -- --
 * taskList: a handle to the task list in question
 */
export function taskListableIsAssignable(taskList) {
  var assignable = false;

  if ($(taskList).data('assignment-url')) {
    assignable = true;
  }
  
  return assignable;
}

// event handlers

/*
 * taskListableHandleDropTeamMemberOnTask
 * handle the user dropping an team member (worker) from a team roster on a task list item
 * -- ---- -- -- --
 * e: the event triggered by the user dropping a team member on a task list item
 */
function taskListableHandleDropTeamMemberOnTask(e) {
  e = e || window.event
  var item = e.target || e.srcElement;

  // un-highlight the receiving droppable
  $(item).removeClass("will-accept");

  var teamMemberId = e.detail.teamMemberId;
  var dropYPosition = e.detail.position.y;

  // if taskListableAcceptDropTeamMember returns false, item should not have indicated to the user that it would
  // accept the draggable, but there is nothing to stop the user from dropping the draggable anyway, so we
  // include this check to make sure nothing happens in that event
  if (taskListableAcceptDropTeamMember(item, e.detail.teamMemberId)) {
    showLoadingOverlay();

    var taskId = $(item).data("task-id");

    var itemTop    = $(item).offset().top;
    var itemHeight = $(item).height();
    var itemMiddle = itemTop + itemHeight/2;

    // default to the top of the worker's pipeline
    var position = 1;
    if (dropYPosition > itemMiddle) {
      // if the worker is dropped in the bottom half of the item, place the item at the end of the worker's pipeline
      position = 0;
    }

    var taskList = $(item).closest(".task-listable")
    var assignmentUrl = taskList.data("assignment-url");

    $.ajax({
      data:  { worker_id: teamMemberId,
               task_id:   taskId,
               position:  position },
      type:  'POST',
      url:   assignmentUrl,
      async: true
    });
  }
}

/*
 * taskListableAcceptDropTeamMember
 * checks if a a team member (worker) from a team roster can be dropped on a specific task list item
 * -- ---- -- -- --
 * item:         the task list item in question
 * teamMemberId: the ID of the team member in question
 */
function taskListableAcceptDropTeamMember(item, teamMemberId) {
  var rVal = true;

  var assignOptionSelector = '.item-action-menu-option' +
                             '.action-assign';

  // if the assign menu option is in the item menu, then this user is okay to assign this task
  // TODO - now this has to be...if the assign menu option is in the item menu AND is active/enabled, the this
  //        user is okay to assign this task
  var assignOption = $(item).find(assignOptionSelector);
  if ($(assignOption).length == 0) {
    rVal = false;
  }

  if (rVal) {
    // if the user begin dragged is already assigned, then don't accept
    var workerAvatar = $(item).find('figure[data-worker-id=' + teamMemberId + ']');
    if ($(workerAvatar).length > 0) {
      rVal = false;
    }
  }

  return rVal;
}


// -- ---- -- -- --
// SORTABLE IMPLEMENTATION
// -- ---- -- -- --

/*
 * taskListableSetupMoveEventHandlers
 * configures the event handlers for the "Move up" and "Move down" actions
 * -- ---- -- -- --
 * selector: use this to select the actions from a subset of items
 */
export function taskListableSetupMoveEventHandlers(selector) {
  $(selector).find('.action-move-down').click(function(event) {
    taskListableHandleMove(event, false);
  });

  $(selector).find('.action-move-up').click(function(event) {
    taskListableHandleMove(event, true);
  });
}

/*
 * taskListableDisableSorting
 * effectively removes sorting capability from a task list
 * -- ---- -- -- --
 * taskListId: the HTML ID of the task list for which sorting should be disabled
 */
export function taskListableDisableSorting(taskListId) {
  var taskListSelector = '#' + taskListId;
  $(taskListSelector).addClass('disabled');
  $(taskListSelector).sortable('disable');
}

/*
 * taskListableEnableSorting
 * counteracts the effect of the taskListableDisableSorting function; this function will not enable sorting
 * unless the task list in question has been initialized as sortable
 * -- ---- -- -- --
 * taskListId: the HTML ID of the task list for which sorting should be enabled
 */
export function taskListableEnableSorting(taskListId) {
  var taskListSelector = '#' + taskListId;
  $(taskListSelector).removeClass('disabled');
  $(taskListSelector).sortable('enable');
}

/*
 * taskListableIsSortable
 * returns true if the given task list has been initialized as sortable; returns false otherwise
 * -- ---- -- -- --
 * taskList: a handle to a task list (the given task list)
 */
export function taskListableIsSortable(taskList) {
  var sortable = false;

  if ($(taskList).data('sort-url')) {
    sortable = true;
  }
  
  return sortable;
}

// event handlers -- ---- -- -- --

/*
 * taskListableHandleMove
 * handle a user tapping a "Move up" or "Move down" action
 * -- ---- -- -- --
 * event: the event generated by the click
 * up:    true if a "Move up" was tapped; false if a "Move down" was tapped
 */
function taskListableHandleMove(event, up) {
  event = event || window.event
  pmappPreventDefaults(event);

  var icon = event.target || event.srcElement;
  var clickedLink = $(icon).closest('a');

  // hide the menu
  $(clickedLink).closest('.dropdown').dropdown('hide');

  var taskList = $(clickedLink).closest('.task-listable');

  var movingItem = $(clickedLink).closest('.draggable-element');
  var movingItemId = taskListableGetSortItemId(movingItem);
  var ordering = taskListableGetSortableOrdering(taskList);
  var movingIndex = ordering.indexOf(movingItemId.idString);

  if (up) {
    taskListableHandleMoveUp(taskList, movingItemId, ordering, movingIndex);
  }
  else {
    taskListableHandleMoveDown(taskList, movingItemId, ordering, movingIndex);
  }

  taskListableResetZIndexes(taskList);
}

// private

/*
 * taskListableGetSortableOrdering
 * builds the current ordering of a sortable task list
 * -- ---- -- -- --
 * taskList: the sortable task list in question
 */
function taskListableGetSortableOrdering(taskList) {
  return $(taskList).sortable('toArray', { attribute: 'data-task-id' });
}

/*
 * taskListableGetSortItemId
 * helper for extracting an item ID from the information stashed on the views
 * -- ---- -- -- --
 * movingItem: a handle to the DOM representation of the moving item
 */
function taskListableGetSortItemId(movingItem) {
  var movingItemId = movingItem.data("task-id");
  var movingItemIdString = movingItemId.toString();
  //var movingItemId = parseInt(movingItemIdString);

  return {
    id: movingItemId,
    idString: movingItemIdString
  }
}

/*
 * taskListableInitializeTaskListAsSortable
 * -- ---- -- -- --
 * taskList: a handle to the task list that needs to be sortable
 */
export function taskListableInitializeTaskListAsSortable(taskList) {

  $(taskList).sortable({
    axis:                 'y', 
    containment:          taskList,
    cursor:               'move',
    disabled:             false,
    items:                '.draggable-element',
    helper:               'clone',
    forceHelperSize:      true,
    revert:               100,
		placeholder:          'task-listable-draggable-placeholder',
    forcePlaceholderSize: true,
    opacity:              0.5,
    scroll:               true,
    scrollSensitivity:    20,
    scrollSpeed:          20,

    // leaving the cursorAt the point of grab and setting tolerance to pointer seems to work best for a
    // single task list (just sorting); fixing te cursorAt and then requiring the 50% overlap causes
    // problems at the top and bottom of the list
    cursorAt:             false,
    tolerance:            'pointer',
    
    update: function(event, ui) {
      // user has dropped the item in an acceptable location

      // send request to the server to update the task list item positions
      var taskList = this;
      var droppedItem = ui.item;
      var droppedItemId = taskListableGetSortItemId(droppedItem);
      var ordering = taskListableGetSortableOrdering(taskList);
      taskListableReportSortToServer(taskList, droppedItemId.id, ordering);

      // update the z-indexes
      taskListableResetZIndexes(taskList);
    }

  });

  // menu options for mobile
  taskListableSetupMoveEventHandlers(taskList);

  // disable sorting if called for
  if ($(taskList).hasClass('disabled')) {
    $(taskList).sortable('disable');
  }
}

/*
 * taskListableHandleMoveDown
 * this method supports the taskListableHandleMove function when the up argument is set to false
 * -- ---- -- -- --
 * taskList:     the task list to which the move is being applied
 * movingItemId: the ID for the item being moved
 * ordering:     an array of item IDs (strings) the gives the current ordering of the task list in question
 * movingIndex:  the index of the item being moved in ordering
 */
function taskListableHandleMoveDown(taskList, movingItemId, ordering, movingIndex) {
  if (movingIndex < ordering.length - 1) {
    var followingItemIdString = ordering[movingIndex + 1];

    // move the element on the page
    var selectorStart = '.draggable-element' +
                        '[data-task-id=';
    $(selectorStart + movingItemId.idString + ']').insertAfter(selectorStart + followingItemIdString + ']');

    // tell the server it moved
    ordering.splice(movingIndex, 1); // remove the moving item from ordering
    ordering.splice(movingIndex + 1, 0, movingItemId.idString); // add it back in its new spot
    taskListableReportSortToServer(taskList, movingItemId.id, ordering);
  }
}

/*
 * taskListableHandleMoveUp
 * this method supports the taskListableHandleMove function when the up argument is set to true
 * -- ---- -- -- --
 * taskList:     the task list to which the move is being applied
 * movingItemId: the ID for the item being moved
 * ordering:     an array of item IDs (strings) the gives the current ordering of the task list in question
 * movingIndex:  the index of the item being moved in ordering
 */
function taskListableHandleMoveUp(taskList, movingItemId, ordering, movingIndex) {
  if (movingIndex > 0) {
    var precedingItemIdString = ordering[movingIndex - 1];

    // move the element on the page
    var selectorStart = '.draggable-element' +
                        '[data-task-id=';
    $(selectorStart + movingItemId.idString + ']').insertBefore(selectorStart + precedingItemIdString + ']');

    // tell the server it moved
    ordering.splice(movingIndex, 1); // remove the moving item from ordering
    ordering.splice(movingIndex - 1, 0, movingItemId.idString); // add it back in its new spot
    taskListableReportSortToServer(taskList, movingItemId.id, ordering);
  }
}

/*
 * taskListableReportSortToServer
 * sorting starts in the browser, but needs to be reported the server to allow the server to persist the
 * sorting that the user has requested
 * -- ---- -- -- --
 * taskList:     the task list that is being sorted
 * movingItemId: the item ID of the item that has moved
 * ordering:     the ordering of the task list after sorting
 */
function taskListableReportSortToServer(taskList, movingItemId, ordering) {
  var section = $(taskList).data('section');
  var sortUrl = $(taskList).data('sort-url');

  $.ajax({
    data:  { section:         section,
             dropped_task_id: movingItemId,
             ordering:        ordering },
    type:  'POST',
    url:   sortUrl,
    async: true
  });
}

/*
 * taskListableResetZIndexes
 * when a task list is "sorted" the z-index get screwed up; this function resest the task list's z-indexes to 
 * not be screwed up
 * -- ---- -- -- --
 * taskList: the task list in question
 */
function taskListableResetZIndexes(taskList) {
  var zIndexWrappers = $(taskList).children();
  var nextZIndex = zIndexWrappers.length;
  zIndexWrappers.each(function(index, zIndexWrapper) {
    $(zIndexWrapper).css('z-index', nextZIndex--);
  });
}


// -- ---- -- -- --
// FILTERS
// -- ---- -- -- --

// private

/*
 * taskListableIsFilterable
 * returns true if the subject task list is filterable; false otherwise
 * -- ---- -- -- --
 * taskList: the subject task list
 */
function taskListableIsFilterable(taskList) {
  var filterable = false;

  if ($(taskList).data('filterable')) {
    filterable = true;
  }
  
  return filterable;
}
