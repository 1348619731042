/*
 * PMApp::general_search
 */

$(document).on('turbolinks:load', function() {
  $('.general-search-filter-buttons input').change(function(event) {
    event = event || window.event
    var changedCheck = event.target || event.srcElement;
    var form = $(changedCheck).closest('form');
    form.submit();
  });
});
