/*
 * PMApp::pmapp
 * PMApp specific JS that should generally be included in all layouts
 */

import { hideLoadingOverlay, showLoadingOverlay } from "./loading_overlay";

$(document).on('turbolinks:load', function() {
  // if a page was opened by clicking a loading overlay link, the loading overlay will have been showing
  // on the last page at the time the new page was loaded and so turbolinks will have cached the last page
  // with the ovelay link showing; here we hide the loading overlay to address that case
  hideLoadingOverlay();

  // enable any loading overlay links we created on the page
  $('.loading-overlay-link').on('click', pmappLoadingOverlayEventHandler);
});

function pmappLoadingOverlayEventHandler(event) {
  var e = event || window.event

  var triggeringElement = e.target || e.srcElement;
  var triggeringLink = $(triggeringElement).closest('a');

  var confirmationMessage = $(triggeringLink).data('loading-confirm');

  if (confirmationMessage) {
    if (confirm(confirmationMessage) == true) {
      showLoadingOverlay();
    }
    else {
      pmappPreventDefaults(event);
    }
  }
  else {
    showLoadingOverlay();
  }
}

/*
 * ajaxPrologue
 * All .js.erb view templates (NOT PARTIALS) should call this method before doing anything else
 */
export function ajaxPrologue(downSelector) {
  // clean out any loading overlay link event handlers, we do this because we are going to blanket
  // set them up again in the epilogue (see below)
  var cssSelector = pmappBuildLoadingOverlaySelector(downSelector);
  $(cssSelector).off('click', pmappLoadingOverlayEventHandler);
}

/*
 * ajaxEpilogue
 * All .js.erb view templates (NOT PARTIALS) should call this method at the end of the script
 */
export function ajaxEpilogue(downSelector) {
  // enable any loading overlay links we created on the page, we do this because the prologue wiped
  // them all out
  var cssSelector = pmappBuildLoadingOverlaySelector(downSelector);
  $(cssSelector).on('click', pmappLoadingOverlayEventHandler);
}

/*
 * pmappPreventDefaults
 * a simple utiltity function
 */
export function pmappPreventDefaults(event) {
  event.preventDefault();
  event.stopPropagation();
}


// -- ---- -- -- --
// private methods

function pmappBuildLoadingOverlaySelector(downSelector) {
  var returnValue = '.loading-overlay-link';

  if (downSelector) {
    returnValue = downSelector + ' ' + returnValue;
  }

  return returnValue;
}
