/*
 * PMApp:job_selector
 * This file is part of the PMApp Job Selector component
 */

import { pmappPreventDefaults } from './pmapp.js.erb';

// functions -- ---- -- -- --

/*
 * jobSelectorAddFieldsToParams
 * This function is used as a helper by other job selector JS functions as well as by other PMApp JS functions.
 * This function is used to infuse job selector params into a more general params list to be submitted to
 * some fucntionality-specific action.
 * -- ---- -- -- --
 * params: the params list into which the job selector params are to be infused (see description)
 */
export function jobSelectorAddFieldsToParams(params) {
  var selectedJobId = jobSelectorGetSelectedJobId();
  var className = jobSelectorGetClassName();
  var locationId = jobSelectorGetLocationId();
  var userFilter = jobSelectorGetUserFilter();
  var workerId = jobSelectorGetWorkerId();
  var teamId = jobSelectorGetTeamId();
  var filteringFlag = jobSelectorGetFilteringFlag();
  var hostControllerData = jobSelectorGetHostControllerData();
  var defaultIfOneFlag = jobSelectorGetDefaultIfOneFlag();
  var allowClosedFlag = jobSelectorGetAllowClosedFlag();

  params['job_selector_job_id']         = selectedJobId;
  params['job_selector_class']     = className;
  params['job_selector_location_id']    = locationId;
  params['job_selector_user_filter']    = userFilter;
  params['job_selector_worker_id']      = workerId;
  params['job_selector_team_id']        = teamId;
  params['job_selector_filtering'] = filteringFlag;
  params['job_selector_host_data']      = hostControllerData;
  params['job_selector_default_if_one']       = defaultIfOneFlag;
  params['job_selector_allow_closed']   = allowClosedFlag;

  return params;
}

/*
 * jobSelectorSetupEventHandlers
 * This function is called with the job selector is first rendered to set up the necessary event handlers.
 * -- ---- -- -- --
 * generateBaseParams: if present, a function to generate a base set of params to which the job selector
 *                     params should be added before hitting the update control action
 */
export function jobSelectorSetupEventHandlers(generateBaseParams) {

  $('#jobSelectorJobSelect').change(function(e) {
    e = e || window.event
    var triggeringSelector = e.target || e.srcElement;

    var params;
    if (generateBaseParams && typeof(generateBaseParams) === typeof(Function))
    {
      params = generateBaseParams();
    }
    else {
      params = {};
    }
    jobSelectorUpdateControls(triggeringSelector, params);
  });

  jobSelectorSetupFilteringEventHandlers();
}


/*
 * jobSelectorSetupFilteringEventHandlers
 * This function sets up the necessary event handles for the job selector filtering control.  This function
 * is separate from jobSelectorSetupEventHandlers because it needs to be re-executed each time the
 * filtering control is redrawn.
 */
export function jobSelectorSetupFilteringEventHandlers() {
  $('#jobSelectorFilterLinksWrapper a').click(function(e) {
    e = e || window.event
    pmappPreventDefaults(e);

    var triggeringElement = e.target || e.srcElement;
    jobSelectorChangeFiltering(triggeringElement);
  });

  $('#jobSelectorFilterLinksWrapper').hover(
    function(e) { // hover in
      $('#jobSelectorFilterLinksWrapper .job-selector-filter-links').show();

      // close the job selector so the select options don't obscure the filtering options
      $('#jobSelectorJobSelect').blur();
    },
    function(e) { // hover out
      $('#jobSelectorFilterLinksWrapper .job-selector-filter-links').hide();
    }
  );
}


// event handlers -- ---- -- -- --

/*
 * jobSelectorChangeFiltering
 * This handler is fired whenever the user picks a new filtering level.
 * -- ---- -- -- --
 * filteringElement: the element of the filtering control the user selected to incidate that they wish to
 *                   change the filtering level
 */
function jobSelectorChangeFiltering(filteringElement) {
  var filteringLink = $(filteringElement).closest('a');

  var params = jobSelectorAddFieldsToParams({});

  var filteringKey = 'filter';
  params['job_selector_filtering'] = $(filteringLink).data(filteringKey);

  var updateUrlKey = 'url';
  var updateUrl = $(filteringLink).data(updateUrlKey);

  $.ajax({
    data:  params,
    type:  'GET',
    url:   updateUrl,
    async: true
  });
}

/*
 * jobSelectorUpdateControls
 * This handler fires when the user changes the job or location
 * -- ---- -- -- --
 * triggeringSelector: the selector that caused the event to fire
 * givenParams:        the set of params, generated by some outside entity, to which job selector
 *                     params will be added before hitting the update controls action
 */
function jobSelectorUpdateControls(triggeringSelector, givenParams) {
  var params = jobSelectorAddFieldsToParams(givenParams);

  var updateUrlKey = 'url';
  var updateUrl = $(triggeringSelector).data(updateUrlKey);

  $.ajax({
    data:  params,
    type:  'POST',
    url:   updateUrl,
    async: true
  });
}


// helpers -- ---- -- -- --

function jobSelectorBuildJobSelectorId() {
  return 'jobSelectorJobSelect';
}

function jobSelectorBuildAllowClosedFlagFieldId() {
  return 'jobSelectorAllowClosedField';
}

function jobSelectorBuildClassNameFieldId() {
  return 'jobSelectorClassNameField';
}

function jobSelectorBuildDefaultIfOneFlagFieldId() {
  return 'jobSelectorDefaultIfOneField';
}

function jobSelectorBuildLocationFieldId() {
  return 'jobSelectorLocationField';
}

function jobSelectorBuildUserFilterFieldId() {
  return 'jobSelectorUserFilterField';
}

function jobSelectorBuildTeamFieldId() {
  return 'jobSelectorTeamField';
}

function jobSelectorBuildWorkerFieldId() {
  return 'jobSelectorWorkerField';
}

function jobSelectorBuildFilteringFlagFieldId() {
  return 'jobSelectorFilterFlagField';
}

function jobSelectorBuildHostControllerDataFieldId() {
  return 'jobSelectorHostDataField';
}


function jobSelectorGetSelectedJobId() {
  return $('#' + jobSelectorBuildJobSelectorId()).children('option:selected').val();
}

function jobSelectorGetAllowClosedFlag() {
  return $('#' + jobSelectorBuildAllowClosedFlagFieldId()).val();
}

function jobSelectorGetClassName() {
  return $('#' + jobSelectorBuildClassNameFieldId()).val();
}

function jobSelectorGetDefaultIfOneFlag() {
  return $('#' + jobSelectorBuildDefaultIfOneFlagFieldId()).val();
}

function jobSelectorGetLocationId() {
  return $('#' + jobSelectorBuildLocationFieldId()).val();
}

function jobSelectorGetUserFilter() {
  return $('#' + jobSelectorBuildUserFilterFieldId()).val();
}

function jobSelectorGetTeamId() {
  return $('#' + jobSelectorBuildTeamFieldId()).val();
}

function jobSelectorGetWorkerId() {
  return $('#' + jobSelectorBuildWorkerFieldId()).val();
}

function jobSelectorGetFilteringFlag() {
  return $('#' + jobSelectorBuildFilteringFlagFieldId()).val();
}

function jobSelectorGetHostControllerData() {
  return $('#' + jobSelectorBuildHostControllerDataFieldId()).val();
}
